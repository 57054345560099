import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';

export const ListStyled = styled('ul')(({ theme }) => ({
  marginTop: theme.space[2],
  marginBottom: theme.space[2],
  paddingLeft: '2em',

  '& &': {
    marginTop: theme.space[1],
    marginBottom: theme.space[1],
  },

  '& ul': {
    listStyleType: 'none',

    li: {
      position: 'relative',
    },

    'li:before': {
      position: 'absolute',
      content: '"-"',
      marginLeft: '-1em',
    },
  },

  li: {
    marginTop: theme.space[1],
    marginBottom: theme.space[1],

    li: {
      margin: 0,
    },
  },
}));

export const NumbersLargeCircleFilled = styled('ol')(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  paddingTop: theme.space[1],
  paddingBottom: theme.space[1],
  counterReset: 'one',

  li: {
    position: 'relative',
    display: 'block',
    padding: 0,
    paddingTop: theme.space[5],
    [media('md')]: {
      paddingTop: 0,
      paddingLeft: theme.space[5],
    },
    marginBottom: theme.space[3],
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&:before': {
      content: 'counter(one) ""',
      color: theme.colors.primaryBrightText,
      textAlign: 'center',
      paddingTop: 12,
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1,
      fontFamily: theme.fonts.headline,
      counterIncrement: 'one',
      position: 'absolute',
      left: 0,
      top: 0,
      [media('md')]: {
        top: '50%',
        transform: 'translateY(-50%)',
      },
      background: theme.colors.primaryBright,
      borderRadius: '50%',
      height: theme.space[4] - 12,
      width: theme.space[4] - 12,
    },
  },
}));

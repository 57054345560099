/**
 * This has been converted to be a variaton on Material UI Colors
 * Google MUI Colors: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 *
 * These colors are global to all themeGroups and themes
 */

export const pallet = {
  white: '#fff',
  black: '#000',

  grey050: '#f8f8f8', //    was: offwhite         / MUI 50:  #FAFAFA
  grey100: '#f0f0f0', //    was: greyLight        / MUI 100: #F5F5F5
  grey300: '#d9d9d9', //    was: greyMedium       / MUI 300: #E0E0E0
  grey600: '#808790', //    was: grey             / MUI 600: #757575
  grey800: '#484848', //    was: greyMediumDark   / MUI 800: #424242
  grey900: '#313131', //    was: greyDark         / MUI 900: #212121

  green025: '#f4f8f6', //   was: lightGreyishCyan or greenLighter
  green050: '#eef9ee', //   was: greenNew         / MUI 50:  #E8F5E9
  green063: '#e6f4f1', //   added as: ThemeGroup Azure tertiary
  green075: '#d6fadc', //   added as: ThemeGroup Azure primary bright
  green100: '#e2fcb0', //   was: greenBright      / MUI 100: #C8E6C9
  green200: '#7fffe2', //   added as: ThemeGroup Peach primary bright
  green300: '#00c2a8', //   added as: ThemeGroup Sand primary bright
  green650: '#00b490', //   added as: ThemeGroup Peach primary
  green700: '#519781', //   was: greenLight       / MUI 700: #388E3C
  green750: '#008f7a', //   added as: ThemeGroup Sand primary
  green800: '#0799B4', //   added as: ThemeGroup Azure primary
  green900: '#166045', //   was: green            / MUI 900: #1B5E20

  blue050: '#f4f9ff', //    added as: ThemeGroup Azure tertiary bright
  blue100: '#abc4dd', //    added as: ThemeGroup Azure quaternary
  blue600: '#144899', //    added as: ThemeGroup Azure secondary bright
  blue700: '#4569ff', //    was: blue             / MUI 700: #1976D2
  blue750: '#2C73d2', //    added as: ThemeGroup Sand secondary bright
  blue850: '#002371', //    added as: ThemeGroup Sand + Azure secondary
  blue900: '#0c2a56', //    was: blueDarker       / MUI 900: #0D47A1

  red025: '#fcf7f7', //     was: errorBg
  red200: '#ff9982', //     added as: ThemeGroup Peach secondary bright
  red900: '#9d0c0c', //     was: errorText              MUI 900: #B71C1C
  red950: '#540000', //     added as: ThemeGroup Peach secondary

  orange025: '#fffaf7', //  added as: ThemeGroup Peach tertiary bright
  orange050: '#fff6f0', //  added as: ThemeGroup Peach quaternary
  orange100: '#fff8ea', //  added as: ThemeGroup Sand tertiary bright
  orange125: '#fff9ec', //  added as: ThemeGroup Peach tertiary
  orange150: '#faf1dd', //  added as: ThemeGroup Sand quaternary
  orange200: '#ffe3ba', //  added as: ThemeGroup Sand tertiary

  crusta500: '#E86F2B', // added as: ThemeGroup Licence primary bright
  crusta450: '#F7813E', // added as: ThemeGroup Licence secondary bright
  crusta400: '#FAA96D', // added as: ThemeGroup Licence tertiary bright
  crusta100: '#FEEFD8', // added as: ThemeGroup Licence quaternary bright

  zodiac500: '#102342', // added as: ThemeGroup Licence primary dark
  zodiac300: '#5A9ACE', // added as: ThemeGroup Licence secondary dark
  zodiac100: '#C8E6F6', // added as: ThemeGroup Licence tertiary dark


};

/**
 * These seem to be the same across theme groups, thus they're kept here to make
 * keeping them consistent easier.
 */
export const basicColors = {
  currentColor: 'currentColor',
  transparent: 'transparent',
  moreDim: pallet.grey300,
  dim: pallet.grey600,
  text: pallet.grey800,
  headline: pallet.grey900,
  background: pallet.white,
  faded: pallet.grey050,

  border: pallet.grey100,

  link: pallet.blue700,
  linkHover: pallet.blue700,

  inputBorder: pallet.grey300,
  inputBorderFocus: pallet.grey600,
};

export const sizes = {
  header: {
    height: {
      default: 68,
      sm: 108,
      md: 108,
      lg: 108,
      xl: 147,
    },
  },
};

export const breakpoints = [
  '576px',
  '768px',
  '992px',
  '1160px',
  '1440px',
] as string[] & { sm: string; md: string; lg: string; xl: string; xxl: string };
export const breakpointNavigation = 'xl';

// Aliases for rebass
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

export type Colors = typeof basicColors & {
  primary: string;
  primaryBright: string;
  primaryText: string;
  primaryBrightText: string;

  secondary: string;
  secondaryBright: string;
  secondaryText: string;
  secondaryBrightText: string;

  tertiary: string;
  tertiaryBright: string;
  tertiaryText: string;
  tertiaryBrightText: string;

  quaternary: string;
  quaternaryText: string;

  errorBg: string;
  errorText: string;
  successBg: string;
  successText: string;

  pallet: typeof pallet;
};

export const getBaseTheme = ({ colors }: { colors: Colors }) => {
  const baseInput = {
    px: 1,
    py: '10px',
    borderRadius: 5,
    borderColor: colors.inputBorder,
    maxWidth: '100%',
    font: 'inherit',
    '&:focus': {
      borderColor: colors.inputBorderFocus,
      outline: 'none',
      boxShadow: 'inset 0 0 7px rgba(0, 0, 0, .1)',
    },
  };

  return {
    breakpoints,
    fonts: {
      base: '"Open Sans", sans-serif',
      headline: '"Unit Rounded", sans-serif',
    },
    // h1: 34 / h2: 34 / h3: 24 / h4: 22 / h5: 22 / h6: 13 - base: 15
    fontSizes: [12, 13, 15, 17, 22, 24, 26, 28, 34, 38],
    space: [0, 17, 30, 40, 65, 80, 100, 125, 150],
    colors,
    forms: {
      input: baseInput,
      label: {
        flexDirection: 'column',
      },
      radio: {},
      checkbox: {},
      invalid: {
        ...baseInput,
        borderColor: colors.errorText,
        backgroundColor: colors.errorBg,
      },
      valid: {
        ...baseInput,
        borderColor: colors.successText,
        backgroundColor: colors.successBg,
      },
    },
    links: {
      color: colors.link,
      colorHover: colors.linkHover,
    },
    buttons: {
      primary: {
        color: colors.headline,
        borderColor: 'currentColor',
        backgroundColor: 'transparent',
        fontWeight: 700,
        transition: 'background-color .25s, color .25s, border-color .25s',

        '&:hover, &:focus': {
          color: colors.secondaryBright,
          backgroundColor: 'rgba(255, 255, 255, .3)',
        },
      },
      primaryGrey: {
        color: colors.pallet.grey300,
        borderColor: 'currentColor',
        backgroundColor: 'transparent',
        transition: 'background-color .25s, color .25s, border-color .25s',
        fontWeight: 600,

        '&:hover, &:focus': {
          color: colors.headline,
          backgroundColor: 'rgba(255, 255, 255, .3)',
        },
      },
      filled: {
        color: colors.secondaryBrightText,
        borderColor: colors.secondaryBright,
        backgroundColor: colors.secondaryBright,
        boxShadow: '0 3px 7px rgba(0, 0, 0, .13)',
        transition: 'background-color .25s, color .25s, border-color .25s',
        fontWeight: 700,

        '&:hover, &:focus': {
          color: colors.secondaryText,
          backgroundColor: colors.secondary,
          borderColor: colors.secondary,
        },
      },
      filledLarge: {
        color: colors.secondaryBrightText,
        borderColor: colors.secondaryBright,
        backgroundColor: colors.secondaryBright,
        boxShadow: '0 3px 7px rgba(0, 0, 0, .13)',
        transition: 'background-color .25s, color .25s, border-color .25s',
        fontWeight: 700,
        width: '100% !important',
        display: 'flex !important',
        padding: '30px !important',

        // fontSize: '17px !important',
        '@media(min-width: 768px)': {
          fontSize: '22px !important',
        },

        '&:hover, &:focus': {
          color: colors.secondaryText,
          backgroundColor: colors.secondary,
          borderColor: colors.secondary,
        },
      },
      blank: {
        color: colors.secondaryText,
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        transition: 'background-color .25s, color .25s, border-color .25s',
        fontWeight: 700,

        '&:hover, &:focus': {
          color: colors.secondaryText,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
      },
      blankGrey: {
        color: colors.pallet.grey600,
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        transition: 'background-color .25s, color .25s, border-color .25s',
        fontWeight: 700,

        '&:hover, &:focus': {
          color: colors.pallet.grey900,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
      },
      filledWhite: {
        color: colors.secondaryBright,
        borderColor: colors.pallet.white,
        backgroundColor: colors.pallet.white,
        boxShadow: '0 3px 7px rgba(0, 0, 0, .13)',
        transition: 'background-color .25s, color .25s, border-color .25s',
        fontWeight: 700,

        '&:hover, &:focus': {
          color: colors.secondary,
          backgroundColor: colors.faded,
          borderColor: colors.faded,
        },
      },
      filledGrey: {
        color: colors.pallet.grey900,
        borderColor: colors.pallet.grey100,
        backgroundColor: colors.pallet.grey100,
        transition: 'background-color .25s, color .25s, border-color .25s',
        fontWeight: 600,

        '&:hover, &:focus': {
          color: colors.pallet.grey900,
          backgroundColor: colors.pallet.grey300,
          borderColor: colors.pallet.grey300,
        },
      },
      toggle: {
        textTransform: 'uppercase',
        fontWeight: 700,
        cursor: 'pointer',
        color: colors.primary,
        borderStyle: 'none !important',
        backgroundColor: 'transparent',
        padding: '0 !important',
        width: 'auto !important',

        '&:hover, &:focus': {
          textDecoration: 'underline',
        },
      },
      toggleInherit: {
        textTransform: 'uppercase',
        fontWeight: 700,
        cursor: 'pointer',
        color: 'inherit',
        borderStyle: 'none !important',
        backgroundColor: 'transparent',
        padding: '0 !important',
        width: 'auto !important',

        '&:hover, &:focus': {
          textDecoration: 'underline',
        },
      },
    },
  };
};

import React from 'react';

import {
  Label,
  Input,
  Checkmark,
} from './Checkbox.styles';

type CheckboxProps = {
  value: boolean;
  onChange: (val?: any) => void;
  text: string;
  disabled: boolean;
  labelFontSize?: number;
};

export default function Checkbox({
  value,
  onChange,
  text,
  disabled,
  labelFontSize,
}: CheckboxProps) {

  return (
    <Label disabled={disabled} fontSize={labelFontSize}>
      {text}
      <Input type="checkbox" checked={value} onChange={onChange} disabled={disabled} />
      <Checkmark className="checkmark" />
    </Label>
  );
}

Checkbox.defaultProps = {
  labelFontSize: 18,
}


import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <polygon
      fill="#71C2FF"
      points="19.1,38.5 30.9,38.5 34.8,8.4 34.8,4.5 15.2,4.5 15.2,8.4 	"
    />
    <path
      fill="#FFFFFF"
      d="M32.3,24.8l1.2-7.8H21.8c-0.7,0-1.2,0.6-1.2,1.3l0.8,5.5c0.1,0.6,0.6,1,1.2,1H32.3z"
    />
    <rect width="19.5" height="3.9" x="15.2" y="4.5" fill="#FFFFFF" />
    <path
      fill="#444B54"
      d="M30.9,38.5c0,0-0.1,0-0.2,0c-0.6-0.1-1.1-0.7-1-1.3l3.9-28.8V5.7H16.4v2.7l3.9,28.8c0.1,0.6-0.4,1.2-1,1.3
		c-0.6,0.1-1.2-0.4-1.3-1L14.1,8.6c0,0,0-0.1,0-0.2V4.5c0-0.7,0.5-1.2,1.2-1.2h19.5c0.7,0,1.2,0.5,1.2,1.2v3.9c0,0,0,0.1,0,0.2
		L32,37.5C32,38,31.4,38.5,30.9,38.5z"
    />
    <path
      fill="#444B54"
      d="M30.9,40.4H19.1c-0.7,0-1.2,0.5-1.2,1.2v3.9c0,0.7,0.5,1.2,1.2,1.2h11.7c0.7,0,1.2-0.5,1.2-1.2v-3.9
		C32,41,31.5,40.4,30.9,40.4z"
    />
  </Svg>
);

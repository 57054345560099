import React from 'react';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';
import NavigationFooter from '~/components/common/NavigationFooter';
import { Craft_MenuItemsMenuItemSub } from '~/query';

export default function CraftGlobalNavigationFooter() {
  const { globalNavigationFooter } = useCraftGlobals();

  const navItems = (globalNavigationFooter?.menuItems ?? [])
    .map(item => {
      return {
        id: item?.id ?? undefined,
        link: {
          url: item?.menuItemLink?.url ?? undefined,
          text: item?.menuItemLink?.text ?? undefined,
          target: item?.menuItemLink?.target ?? undefined,
        },
        appearance: item?.menuItemAppearance ?? undefined,
        children: (item?.children ?? [])
          .map(childItem => {
            return {
              id: childItem?.id ?? undefined,
              link: {
                url: childItem?.menuItemLink?.url ?? undefined,
                text: childItem?.menuItemLink?.text ?? undefined,
                target: childItem?.menuItemLink?.target ?? undefined,
              },
              appearance: childItem?.menuItemAppearance ?? undefined,
              description:
                (childItem as Craft_MenuItemsMenuItemSub)
                  ?.menuItemDescription ?? undefined,
            };
          })
          .filter(_ => _ && _.id),
      };
    })
    .filter(_ => _ && _.id);

  return <NavigationFooter navItems={navItems} />;
}

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#C7D7E2"
      d="M76.5,97.3H63.3H51.5c0,7.9-1.2,12.7-6.7,15.3h38.3C77.8,110.1,76.5,105.2,76.5,97.3z"
    />
    <path
      fill="#454B54"
      d="M83.2,115.7H44.8c-1.4,0-2.6-1-2.9-2.3c-0.3-1.4,0.4-2.8,1.6-3.4c2-0.9,3.5-1.3,4.5-1.5c0.1,0,0.2,0,0.3-0.1c0.1-0.5,0.1-1.4,0.1-3.1c0-1.7,1.3-3,3-3s3,1.3,3,3c0,1.7-0.1,3.1-0.3,4.3h19.5c-0.2-1.2-0.3-2.6-0.3-4.3c0-1.7,1.3-3,3-3s3,1.3,3,3c0,1.7,0.1,2.6,0.1,3.1c0.1,0,0.2,0,0.3,0.1c1,0.2,2.6,0.5,4.5,1.5c1.3,0.6,1.9,2,1.6,3.4C85.8,114.7,84.6,115.7,83.2,115.7z"
    />
    <path
      fill="#454B54"
      d="M86,115.7H42c-1.7,0-3-1.3-3-3s1.3-3,3-3h44c1.7,0,3,1.3,3,3S87.7,115.7,86,115.7z"
    />
    <path
      fill="#C7D7E2"
      d="M109.8,18.2H18.2c-2.3,0-4.2,1.9-4.2,4.2v70.8c0,2.3,1.9,4.2,4.2,4.2h91.7c2.3,0,4.2-1.9,4.2-4.2V22.3C114,20,112.1,18.2,109.8,18.2z"
    />
    <path
      fill="#FFF"
      d="M109.8,18.2H18.2c-2.3,0-4.2,1.9-4.2,4.2v58.3h100V22.3C114,20,112.1,18.2,109.8,18.2z"
    />
    <path
      fill="#454B54"
      d="M114,83.7H14c-1.7,0-3-1.3-3-3s1.3-3,3-3h100c1.7,0,3,1.3,3,3S115.7,83.7,114,83.7z"
    />
    <path
      fill="#454B54"
      d="M109.8,100.3H18.2c-4,0-7.2-3.2-7.2-7.2V22.3c0-4,3.2-7.2,7.2-7.2h91.7c4,0,7.2,3.2,7.2,7.2v70.8C117,97.1,113.8,100.3,109.8,100.3z M18.2,21.2c-0.6,0-1.2,0.5-1.2,1.2v70.8c0,0.6,0.5,1.2,1.2,1.2h91.7c0.6,0,1.2-0.5,1.2-1.2V22.3c0-0.6-0.5-1.2-1.2-1.2H18.2z"
    />
    <g>
      <path fill="#FFF" d="M64 86A3 3 0 1 0 64 92A3 3 0 1 0 64 86Z" />
    </g>
  </Svg>
);

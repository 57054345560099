import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#fff"
      d="M106,97H92l0,20H22V39c0-3.3,2.7-6,6-6h72c3.3,0,6,2.7,6,6V97z"
    />
    <path
      fill="#444b54"
      d="M120.4 48H117c-1.7 0-3-1.3-3-3s1.3-3 3-3h3.4c1.7 0 3 1.3 3 3S122 48 120.4 48zM100 30H29c-5.5 0-10 4.5-10 10v2H7.6c-1.7 0-3 1.3-3 3s1.3 3 3 3H33v49c0 1.7 1.3 3 3 3h70c1.7 0 3-1.3 3-3V39C109 34 105 30 100 30zM25 42v-2c0-2.2 1.8-4 4-4s4 1.8 4 4v2H25zM103 94H39V40c0-1.4-.3-2.8-.8-4H100c1.7 0 3 1.3 3 3V94z"
    />
    <path
      fill="#444b54"
      d="M92,120H22c-1.7,0-3-1.3-3-3V58c0-1.7,1.3-3,3-3s3,1.3,3,3v56h64v-5c0-1.7,1.3-3,3-3s3,1.3,3,3v8C95,118.7,93.7,120,92,120z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '~/components/common/Icon/Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#fff"
      d="M64,113c24.8-14.7,40-40.9,40-70.1V27.8L64,13L24,27.8v15.1C24,72.1,39.2,98.3,64,113"
    />
    <path
      fill="#1fd4af"
      d="M89.9,111.1c-7.8-7.8-7.8-20.5,0-28.3c7.6-7.6,19.8-7.8,27.7-0.6c0.2,0.2-0.7,2.5-0.5,2.7c0.8,0.8,2.6,1.7,3.2,2c0.3,0.1-0.5,1-0.2,1c0.1,0,1.2-1.2,1.3-1c4.4,7.6,3.3,17.6-3.2,24.1C110.3,119,97.7,119,89.9,111.1z"
    />
    <path
      fill="#fff"
      d="M99.4,91.2c-1-1.3-2.9-1.5-4.2-0.5c-1.3,1-1.5,2.9-0.5,4.2l7,9c0.5,0.7,1.4,1.1,2.3,1.2c0,0,0.1,0,0.1,0c0.9,0,1.7-0.4,2.2-1l15.1-17c-1-1.8-2.3-3.4-3.8-4.7l-13.4,15L99.4,91.2z"
    />
    <path
      fill="#444b54"
      d="M64,116c-0.5,0-1-0.1-1.5-0.4C36.5,100.2,21,73,21,42.9V27.8c0-1.3,0.8-2.4,2-2.8l40-14.8c0.7-0.2,1.4-0.2,2.1,0L105,25c1.2,0.4,2,1.6,2,2.8v15.1c0,7.8-1.1,15.6-3.2,23.1c-0.4,1.6-2.1,2.5-3.7,2.1c-1.6-0.4-2.5-2.1-2.1-3.7c2-6.9,2.9-14.1,2.9-21.4v-13L64,16.2L27,29.9v13c0,28,14.4,53.2,38.5,67.5c1.4,0.8,1.9,2.7,1.1,4.1C66,115.5,65,116,64,116z"
    />
    <path
      fill="#444b54"
      d="M64,116c-1,0-2-0.5-2.6-1.5c-0.8-1.4-0.4-3.3,1.1-4.1c3.2-1.9,6.3-4,9.2-6.3c1.3-1,3.2-0.8,4.2,0.5c1,1.3,0.8,3.2-0.5,4.2c-3.1,2.5-6.4,4.8-9.9,6.8C65,115.9,64.5,116,64,116z"
    />
    <path
      fill="#d0d4d8"
      d="M64,101.6c-0.6,0-1.3-0.2-1.8-0.6c-15.9-11.7-26-29-28.6-48.5c-0.2-1.6,0.9-3.1,2.6-3.4c1.6-0.2,3.1,0.9,3.4,2.6c2.3,17.1,10.9,32.3,24.4,43c15.9-12.6,25-31.4,25-51.9v-4.6L64,29L37,39c-1.6,0.6-3.3-0.2-3.9-1.8c-0.6-1.6,0.2-3.3,1.8-3.9L63,23c0.7-0.2,1.4-0.2,2.1,0l28,10.4c1.2,0.4,2,1.6,2,2.8v6.7c0,23.2-10.6,44.4-29.2,58.1C65.3,101.4,64.6,101.6,64,101.6z"
    />
    <g>
      <path
        fill="#d0d4d8"
        d="M64,25.8v72.8c17.7-13.1,28-33.4,28-55.7v-6.7L64,25.8z"
      />
    </g>
  </Svg>
);

import React from 'react';
import { HeadlineStyled } from './Headline.styles';
import { ThemeColor } from '../ThemeProvider/ThemeProviderImplementation';

type HeadlineStyledProps = React.ComponentProps<typeof HeadlineStyled>;

type HeadlineLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

type HeadlineTag = HeadlineLevel | 'div';

type HeadlineProps = {
  level?: HeadlineLevel;
  tag?: HeadlineTag | 'inherit';
  id?: string;
  className?: string;
  children?: React.ReactNode;
  color?: ThemeColor;
} & typeof defaultProps &
  HeadlineStyledProps;

const defaultProps = {
  level: 'h2',
  tag: 'inherit',
  color: 'headline',
};

/**
 * Component: Headline
 */
export default function Headline({
  id,
  level,
  tag,
  className,
  children,
  color,
  // make sure css is not included in rest
  css,
  ...rest
}: HeadlineProps) {
  return (
    <HeadlineStyled
      id={id}
      data-level={level}
      as={tag === 'inherit' ? level : tag}
      className={className}
      color={color}
      {...rest}
    >
      {children}
    </HeadlineStyled>
  );
}

Headline.defaultProps = defaultProps;

import React from 'react';
import Parser from '~/components/common/Parser';

import { ListStyled, NumbersLargeCircleFilled } from './List.styles';

/**
 * Component: List
 */
export default function List({
  className = undefined,
  children = undefined,
  element = 'ul' || undefined,
  listItems = undefined,
  ...rest
}) {
  switch (element) {
    case 'ul':
    default:
      return (
        <ListStyled className={className} as={element} {...rest}>
          {children}
        </ListStyled>
      );
    case 'numbersLargeCircleFilled':
      return listItems ? (
        <NumbersLargeCircleFilled className={className} as="ol" {...rest}>
          {listItems.map(item => (
            <li key={item?.id}>
              <Parser html={item?.itemContent?.content} />
            </li>
          ))}
        </NumbersLargeCircleFilled>
      ) : null;
  }
}

import React from 'react';
import Parser from '~/components/common/Parser';

export default function SalesViewer() {
  const salesViewerScript = `
    <script type="text/javascript">!(function (s, a, l, e, sv, i, ew, er) {try {(a =s[a] || s[l] || function () {throw "no_xhr";}),(sv = i = "https://salesviewer.org"),(ew = function(x){(s = new Image()), (s.src = "https://salesviewer.org/tle.gif?sva=e3L5Q3W0k4I8&u="+encodeURIComponent(window.location)+"&e=" + encodeURIComponent(x))}),(l = s.SV_XHR = function (d) {return ((er = new a()),(er.onerror = function () {if (sv != i) return ew("load_err");
    (sv = "https://www.salesviewer.com/t"),  setTimeout(l.bind(null, d), 0);}),(er.onload = function () {(s.execScript || s.eval).call(er, er.responseText);}),er.open("POST", sv, !0),(er.withCredentials = true),er.send(d),er);}),l("h_json=" + 1 * ("JSON" in s && void 0 !== JSON.parse) + "&h_wc=1&h_event=" + 1 * ("addEventListener" in s) + "&sva=" + e);} catch (x) {ew(x)}})(window, "XDomainRequest", "XMLHttpRequest", "e3L5Q3W0k4I8");</script>

    <noscript><img src="https://salesviewer.org/e3L5Q3W0k4I8.gif" style="visibility:hidden;" /></noscript>
  `;

  return (
    <Parser html={salesViewerScript} />
  );
}

import styled from '~/utils/styled';

export interface LinkWrapperProps {
  margin?: string;
  variant: "mobile" | "default";
}
// Internal link in new tab
export const LinkWrapper = styled.a<LinkWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${ props => props.margin ? props.margin : '0 0 0 0.25rem' };
  cursor: pointer;
  text-decoration: none;
  color: ${ props => props.variant === 'default' ? 'white' : '#0c2a56' };

  &:hover {
    text-decoration: ${ props => props.variant === 'default' ? 'underline' : 'none' };
    color: ${ props => props.variant === 'default' ? 'white' : '#4569ff' };
  }
`

export const FlagIcon = styled.img`
  width: 1.325rem;
  height: 1.325rem;
  border-radius: 999px;
`

export const ExternalIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
export interface LinkTextProps {
  variant: "mobile" | "default";
}
export const LinkText = styled.span<LinkTextProps>`
  padding-left: 0.5rem;
  font-size: 12px;
  font-weight: ${ props => props.variant === 'default' ? 'normal' : '600' };
  font-size: ${ props => props.variant === 'default' ? '12px' : '15px' };
`
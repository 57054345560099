import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M15.7,11c-2,0-3.7,0.6-5.1,1.8c-1.4,1.2-2,2.7-2,4.5c0,1,0.4,1.6,1.1,1.6c0.4,0,0.7-0.1,1-0.3c0.2-0.2,0.4-0.4,0.4-0.7
	c0-1.5,0.4-2.6,1.3-3.3c0.8-0.8,2-1.2,3.3-1.2c1.6,0,2.9,0.5,3.8,1.5c0.9,1,1.3,2.2,1.3,3.5c0,1.2-0.4,2.4-1.3,3.6
	c-0.9,1.2-2,2.2-3.2,3.1l-3.7,2.7c-1.2,0.9-2.3,1.9-3.2,3C8.4,31.8,8,32.9,8,34.1v3.7c0,0.3,0.1,0.5,0.4,0.7
	c0.3,0.2,0.5,0.3,0.8,0.3h13.4c0.3,0,0.5-0.1,0.6-0.4c0.2-0.2,0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.6-0.3H10.5v-2.4
	c0-0.8,0.3-1.6,0.9-2.4c0.6-0.8,1.4-1.5,2.4-2.2l3.1-2.2c1.1-0.8,2.1-1.7,3.1-2.5c0.9-0.8,1.7-1.8,2.3-3c0.6-1.2,0.9-2.4,0.9-3.7
	c0-2.1-0.7-3.8-2.1-5.2S17.9,11,15.7,11z M34.2,11c-2.3,0-4.2,0.7-5.6,2c-1.4,1.3-2.1,3.4-2.1,6.1v11.8c0,2.8,0.7,4.9,2.1,6.2
	c1.4,1.3,3.3,2,5.6,2c2.3,0,4.2-0.7,5.6-2c1.4-1.3,2.1-3.4,2.1-6.2V19.1c0-2.8-0.7-4.8-2.1-6.1C38.4,11.7,36.6,11,34.2,11z
	 M34.2,13.3c3.5,0,5.3,1.9,5.3,5.8v11.8c0,3.9-1.8,5.8-5.3,5.8S29,34.7,29,30.9V19.1C29,15.2,30.7,13.3,34.2,13.3z"
    />
  </Svg>
);

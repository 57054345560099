import React, { createContext, useState, useContext, SetStateAction } from 'react';
import { Global } from '@emotion/core';
import { Flex, Box } from 'rebass';
import Headroom from 'react-headroom';
import Segment from '../Segment';
import Link, { LinkSideEffectContext } from '~/components/common/Link';
import { useSwitch } from '~/utils/hooks/useSwitch';
import { useLockBodyScroll } from '~/utils/hooks/useLockBodyScroll';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import Logo from '../Logo';

import {
  headroomStyles,
  HeaderDesktop,
  HeaderMobile,
  HeaderMobileInner,
  HeaderMobileWrapNav,
  HeaderMobileWrapMeta,
  SegmentWithShadow,
  Toggle,
} from './Header.styles';
import { PageContext } from '../Layout';

type HeaderProps = {
  navigationDesktop?: React.ReactNode;
  navigationMobile?: React.ReactNode;
  meta?: React.ReactNode;
  opaqueAtTop?: boolean;
};

export const HeaderIsPinnedContext = createContext(true);

export default function Header({
  navigationDesktop,
  navigationMobile,
  meta,
  opaqueAtTop,
}: HeaderProps) {
  const [isPinned, handlePin, handleUnpin] = useSwitch(false);
  const [isNavigationOpen, handleNavigationToggle] = useState(false);

  const navigationToggleWithCallbacks = (bool: Boolean) => {
    const trustbadge = document.querySelector('[id^="trustbadge-container-"]') ;

    if (trustbadge && bool) {
      // @ts-ignore
      trustbadge.style.display = 'none';
    } else if (trustbadge) {
      // @ts-ignore
      trustbadge.style.display = 'block';
    }
    const toggleState: SetStateAction<boolean> = bool as SetStateAction<boolean>;
    handleNavigationToggle(toggleState);
  }

  const { homeUri } = useContext(PageContext);

  const t = useCraftTranslations();

  useLockBodyScroll(isNavigationOpen);

  return (
    <HeaderIsPinnedContext.Provider value={isPinned}>
      <Headroom
        upTolerance={0}
        downTolerance={Infinity}
        // FIXME: isPinned should actually be isFixed
        onPin={handlePin}
        onUnpin={handlePin}
        onUnfix={handleUnpin}
        className={
          opaqueAtTop ? 'header--opaque-at-top' : 'header--transparent-at-top'
        }
      >
        <Global styles={headroomStyles} />

        <HeaderDesktop as="header">
          <Segment themeName="secondary">{meta}</Segment>
          <Segment themeName="default" className="header-main">
            <Flex
              py={1}
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Link to={homeUri}>
                <Logo />
              </Link>
              {navigationDesktop}
            </Flex>
          </Segment>
        </HeaderDesktop>

        <HeaderMobile as="header" expanded={isNavigationOpen}>
          <SegmentWithShadow
            className="header-main"
            themeName="default"
            expanded={isNavigationOpen}
          >
            <Flex
              py={1}
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Link to={homeUri} onClick={() => navigationToggleWithCallbacks(false)}>
                <Logo />
              </Link>
              <Toggle
                name={isNavigationOpen ? 'Cross' : 'Burger'}
                onClick={() => navigationToggleWithCallbacks(!isNavigationOpen)}
              />
            </Flex>
          </SegmentWithShadow>
          {isNavigationOpen ? (
            <HeaderMobileInner>
              <HeaderMobileWrapNav>
                <Segment themeName="default">
                  <LinkSideEffectContext.Provider
                    value={() => navigationToggleWithCallbacks(false)}
                  >
                    {navigationMobile}
                  </LinkSideEffectContext.Provider>
                </Segment>
              </HeaderMobileWrapNav>
              <HeaderMobileWrapMeta>
                <Segment themeName="secondary">{meta}</Segment>
              </HeaderMobileWrapMeta>
            </HeaderMobileInner>
          ) : null}
        </HeaderMobile>
      </Headroom>
    </HeaderIsPinnedContext.Provider>
  );
}

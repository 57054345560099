import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M25,11c-2.4,0-4.3,0.7-5.8,2c-1.4,1.3-2.2,3.4-2.2,6.1V20c0,5.1,2.6,7.6,7.7,7.6c2.6,0,4.6-0.8,5.8-2.5v5.8
	c0,3.9-1.8,5.8-5.5,5.8c-1.5,0-2.8-0.4-3.9-1.2c-1.1-0.8-1.6-1.8-1.6-3.1c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.2,0.4-1.2,1.2
	c0,1.8,0.7,3.3,2.1,4.6c1.4,1.3,3.3,1.9,5.8,1.9c2.4,0,4.4-0.7,5.9-2c1.5-1.3,2.2-3.4,2.2-6.2V19.1c0-2.8-0.7-4.8-2.2-6.1
	C29.3,11.7,27.4,11,25,11z M25,13.2c1.7,0,3.1,0.5,4.1,1.4c1,1,1.4,2.4,1.4,4.4v1.3c0,3.5-1.8,5.2-5.5,5.2c-1.8,0-3.2-0.4-4.1-1.3
	c-0.9-0.9-1.4-2.3-1.4-4.2V19C19.5,15.1,21.3,13.2,25,13.2z"
    />
  </Svg>
);

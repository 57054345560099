import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M16.6,11c-0.4,0-0.7,0.2-0.9,0.6c0,0-3.4,4.7-3.5,4.9C12,16.7,12,16.9,12,17.1c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3
	c0.3,0,0.5-0.2,0.7-0.5l1.5-2.5v22.5c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.4-0.4,0.4-0.7
	V11.9c0-0.3-0.1-0.5-0.3-0.7S16.9,11,16.6,11z M30,11c-4.8,0-7.2,2.2-7.2,6.6c0,3,1.1,5,3.4,6c-2.8,1.1-4.2,3.5-4.2,7.1v0.5
	c0,2.7,0.7,4.7,2.2,5.9c1.5,1.3,3.4,1.9,5.8,1.9c2.4,0,4.3-0.6,5.8-1.9c1.5-1.3,2.2-3.3,2.2-5.9v-0.5c0-3.7-1.4-6-4.2-7.1
	c2.3-0.9,3.4-2.9,3.4-6C37.2,13.2,34.8,11,30,11z M30,13.2c3.2,0,4.8,1.5,4.8,4.6c0,3.1-1.6,4.7-4.8,4.7c-3.2,0-4.7-1.6-4.7-4.7
	C25.3,14.7,26.8,13.2,30,13.2z M30,24.8c3.6,0,5.5,1.9,5.5,5.7V31c0,1.9-0.5,3.4-1.4,4.4s-2.3,1.5-4,1.5c-1.7,0-3.1-0.5-4.1-1.5
	s-1.4-2.4-1.4-4.4v-0.5C24.5,26.7,26.4,24.8,30,24.8z"
    />
  </Svg>
);

import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';

// themes
import * as azure from '~/themes/azure';
import * as peach from '~/themes/peach';
import * as sand from '~/themes/sand';
import * as zmart from '~/themes/zmart';
import * as licence from '~/themes/licence';

// supported cms themes
export enum ThemeGroupName {
  azure = 'azure',
  peach = 'peach',
  sand = 'sand',
  zmart = 'zmart',
  licence = 'licence',
}

export enum ThemeName {
  _default = '_default',
  faded = 'faded', // "Hellgrau"
  transparent = 'transparent', // "Transparent"
  primary = 'primary', // "Primärton (Zmart = Dunkelgrün)"
  secondary = 'secondary', // "Sekundärton (Zmart = Dunkelblau)"
  tertiary = 'tertiary', // "Tertiärton (Zmart = Hellgrün)"
  tertiaryBright = 'tertiaryBright', // "Tertiärton Hell (Zmart = Blassgrün)"
  quaternary = 'quaternary', // "Quartärton (Zmart = Grün)"
}

export type Theme = typeof zmart._default;

export type ThemeColor = keyof typeof zmart._default.colors;

export type ThemeMap = {
  [themeName in ThemeName]: Theme;
};

export type ThemeGroupMap = {
  [themeGroupName in ThemeGroupName]: ThemeMap;
};

/**
 * Available themes.
 */
const themeGroupMap: ThemeGroupMap = {
  [ThemeGroupName.azure]: azure,
  [ThemeGroupName.peach]: peach,
  [ThemeGroupName.sand]: sand,
  [ThemeGroupName.zmart]: zmart,
  [ThemeGroupName.licence]: licence,
};


type ThemeProviderImplementationProps = {
  name: ThemeName;
  group: ThemeGroupName;
  children: React.ReactNode;
};

export function ThemeProviderImplementation({
  name,
  group,
  children,
}: ThemeProviderImplementationProps) {
  const theme: Theme =
    themeGroupMap[group]?.[name] ?? themeGroupMap[group]?._default;

  return <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>;
}

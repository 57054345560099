import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M24.9,11c-2.1,0-3.8,0.6-5.2,1.8c-1.4,1.2-2.1,2.7-2.1,4.6c0,1,0.4,1.6,1.2,1.6c0.4,0,0.7-0.1,1-0.3
	c0.2-0.2,0.4-0.4,0.4-0.7c0-1.5,0.4-2.6,1.3-3.4s2-1.2,3.4-1.2c1.7,0,3,0.5,3.9,1.5c0.9,1,1.4,2.2,1.4,3.5c0,1.2-0.5,2.4-1.4,3.6
	c-0.9,1.2-2,2.2-3.3,3.1l-3.8,2.7c-1.3,0.9-2.4,1.9-3.3,3C17.4,32,17,33.1,17,34.3V38c0,0.3,0.1,0.5,0.4,0.7
	c0.3,0.2,0.5,0.3,0.8,0.3h13.9c0.3,0,0.5-0.1,0.7-0.4c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.7-0.3H19.6
	v-2.4c0-0.8,0.3-1.6,1-2.4c0.7-0.8,1.5-1.5,2.4-2.2l3.2-2.2c1.1-0.8,2.2-1.7,3.2-2.5c1-0.8,1.8-1.8,2.4-3s1-2.4,1-3.7
	c0-2.1-0.7-3.8-2.2-5.2C29,11.7,27.2,11,24.9,11z"
    />
  </Svg>
);

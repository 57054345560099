import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import { breakpointNavigation } from '~/themes/baseTheme';
import { Box } from 'rebass';
import BaseLink from '../Link';
import Icon from '../Icon';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '100vw',
  position: 'relative',

  [media(breakpointNavigation)]: {
    width: 477,
  },
}));

Container.defaultProps = {
  fontSize: 1,
};

export const HighlightItems = styled(Box)({});

export const DefaultItems = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.tertiaryBright,
  color: theme.colors.tertiaryBrightText,
}));

DefaultItems.defaultProps = {
  py: 1,
};

export const Link = styled(BaseLink)(({ theme, appearance }) => ({
  display: 'block',
  textDecoration: 'none',
  color: 'inherit',
  position: 'relative',
  borderBottom:
    appearance === 'highlight' ? `1px solid ${theme.colors.border}` : 0,

  '&:last-child': {
    borderBottom: 0,
  },

  [media(breakpointNavigation)]: {
    borderBottom: 0,
  },

  '&:hover': {
    backgroundColor: theme.colors.secondaryBright,
    color: theme.colors.secondaryBrightText,
    textDecoration: 'none',

    '& [data-description]': {
      color: 'inherit !important',
    },

    '& [data-chevron]': {
      transform: 'translate(5px, -50%)',
    },
  },
}));

export const Chevron = styled(Icon)(({ theme }) => ({
  position: 'absolute',
  right: theme.space[2],
  top: '50%',
  transform: 'translate(0, -50%)',
  transition: 'transform .3s ease-in-out',
  fontSize: 18,

  [media('sm')]: {
    right: theme.space[4],
  },

  [media('lg')]: {
    right: theme.space[5],
  },

  [media(breakpointNavigation)]: {
    right: theme.space[3],
    fontSize: 'inherit',
  },
}));

export const LinkBox = styled(Box)({});

LinkBox.defaultProps = {
  py: 1,
  pl: { _: 1, sm: 4, lg: 5, xl: 3 },
  pr: { _: 5, sm: 8, lg: 7, xl: 6 },
};

export const LinkText = styled(Box)({
  textTransform: 'uppercase',
  fontWeight: 700,
});

export const Description = styled(Box)({
  paddingTop: 15,
});

Description.defaultProps = {
  color: 'dim',
};

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 251.9 256">
    <path
      d="M200.8 48.5c-3.9 4.1-10.8 5.3-17.4-.2-32.7-27.4-80.2-27.8-113.3-.9-6.1 5-12.8 3.3-16.5-1-4.1-4.5-4.8-12 1.7-17.3 41.9-34 101.9-33.5 143.3 1.1 7.2 6.1 6.5 13.9 2.2 18.3z"
      fill="#f7813e"
    />
    <path
      d="M38 114.1v67.8c-18.8 0-34-15.2-34-33.9 0-13.9 8.5-26.5 21.4-31.6 1.3-.6 2.7-1 4.1-1.4 2.8-.6 5.6-.9 8.5-.9zM248 147.9c0 18.7-15.2 33.9-34 33.9h-.1V114h.1c4.5 0 9 .9 13.2 2.6 12.5 5.4 20.7 17.7 20.8 31.3z"
      fill="#5a9ace"
    />
    <path
      d="M37.9 107.1h19.8v83.8H37.9zM194.1 107.1h19.8v83.8h-19.8z"
      fill="#f8f8f8"
    />
    <path
      d="M162.9 240.9c0 12.6-28.3 11.9-37 10s-6.6-18.6 0-21.7 37-.8 37 11.7z"
      fill="#f7813e"
    />
    <path
      fill="#313131"
      d="M231.2 114.2c-.5-24.3-9.4-47.6-25.2-66 1.5-2.5 2.2-5.5 2-8.5-.2-3.3-1.5-8.1-6.8-12.6-42.8-35.8-105.1-36.2-148.4-1l-1.5 1.2c-5.3 5-6.3 12.8-2.9 18.9-16.8 18.6-26.4 42.6-27 67.6C8.4 120.1-.1 133.4 0 148c0 19.6 14.9 35.7 34 37.6v5.3c0 2.2 1.8 4 4 4h19.8c2.2 0 4-1.8 4-4v-83.8c0-2.2-1.8-4-4-4H38c-2.2 0-4 1.8-4 4v3.2c-1.5.1-2.9.4-4.4.7 1.2-21.8 9.8-42.6 24.4-59 2.5 1.6 5.3 2.5 8.3 2.5 3.8-.1 7.5-1.5 10.5-4 31.6-25.7 77-25.3 108.2.8 3.2 2.9 7.4 4.5 11.8 4.6 2.7 0 5.3-.7 7.6-2.1C214 69.9 222 90 223.1 111c-1.7-.4-3.4-.7-5.1-.9v-3.2c0-2.2-1.8-4-4-4h-19.8c-2.2 0-4 1.8-4 4v83.8c0 2.2 1.8 4 4 4H214c2.2 0 4-1.8 4-4v-5.2c1.5-.2 3-.4 4.5-.7-8.9 28.6-21 43.5-56.5 51.2-1.4-3.2-4.6-6.5-11.4-9-6.3-2.2-13-3.3-19.6-3.2-3.3 0-7.8.3-10.7 1.6-5.9 2.8-8.7 11.5-7.9 18.2.7 5.9 3.9 10.1 8.8 11.1 4.3.8 8.7 1.2 13.1 1.1 16 0 26-4.2 28.3-11.7 42.2-8.9 55.6-28.4 65.4-62.8 7.5-4 13.6-10.4 17-18.5 7.8-18.3-.1-39.6-17.8-48.6zm-93 133.9c-3.8 0-7.6-.3-11.4-1-1.8-.4-2.4-2.8-2.5-4.2-.5-4.3 1.4-9.2 3.4-10.1.7-.3 3-.9 7.2-.9 11.3 0 23.8 3.6 24 8.9v.2c-.1 4.9-10.4 7.1-20.7 7.1zM42 111.1h11.8v75.8H42v-75.8zm-8 7.2v59.3c-10.7-1.4-19.9-8.6-23.8-18.7-6.1-15.4 1.5-32.7 16.8-38.8 1.2-.5 2.4-.9 3.6-1.2 1.1-.2 2.2-.4 3.4-.6zm152-73.1c-16.7-14-37.9-21.7-59.7-21.7-21.4 0-42.1 7.4-58.6 20.8-1.5 1.3-3.4 2.1-5.4 2.2-2.2-.1-4.2-1-5.6-2.7-1.5-1.6-2.2-3.7-2.1-5.8.3-2.3 1.5-4.3 3.3-5.7C98.2-.5 156.2 0 196 33.4c2.5 2.1 3.9 4.5 4 6.9.1 1.8-.5 3.6-1.7 5-.1.1-.2.2-.3.2-.2.2-.4.4-.5.7-1.3 1.2-3 1.8-4.8 1.8-2.5-.1-4.8-1.1-6.7-2.8zm23.9 141.7h-11.8v-75.8h11.8v75.8zm17.4-12.2c-.3.1-.6.3-.9.4-2.7 1.2-5.5 2-8.4 2.4v-59.3c2.6.3 5.1 1 7.5 2l.2.1c6.8 2.9 12.3 8.1 15.5 14.8 6.8 14.9.7 32.5-13.9 39.6z"
    />
  </Svg>
);

import styled from '~/utils/styled';

export const Checkmark = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  transform: translateY(-50%);

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

type InputProps = {
  disabled?: boolean;
}

export const Input = styled.input<InputProps>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    background-color: ${props => props.disabled ? '#ccc' : '#2196F3' };
  }

  &:checked ~ .checkmark:after {
    display: block;
  }
`;

type LabelProps = {
  disabled?: boolean;
  fontSize?: number;
}

// eslint-disable-next-line
export const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: ${ props=> props.fontSize ? `${props.fontSize}px` : '18px'};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  color: ${props => props.disabled ? 'gray' : 'black' };

  &:hover .checkmark {
    background-color: ${props => props.disabled ? '#eee' : '#ccc' };
  }

  .checkmark:after {
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
import React from 'react';
import NavigationMobile from '~/components/common/NavigationMobile';
import Navigation from '~/components/common/Navigation';
import slugify from '~/utils/slugify'

type AnchorNavigationProps = {
  mobile?: boolean;
  anchorNav?: any;
  navigationCta?: any;
};

export default function AnchorNavigation({
  mobile,
  anchorNav,
  navigationCta
}: AnchorNavigationProps) {
  
  const navItems = (anchorNav ?? [])
    .map((item:any, index:number) => {
      if (!item || !item.anchorNavName) return {};
      return {
        id: index,
        link: {
          url: `#${slugify(item.anchorNavPath || item.anchorNavName || '')}` ?? undefined,
          text: `${item.anchorNavName}` ?? undefined,
          target: undefined,
        },
        appearance: undefined,
        children: []
      };
    })
    .filter((_:any) => _ && _.id);

  navigationCta && navItems.push({
    id: 'cta',
    link: {
      url: navigationCta?.url ?? undefined,
      text: navigationCta?.customText || navigationCta?.text || undefined,
      target: navigationCta?.target ?? undefined,
    },
    appearance: 'highlight',
  });
    
  if (mobile) {
    return <NavigationMobile navItems={navItems} />;
  }

  return <Navigation navItems={navItems} />;

  return null;
}
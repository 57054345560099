// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-articles-tsx": () => import("./../src/templates/blogArticles.tsx" /* webpackChunkName: "component---src-templates-blog-articles-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-calculator-conclude-contract-tsx": () => import("./../src/templates/calculatorConcludeContract.tsx" /* webpackChunkName: "component---src-templates-calculator-conclude-contract-tsx" */),
  "component---src-templates-calculator-tsx": () => import("./../src/templates/calculator.tsx" /* webpackChunkName: "component---src-templates-calculator-tsx" */),
  "component---src-templates-home-tsx": () => import("./../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-error-404-tsx": () => import("./../src/templates/error404.tsx" /* webpackChunkName: "component---src-templates-error-404-tsx" */),
  "component---src-templates-pages-tsx": () => import("./../src/templates/pages.tsx" /* webpackChunkName: "component---src-templates-pages-tsx" */)
}


import { graphql, useStaticQuery } from 'gatsby';
import { useContext, useMemo } from 'react';
import { PageContext } from '~/components/common/Layout';
import { Craft_GlobalsSet, Craft_TranslationsTable } from '~/query';

type TranslationMap = {
  [key: string]: string;
};

export function useCraftTranslations() {
  const { globalTranslations } = useCraftGlobals();

  return useMemo(() => {
    const translations = (globalTranslations?.translations ??
      []) as Craft_TranslationsTable[];
    const translationMap = translations.reduce(
      (obj, cur) => (cur.key ? { ...obj, [cur.key]: cur.translation } : obj),
      {},
    ) as TranslationMap;

    return function t(key: string) {
      return translationMap[key] || key;
    };
  }, [globalTranslations]);
}

export function useCraftGlobals(
  pageContext?: React.ContextType<typeof PageContext>,
) {
  const _pageContext = useContext(PageContext);
  const { site = '' } = pageContext || _pageContext;

  const data = useStaticQuery(graphql`
    query QueryGlobalNavigation {
      craft {
        mainDe: globals(site: "mainDe") {
          ...CraftGlobalFragments
        }

        mainEn: globals(site: "mainEn") {
          ...CraftGlobalFragments
        }

        landingVerpackungsgesetzDe: globals(
          site: "landingVerpackungsgesetzDe"
        ) {
          ...CraftGlobalFragments
        }

        #landingVerpackungsgesetzEn: globals(
        #  site: "landingVerpackungsgesetzEn"
        #) {
        #  ...CraftGlobalFragments
        #}

        landingVerpackungslizenzierungDe: globals(
          site: "landingVerpackungslizenzierungDe"
        ) {
          ...CraftGlobalFragments
        }

        #landingVerpackungslizenzierungEn: globals(
        #  site: "landingVerpackungslizenzierungEn"
        #) {
        #  ...CraftGlobalFragments
        #}
      }
    }
  `);

  return (data?.craft[site] ?? {}) as Craft_GlobalsSet;
}

export const globalFragments = graphql`
  fragment CraftGlobalFragments on Craft_GlobalsSet {
    ...CraftGlobalNavigationFragment
    ...CraftGlobalNavigationFooterFragment
    ...CraftGlobalNavigationMetaFragment
    ...CraftGlobalTranslationFragment
    ...CraftGlobalMetaFragment
    ...CraftGlobalSeoFragment
    ...CraftGlobalPagesFragment
    ...CraftGlobalPrivacyFragment
    ...CraftGlobalTrustedShopsFragment
    ...CraftGlobalCalculatorFragment
    ...CraftGlobalBrowserSupportFragment
    ...CraftGlobalBrandFragment
    ...CraftGlobalTreeCampaignFragment
    ...CraftGlobalIntCampaignFragment
    ...CraftGlobalSiteSettingsFragment
  }

  fragment CraftMenuItems on Craft_MenuItemsMenuItem {
    id
    menuItemLink {
      url
      text
      target
    }
    menuItemAppearance
    children {
      ... on Craft_MenuItemsMenuItemSub {
        id
        menuItemLink {
          url
          text
          target
        }
        menuItemAppearance
        menuItemDescription
      }
    }
  }

  fragment CraftGlobalNavigationFragment on Craft_GlobalsSet {
    globalNavigation {
      menuItems {
        ...CraftMenuItems
      }
    }
  }

  fragment CraftGlobalNavigationFooterFragment on Craft_GlobalsSet {
    globalNavigationFooter {
      menuItems {
        ...CraftMenuItems
      }
    }
  }

  fragment CraftGlobalNavigationMetaFragment on Craft_GlobalsSet {
    globalNavigationMeta {
      menuItems {
        ...CraftMenuItems
      }
      menuItemsSimplified
    }
  }

  fragment CraftGlobalTranslationFragment on Craft_GlobalsSet {
    globalTranslations {
      translations {
        key
        translation
      }
    }
  }

  fragment CraftGlobalPrivacyFragment on Craft_GlobalsSet {
    globalPrivacy {
      cookieInfoLink {
        text
        target
        url
      }
      cookieInfoText {
        content
      }
    }
  }

  fragment CraftGlobalMetaFragment on Craft_GlobalsSet {
    globalMeta {
      metaPoweredByImage {
        id
        url
      }
      metaPoweredByLink {
        text
        target
        url
      }
      metaBusinessHours
      metaMail
      metaPhone
      metaCustomerPortalLink {
        text
        target
        url
      }
    }
  }

  fragment CraftGlobalSeoFragment on Craft_GlobalsSet {
    globalSeo {
      seoTitleTemplate
    }
  }

  fragment CraftGlobalPagesFragment on Craft_GlobalsSet {
    globalPages {
      globalPagesHeaderImage {
        ...CraftImageFluid
      }
    }
  }

  fragment CraftGlobalTrustedShopsFragment on Craft_GlobalsSet {
    globalTrustedShops {
      trustedShopsId
      trustedShopsShowBadge
    }
  }

  fragment CraftGlobalBrowserSupportFragment on Craft_GlobalsSet {
    browserSupport {
      browserSupportHeadline
      browserSupportText {
        content
      }
    }
  }

  fragment CraftGlobalCalculatorFragment on Craft_GlobalsSet {
    globalCalculator {
      calculatorPaymentMehodsImage {
        ...CraftImageFluid
      }
      calculatorHeaderImage {
        ...CraftImageFluid
      }
      contractYears {
        year
        startDateRequired
        default
      }
      calculatorRegistrationNumberInfo {
        content
      }
      calculatorQuoteValue
      calculatorQuoteTitle
      calculatorQuoteMessage {
        content
      }
      calculatorQuoteLink {
        url
        text
        target
      }
    }
  }

  fragment CraftGlobalBrandFragment on Craft_GlobalsSet {
    globalBrand {
      themeGroup
    }
  }

  fragment CraftGlobalSiteSettingsFragment on Craft_GlobalsSet {
    globalSiteSettings {
      contractOrigin
      googleAnalyticsId
      forcedVoucherCode
    }
  }

  fragment CraftGlobalTreeCampaignFragment on Craft_GlobalsSet {
    treeCampaign {
      treeCampaignIsActive
      treeCampaignTitle
      treeCampaignLink {
        url
        text
        target
      }
      treeCampaignPartnerLink {
        url
        text
        target
      }
      treeCampaignPartnerLogo {
        ...CraftImageFluid
      }
      treeCampaignMaxTrees
      treeCampaignCo2PerTree
      treeCampaignStartDate
      treeCampaignEndDate
      treeCampaignIncrementHoursFrom
      treeCampaignIncrementHoursTo
      treeCampaignIncrementDays
      treeCampaignTreeCountLabel
      treeCampaignCo2CountLabel
    }
  }

  fragment CraftGlobalIntCampaignFragment on Craft_GlobalsSet {
    intCampaign {
      intCampaignBackgroundImage {
        ...CraftImageFluid
      }
      intCampaignIsActive
      intCampaignContent {
        content
      }
      intCampaignTitle
      intCampaignPartnerLink {
        url
        text
        target
      }
      intCampaignPartnerLogo {
        ...CraftImageFluid
      }
      intCampaignLink {
        url
        text
        target
      }
    }
  }
`;

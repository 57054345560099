import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 685.6 619.7">
    <path
      d="M221.9 138.3l166.5-14.6 35.4-59.3-47.6-55-231.7 19.5-.8 1.8L88 149.6h.2L0 338.7l40 56.7 23.5-68.1 70.5 1 88.3-189.6-.4-.4z"
      fill="#a5cda0"
    />
    <path
      d="M254.2 499.4l-96.7-137.6-70.1-.8-25.7 65.2 136.5 193.3-.1.2 342.6-29 28.8-61.7s-70.5 13.3-71.1 13.4l-33.2-61.2-211 18.2z"
      fill="#166045"
    />
    <path
      d="M685.1 280.9L487.1 0l-69.3 6.2 47.2 55-36 59.8c40.2 57.6 80.4 115.2 120.6 172.7h.2l-68.5 151.5 34.3 59.9 71.3-13.5 98.6-210.7h-.4z"
      fill="#519781"
    />
  </Svg>
);

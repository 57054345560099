import React, { useState } from 'react'
import Cookie from 'js-cookie'
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';

import {
  CookieContainer,
  CookieInnerContainer,
  CookieHeader,
  CookieBannerText,
  CookieHeadline,
  CookieContentBlock,
  ButtonContainer,
  CheckboxesWrapper,
} from '~/components/common/CookieBanner/CookieBanner.styles';
import { ButtonGroupItem } from '~/components/common/ButtonGroup';
import Button from '~/components/common/Button';

import TextLink from '~/components/common/TextLink';
import Logo from '../Logo';
import Checkbox from '~/components/common/Checkbox';

import { enableGoogleAnalytics } from '~/utils/tracking';

export interface CookieBannerProps {
  debug: boolean;
  analyticsId?: string;
}

export default function CookieBanner (props: CookieBannerProps) {
  const { debug, analyticsId } = props;
  const initialCookieState = Cookie.get('consent') === undefined || debug
  const initialAdState = Cookie.get('consentAds') !== undefined || debug
  const t = useCraftTranslations();
  const [consent, setConsent] = useState(false);
  const [visible, setVisible] = useState(initialCookieState);

  if (initialAdState) {
    enableGoogleAnalytics(analyticsId);
  }

  const setConsentCookie = () =>  {
    Cookie.set('consent', 'true', { sameSite: 'strict', expires: 365 })
  }

  const acceptAds = () => {
    enableGoogleAnalytics(analyticsId);
    Cookie.set('consentAds', 'true', { sameSite: 'strict', expires: 365 })
  }

  const save = () => {
    if (consent) {
      acceptAds();
    }

    setConsentCookie();
    setVisible(false);
  }

  const acceptAll = () => {
    acceptAds();
    setVisible(false);
    setConsentCookie();
  }


  if (!visible) {
    return(null);
  }

  return (
    <>
      <CookieContainer>
        <CookieInnerContainer>
          <CookieHeader>
            <Logo width={150} />
          </CookieHeader>

          <CookieBannerText>
            <CookieHeadline>{t('cookieBanner.header')}</CookieHeadline>
            <CookieContentBlock>
              {t('cookieBanner.text')}
              <TextLink to={t('cookieBanner.privacyLink')}>
                {` ${t('cookieBanner.privacyLinkText')}`}
              </TextLink>
            </CookieContentBlock>

            <CheckboxesWrapper>
              <CookieContentBlock>
                <Checkbox
                  onChange={() => {}}
                  text={t('cookieBanner.checkboxRequired')}
                  value
                  disabled
                />
              </CookieContentBlock>

              <CookieContentBlock>
                <Checkbox
                  onChange={() => setConsent(!consent)}
                  text={t('cookieBanner.checkboxOptional')}
                  value={consent}
                  disabled={false}
                />
              </CookieContentBlock>
            </CheckboxesWrapper>

          </CookieBannerText>

          <ButtonContainer>
            <ButtonGroupItem>
              <Button
                width="100%"
                type="button"
                variant="filled"
                textTransform="uppercase"
                to="#"
                onClick={() => {acceptAll()}}
              >
                {t('cookieBanner.acceptCookies')}
              </Button>
            </ButtonGroupItem>
            <ButtonGroupItem>
              <Button
                width="100%"
                type="button"
                variant="filledGrey"
                textTransform="uppercase"
                to="#"
                onClick={() => {save()}}
              >
                {t('cookieBanner.save')}
              </Button>
            </ButtonGroupItem>
          </ButtonContainer>
        </CookieInnerContainer>
      </CookieContainer>
    </>
  );
}
import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';

import Parser from '~/components/common/Parser';

import { NavItem } from './NavigationMeta.styles';

type NavItemBase = {
  id?: number;
  link?: {
    url?: string;
    text?: string;
    target?: string;
  };
  appearance?: 'default' | 'highlight';
};

type NavItemSub = NavItemBase & {
  description?: string;
};

type NavItem = NavItemBase & {
  children?: NavItemSub[];
};

type NavigationMetaProps = {
  navItems: NavItem[];
  hideFirstLink?: boolean;
  isVertical?: boolean;
};

export default function NavigationMeta({
  navItems,
  hideFirstLink,
  isVertical,
}: NavigationMetaProps) {
  return (
    <Flex
      as="nav"
      pt={isVertical ? { _: 0 } : { _: 2, lg: 2 }}
      pb={isVertical ? { _: 0 } : { _: 1, lg: 1 }}
      justifyContent={isVertical ? '' : { _: 'flex-start', xl: 'flex-end' }}
      flexDirection={isVertical ? { _: 'row' } : { _: 'row', xl: 'column' }}
      flexWrap="wrap"
    >
      {navItems.map(({ id, link, appearance }, index) => {
        if (!link) return null;
        if (index === 0 && hideFirstLink) return null;

        const { url, target, text } = link;

        return (
          <NavItem
            key={`nav-meta-${id}`}
            to={url}
            target={target}
            appearance={appearance}
            isVertical={isVertical}
          >
            <Parser html={text} />
          </NavItem>
        );
      })}
    </Flex>
  );
}

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#fff"
      d="M80.4,104.2c-0.3-5.7,3-15.5,3-31.7c0-4.5-1.2-13.3-5.8-22.3C70.7,36.4,72,14,72,14H56c0,0,1.3,22.4-5.7,36.2c-4.6,9-5.8,17.8-5.8,22.3c0,16.2,3.4,26,3,31.7c-0.3,4.9-2.2,8.5-3.1,14.1c-0.5,3,1.9,5.8,4.9,5.8H62h4h12.6c3.1,0,5.4-2.8,4.9-5.8C82.6,112.7,80.7,109.1,80.4,104.2z"
    />
    <path
      fill="#70e7d2"
      d="M83.5,118.2c-0.9-5.5-2.8-9.2-3.1-14.1c-0.3-5.7,3-15.5,3-31.7c0-4.5-1.9-13-5.8-22.3c-4-7.8-5.3-21.5-5.6-29.2H62l-0.6,11.7c-0.1,2.9-5.9-2.5-6.4,1c-0.8,5.7-2.3,11.6-4.7,16.5c-3.8,9.3-5.8,17.8-5.8,22.3c0,16.2,3.4,26,3,31.7c-0.3,4.9-2.2,8.5-3.1,14.1c-0.5,3,1.9,5.8,4.9,5.8H62h4h12.6C81.7,124,84,121.2,83.5,118.2z"
    />
    <path
      fill="#ffa869"
      d="M80.4,104.2c-0.3-5.7,3-15.5,3-31.7c0-4.5-1.2-13.3-5.8-22.3l-3.2-7.9H53.6l-3.2,7.9c-4.6,9-5.8,17.8-5.8,22.3c0,16.2,3.4,26,3,31.7c-0.3,4.9-2.2,8.5-3.1,14.1c-0.5,3,1.9,5.8,4.9,5.8H62h4h12.6c3.1,0,5.4-2.8,4.9-5.8C82.6,112.7,80.7,109.1,80.4,104.2z"
    />
    <path
      fill="#fff"
      d="M60.3,121H48.6c-1.7,0-3-1.3-3-3s1.3-3,3-3h11.7c1.7,0,3,1.3,3,3S62,121,60.3,121z"
    />
    <path
      fill="#f77c3f"
      d="M83.5,118.2c-0.9-5.5-2.8-9.2-3.1-14.1c-0.3-5.7,3-15.5,3-31.7c0-4.1-1-11.6-4.5-19.6c-0.7-1.7-2.4-2.7-4.2-2.7h0c-3.2,0-5.5,3.2-4.4,6.2c2.4,6.7,3.1,12.7,3.1,16.1c0,16.2-3.4,26-3,31.7c0.3,4.9,2.2,8.5,3.1,14.1c0.5,3,5.1,5.8,5.1,5.8C81.7,124,84,121.2,83.5,118.2z"
    />
    <path
      fill="#70e9d4"
      d="M44.6,72.5c0,6.5,0.5,11.9,1.2,16.5h36.5c0.6-4.6,1.2-10,1.2-16.5c0-3-0.5-7.9-2.2-13.5H46.8C45.1,64.6,44.6,69.5,44.6,72.5z"
    />
    <path
      fill="#009f96"
      d="M81.2,59h-10c1.7,5.6,2.2,10.5,2.2,13.5c0,6.5-0.5,11.9-1.2,16.5h10c0.6-4.6,1.2-10,1.2-16.5C83.4,69.5,82.9,64.6,81.2,59z"
    />
    <path
      fill="#3f4a54"
      d="M78.6,127H49.4c-2.4,0-4.6-1-6.1-2.8c-1.5-1.8-2.2-4.1-1.8-6.4c0.4-2.6,1.1-4.8,1.6-6.8c0.7-2.4,1.3-4.5,1.5-6.9c0.1-2-0.3-4.9-0.9-8.6c-0.9-5.5-2.1-13.1-2.1-22.9c0-5.7,1.6-14.8,6.1-23.7c2.5-4.8,4.1-10,4.9-15.5c0.2-1.6,1.8-2.8,3.4-2.5c1.6,0.2,2.8,1.8,2.5,3.4c-0.9,6.1-2.8,11.9-5.5,17.3c-5.2,10.2-5.4,19.2-5.4,21c0,9.3,1.1,16.3,2,21.9c0.6,4,1.2,7.2,1,9.9c-0.2,3.1-0.9,5.7-1.7,8.2c-0.5,1.9-1.1,3.9-1.5,6.1c-0.1,0.8,0.2,1.3,0.4,1.6c0.2,0.3,0.7,0.7,1.5,0.7h29.2c0.8,0,1.3-0.4,1.5-0.7c0.2-0.3,0.6-0.8,0.4-1.6c-0.4-2.3-0.9-4.2-1.5-6.1c-0.7-2.5-1.5-5.2-1.7-8.2c-0.2-2.7,0.3-5.8,1-9.9c0.9-5.6,2-12.7,2-21.9c0-1.7-0.3-10.8-5.4-21c-7.3-14.4-6-27.3-6-27.9c0.2-1.6,1.7-2.8,3.3-2.7c1.6,0.2,2.8,1.7,2.7,3.3c0,0.1-1.1,11.8,5.4,24.5c4.5,8.8,6.1,18,6.1,23.7c0,9.8-1.2,17.4-2.1,22.9c-0.6,3.7-1.1,6.6-0.9,8.6c0.1,2.4,0.8,4.5,1.5,6.9c0.6,2,1.2,4.2,1.6,6.8c0.4,2.3-0.3,4.7-1.8,6.4C83.2,126,81,127,78.6,127z"
    />
    <path
      fill="#ffa869"
      d="M72,18H56c-1.7,0-3-1.3-3-3s1.3-3,3-3h16c1.7,0,3,1.3,3,3S73.7,18,72,18z"
    />
    <path
      fill="#f77c3f"
      d="M72,18h-4c-1.7,0-3-1.3-3-3s1.3-3,3-3h4c1.7,0,3,1.3,3,3S73.7,18,72,18z"
    />
    <path
      fill="#fff"
      d="M70.1,76.5H50.6c-1.7,0-3-1.3-3-3s1.3-3,3-3h19.5c1.7,0,3,1.3,3,3S71.7,76.5,70.1,76.5z"
    />
    <path
      fill="#fff"
      d="M52.5,97c-1.4,0-2.7-1.1-3-2.5c-0.3-1.6-0.5-3.2-0.8-5c-0.5-3.8-1.2-9.6-1.2-16.9c0-4.2,1-9.6,2.7-14.5c0.8-2.2,1.7-4.4,2.7-6.5c0.7-1.4,1.3-2.8,1.9-4.2c1.2-2.8,2.1-5.8,2.7-9c0.6-3.2,0.9-6.5,0.9-10.3V18h11v3h-5v7c0,4.2-0.3,7.9-1,11.5c-0.7,3.5-1.7,6.9-3,10.1c-0.6,1.6-1.4,3.1-2.1,4.7c-0.9,1.8-1.7,3.7-2.4,5.7c-2.2,6.4-2.4,11.2-2.4,12.5c0,6.8,0.6,12.2,1.2,16.1c0.2,1.8,0.5,3.4,0.8,4.9c0.3,1.6-0.9,3.2-2.5,3.4C52.9,97,52.7,97,52.5,97z"
    />
    <path
      fill="#3f4a54"
      d="M94,127H77.9c-1.7,0-3-1.3-3-3s1.3-3,3-3H94c1.7,0,3,1.3,3,3S95.7,127,94,127z"
    />
    <path
      fill="#3f4a54"
      d="M109,127c-0.8,0-1.6-0.3-2.1-0.9c-0.6-0.6-0.9-1.3-0.9-2.1s0.3-1.6,0.9-2.1c0.7-0.7,1.7-1,2.7-0.8c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.4,0.4c0.6,0.6,0.9,1.3,0.9,2.1c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.2C109.4,127,109.2,127,109,127z"
    />
    <path
      fill="#fff"
      d="M53.3 104.3A3 3 0 1 0 53.3 110.3A3 3 0 1 0 53.3 104.3Z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 401 131">
    <rect x="52.09" y="39.25" width="34.46" height="56.28" fill="#ff5f00" />
    <path
      d="M55.64,67.4A35.73,35.73,0,0,1,69.31,39.26a35.8,35.8,0,1,0,0,56.29A35.74,35.74,0,0,1,55.64,67.4Z"
      fill="#eb001b"
    />
    <path
      d="M123.81,89.58V88.43h.49v-.24h-1.18v.24h.47v1.15Zm2.29,0V88.19h-.36l-.43,1-.43-1h-.36v1.39h.26v-1l.39.91h.27l.39-.91v1Z"
      fill="#f79e1b"
    />
    <path
      d="M127.23,67.4A35.8,35.8,0,0,1,69.31,95.53a35.8,35.8,0,0,0,0-56.28,35.81,35.81,0,0,1,57.92,28.14Z"
      fill="#f79e1b"
    />
    <rect x="227.57" y="11.64" width="169.01" height="107.36" fill="#fff" />
    <rect x="230.39" y="101.55" width="163.38" height="14.65" fill="#fab400" />
    <rect x="230.39" y="14.46" width="163.38" height="14.65" fill="#1c2f76" />
    <path
      d="M295.79,45.24,278.91,85.48h-11l-8.31-32.14a4.39,4.39,0,0,0-2.47-3.53,43.38,43.38,0,0,0-10.27-3.43l.24-1.17h17.74a4.86,4.86,0,0,1,4.81,4.11L274,72.64l10.84-27.39Zm43.13,27.12c.05-10.63-14.66-11.22-14.59-16,0-1.44,1.41-3,4.42-3.38A19.77,19.77,0,0,1,339,54.82l1.82-8.53a27.91,27.91,0,0,0-9.74-1.79c-10.3,0-17.55,5.48-17.61,13.3-.06,5.8,5.18,9,9.12,11s5.42,3.24,5.4,5c0,2.71-3.24,3.92-6.26,3.92a21.77,21.77,0,0,1-10.69-2.55L309.17,84a31.89,31.89,0,0,0,11.59,2.15c10.95,0,18.11-5.4,18.11-13.77m27.18,13.14h9.63L367.3,45.25h-8.86a4.75,4.75,0,0,0-4.44,3L338.36,85.48h11l2.15-6h13.38Zm-11.6-14.23,5.48-15.1,3.13,15.1Zm-43.78-26L302,85.5h-10.4l8.63-40.26Z"
      fill="#1c2f76"
    />
    <line
      x1="177.27"
      y1="24"
      x2="177.27"
      y2="107.04"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </Svg>
);

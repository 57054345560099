import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#fff"
      d="M72.5,114h16.7c4.6,0,8.3-3.7,8.3-8.3V40c0-2.2-0.9-4.3-2.4-5.9L85,24H43.4l-9.8,9.8 c-1.6,1.6-2.4,3.7-2.4,5.9v66c0,4.6,3.7,8.3,8.3,8.3H72.5"
    />
    <path
      fill="#e5e5e5"
      d="M85.149,24.15L72.8,36.5V114l12.5-10.6c-0.289,0-0.021-73.813-0.001-79.102L85.149,24.15"
    />
    <path
      fill="#8bd5ec"
      d="M60.3,53.2H43.7c-2.3,0-4.2,1.9-4.2,4.2v8.3c0,2.3,1.9,4.2,4.2,4.2h16.7c2.3,0,4.2-1.9,4.2-4.2v-8.3 C64.5,55,62.6,53.2,60.3,53.2z"
    />
    <path
      fill="#454b54"
      d="M99,33.4L88.3,22.8v-4.6c0-4-3.2-7.2-7.2-7.2H47.8c-4,0-7.2,3.2-7.2,7.2v4.6L31.4,32 c-1.9,1.9-3.1,4.5-3.3,7.2c0,0.2,0,0.5,0,0.7V40v8.6v57.1c0,6.2,5.1,11.3,11.3,11.3h50c6.199,0,11.3-5.1,11.3-11.3V37.9 C100.8,36.2,100.2,34.6,99,33.4z M73.2,31.9c-0.601,0.6-1.101,1.2-1.5,1.9c-0.7-0.2-1.4-0.4-2.101-0.4H38.4l6.5-6.5h33.2L73.2,31.9z M47.8,17h33.3c0.601,0,1.2,0.5,1.2,1.2V21H46.7v-2.8C46.7,17.5,47.2,17,47.8,17z M34.2,105.7V48.6V40c0-0.1,0-0.1,0-0.2 c0-0.2,0.1-0.3,0.3-0.3h35c0.2,0,0.3,0.1,0.3,0.3V40v9.7V111H39.5C36.6,111,34.2,108.6,34.2,105.7z M94.8,105.7 c0,2.899-2.399,5.3-5.3,5.3H75.8V49.6V40c0-1.4,0.601-2.8,1.601-3.8l7.899-7.9l9.4,9.4c0.1,0.1,0.1,0.1,0.1,0.2V105.7z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      fill="#DDCEB1"
      d="M8.9,17.2l-1.4,3.6c-3.1,7.8-3.7,16.4-1.7,24.6l0.8,3.1h35.9l0.8-3.1c2-8.2,1.4-16.8-1.7-24.6l-1.4-3.6V7.9
	L37.1,11c-0.5,0.5-1.2,0.5-1.6,0l-2.3-2.3c-0.5-0.5-1.2-0.5-1.6,0L29.3,11c-0.5,0.5-1.2,0.5-1.6,0l-2.3-2.3c-0.5-0.5-1.2-0.5-1.6,0
	L21.5,11c-0.5,0.5-1.2,0.5-1.6,0l-2.3-2.3c-0.5-0.5-1.2-0.5-1.6,0L13.7,11c-0.5,0.5-1.2,0.5-1.6,0L8.9,7.9L8.9,17.2"
    />
    <path
      fill="#444B54"
      d="M42.7,20.4L41.4,17v-9c0-0.5-0.3-0.9-0.7-1.1c-0.4-0.2-0.9-0.1-1.3,0.3l-3,3L34,7.9c-0.9-0.9-2.4-0.9-3.3,0
	l-2.2,2.3l-2.3-2.3c-0.9-0.9-2.4-0.9-3.3,0l-2.2,2.3l-2.3-2.3C17.5,7,16,7,15.1,7.9l-2.2,2.3L9.8,7.1C9.4,6.7,8.9,6.6,8.5,6.8
	C8,7,7.7,7.5,7.7,7.9v9l-1.4,3.4c-3.2,8.1-3.8,16.8-1.7,25.3l0.8,3.1c0.1,0.5,0.6,0.9,1.1,0.9c0.1,0,0.2,0,0.3,0
	c0.6-0.2,1-0.8,0.9-1.4l-0.8-3.1c-2-8-1.4-16.2,1.6-23.8l0.4-0.9l0.4,0.9c3,7.6,3.6,15.9,1.6,23.8L10,48.2c-0.1,0.4,0,0.7,0.2,1
	c0.2,0.3,0.5,0.5,0.9,0.5h31.3c0.5,0,1-0.4,1.1-0.9l0.8-3.1C46.6,37.2,46,28.4,42.7,20.4z M42.2,45.1l-0.5,2.2H12.8l0.4-1.6
	c2.1-8.4,1.5-17.2-1.7-25.3L10.1,17v-6.3l1.1,1.1c0.9,0.9,2.4,0.9,3.3,0l2.3-2.3l2.3,2.3c0.9,0.9,2.4,0.9,3.3,0l2.3-2.3l2.3,2.3
	c0.9,0.9,2.4,0.9,3.3,0l2.3-2.3l2.3,2.3c0.9,0.9,2.4,0.9,3.3,0l1.1-1.1V16H16.9c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h22.7
	l1.1,2.9C43.6,28.9,44.2,37.1,42.2,45.1z"
    />
  </Svg>
);

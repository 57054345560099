import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#fff"
      d="M90.3,94v-3h-16l5.1-3.1C85.8,83.6,90,76.3,90,68c0-13.3-10.7-24-24-24c-4.1,0-8,1-11.3,2.8l0,0L21,67c-10,4.8-17,15.1-17,27v0c0,16.6,13.4,30,30,30h50l40-30H90.3z"
    />
    <path
      fill="#444b54"
      d="M127.6,92.5c-0.5-1-1.6-1.5-2.8-1.5l-33.5,0c-1.5,0-2.8,1-3.1,2.4C88,95.3,89.4,97,91.3,97H116l-32,24H35.6C20.8,121,8.3,109.4,8,94.6c-0.2-9.4,4.5-17.8,11.7-22.8h0c1-0.7,2.1-1.4,3.3-1.9l0.4-0.2C26.8,68,30.8,67,35,67h1.5c11.4,0,21.1,8.8,21.5,20.2c0.5,11.9-9.1,21.8-21,21.8h-1.6c-7.8,0-14.7-5.9-15.4-13.7c-0.8-8.8,6.2-16.2,14.8-16.3c1.6,0,3-1.2,3.1-2.8c0.1-1.7-1.3-3.2-3-3.2c-11.8,0-21.4,9.9-21,21.8c0.4,11.4,10.1,20.2,21.5,20.2H37c5.4,0,10.4-1.6,14.6-4.3c0.1,0,0.2-0.1,0.2-0.1l30.2-20.1C89.5,85.4,94,77,94,68c0-14.9-12.1-27-27-27c-4.4,0-8.9,1.1-12.7,3.2c0,0-0.1,0-0.1,0.1c0,0-35.6,21.1-36.5,21.7C8,71.9,1.7,82.8,2,95c0.5,17.9,15.7,32,33.6,32H85c0.6,0,1.3-0.2,1.8-0.6l40-30C128,95.5,128.4,93.9,127.6,92.5z M56.2,49.4c3-1.6,6.4-2.4,9.8-2.4c11.6,0,21,9.4,21,21c0,7-3.5,13.5-9.3,17.4l-16,10.6c0.8-2.5,1.2-5.3,1.2-8.1c0-14.6-11.7-26.6-26.2-27L56.2,49.4z"
    />
  </Svg>
);

import React from 'react';
import {
  Container,
  LinkBox,
  Link,
  LinkText,
  Description,
  HighlightItems,
  DefaultItems,
  Chevron,
} from './NavigationDropdown.styles';

type NavItemBase = {
  id?: number;
  link?: {
    url?: string;
    text?: string;
    target?: string;
  };
  appearance?: 'default' | 'highlight';
};

type NavItemSub = NavItemBase & {
  description?: string;
};

type NavigationDropdownProps = {
  navItems: NavItemSub[];
  mobile?: boolean;
  onLinkClick?: () => void;
};

export default function NavigationDropdown({
  navItems,
  mobile,
  onLinkClick = undefined,
}: NavigationDropdownProps) {
  const highlightNavItems = navItems.filter(
    ({ appearance }) => appearance === 'highlight',
  );

  const defaultNavItems = navItems.filter(
    ({ appearance }) => appearance !== 'highlight',
  );

  return (
    <Container>
      {highlightNavItems.length ? (
        <HighlightItems data-first-dropdown-section>
          {highlightNavItems.map(({ id, link, description }) => {
            if (!link) return;
            return (
              <Link
                key={`nav-main-child-${mobile ? 'mobile-' : ''}-${id}`}
                to={link.url}
                onClick={onLinkClick}
                target={link.target}
                appearance="highlight"
              >
                <LinkBox py={2}>
                  <LinkText>{link.text}</LinkText>
                  <Description data-description>{description}</Description>
                  <Chevron name="ChevronRight" data-chevron />
                </LinkBox>
              </Link>
            );
          })}
        </HighlightItems>
      ) : null}

      {defaultNavItems.length ? (
        <DefaultItems>
          {defaultNavItems.map(({ id, link }) => {
            if (!link) return;

            return (
              <Link
                key={`nav-main-child-${mobile ? 'mobile-' : ''}-${id}`}
                to={link.url}
                onClick={onLinkClick}
                target={link.target}
              >
                <LinkBox>
                  <LinkText>{link.text}</LinkText>
                  <Chevron name="ChevronRight" data-chevron />
                </LinkBox>
              </Link>
            );
          })}
        </DefaultItems>
      ) : null}
    </Container>
  );
}

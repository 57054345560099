import React, { createElement, useContext } from 'react';
import { LogoStyled } from './Logo.styles';
import * as logos from './logos';
import { PageContext } from '~/components/common/Layout';

export type LogoNames = keyof typeof logos;

type LogoProps = {
  [key: string]: any;
};

/**
 * Available logos.
 *
 * To add a new logo, simply create a component in logos/ and
 * import it in logos/index.js
 */
interface LogoMap {
  [logo: string]: React.ReactNode;
}

const availableLogos: LogoMap = (Object.keys(logos) as Array<LogoNames>).reduce(
  (logoElements, logoKey) => ({
    ...logoElements,
    [logoKey]: createElement(logos[logoKey]),
  }),
  {},
);

export default function Logo(props: LogoProps) {
  const { site = '' } = useContext(PageContext);
  const logo = availableLogos[site] || availableLogos['mainDe'];
  if (!logo) return null;
  return <LogoStyled {...props}>{logo}</LogoStyled>;
}

import React from 'react';
import { Svg } from '~/components/common/Icon/Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#2B9E7D"
      d="M106.7,84.5c-0.8-0.3-2.4-0.6-2.4-0.6c5.8,0.1,11.3-3.3,13.5-9.1c2.7-7.2-1-15.3-8.2-18c-3.2-1.2-6.6-1.1-9.5-0.1l0,0l-0.1-0.4c2.5-1.9,4.5-4.5,5.7-7.7c3.4-9.1-1.2-19.1-10.3-22.5s-19.1,1.2-22.5,10.3c-1.4,3.8-1.4,7.8-0.3,11.4c-1.4-3.5-4.2-6.4-8-7.8c-7.2-2.7-15.3,1-18,8.2c-2.1,5.7-0.3,12,4.2,15.7c0,0-1.4-0.8-2.2-1.1c-7.2-2.7-15.3,1-18,8.2c-1.3,3.5-1,6.9,0.3,10.1c6.9,17.5,29.3,24.5,34.3,25.8l0,0c4.7,2.3,26.2,11.7,42.8,3c3.1-1.6,5.6-4,6.9-7.5C117.6,95.3,114,87.2,106.7,84.5z"
    />
    <path
      fill="#444B54"
      d="M111.7 85c-1 0-2-.5-2.6-1.5-.8-1.4-.3-3.3 1.1-4.1 2.2-1.3 3.9-3.2 4.8-5.6 1-2.8.9-5.7-.3-8.4-1.2-2.7-3.4-4.7-6.2-5.7-2.4-.9-5-.9-7.5 0-.8.3-1.8.2-2.5-.3-.8-.5-1.3-1.2-1.4-2.1L97 56.8c-.2-1.1.3-2.2 1.1-2.8 2.2-1.7 3.8-3.9 4.7-6.4 2.8-7.5-1-15.9-8.5-18.7-4.5-1.7-9.6-1-13.5 1.8-1.3 1-3.2.6-4.2-.7-1-1.3-.6-3.2.7-4.2 5.6-4 12.7-4.9 19.1-2.5 5.1 1.9 9.2 5.7 11.5 10.7s2.5 10.6.6 15.7c-.4 1.2-1 2.3-1.6 3.4 1.3.2 2.5.5 3.8.9 8.8 3.3 13.3 13.1 10 21.9-1.4 3.7-4 6.8-7.4 8.7C112.8 84.9 112.3 85 111.7 85zM92.1 116.6c-1.8 0-3.6-.1-5.4-.3-1.6-.2-2.8-1.6-2.7-3.3.2-1.6 1.7-2.8 3.3-2.7 7.5.8 14-.2 19.3-3 2.7-1.4 4.5-3.3 5.5-5.9 1-2.8.9-5.8-.4-8.5-.7-1.5-.1-3.3 1.4-4 1.5-.7 3.3-.1 4 1.4 2 4.2 2.2 8.9.5 13.2-1.5 4-4.3 7.1-8.3 9.1C104.4 115.3 98.6 116.6 92.1 116.6z"
    />
    <path
      fill="#52DDB2"
      d="M85.3,74.2c-0.8,0-2.4,0.2-2.4,0.2c5.5-1.9,9.4-7.1,9.4-13.2c0-7.7-6.3-14-14-14c-3.4,0-6.5,1.2-9,3.3v0l-0.2-0.4c1.7-2.7,2.7-5.8,2.7-9.2c0-9.7-7.8-17.5-17.5-17.5s-17.5,7.8-17.5,17.5c0,4.1,1.4,7.8,3.7,10.8c-2.6-2.7-6.2-4.5-10.2-4.5c-7.7,0-14,6.3-14,14c0,6.1,4,11.3,9.4,13.2c0,0-1.6-0.2-2.4-0.2c-7.7,0-14,6.3-14,14c0,3.7,1.5,6.8,3.8,9.4c12.6,14,36,12.7,41.2,12.2l0,0c5.2,0.5,28.6,1.8,41.2-12.2c2.4-2.6,3.8-5.7,3.8-9.4C99.3,80.4,93,74.2,85.3,74.2z"
    />
    <path
      fill="#444B54"
      d="M40.6,112.6c-0.1,0-0.2,0-0.3,0c-8.9-0.9-21.2-3.9-29.4-13.1c-1.1-1.2-1-3.1,0.2-4.2c1.2-1.1,3.1-1,4.2,0.2c6.9,7.7,17.7,10.3,25.5,11.1c1.6,0.2,2.8,1.6,2.7,3.3C43.4,111.5,42.1,112.6,40.6,112.6z"
    />
    <path
      fill="#444B54"
      d="M13.1 100.5c-.8 0-1.6-.3-2.2-1-3.1-3.3-4.6-7.2-4.6-11.4 0-7.3 4.6-13.5 11.1-15.9-2.6-3-4.1-6.9-4.1-11.1 0-8.1 5.8-15.2 13.8-16.7 1.6-.3 3.2.8 3.5 2.4.3 1.6-.8 3.2-2.4 3.5-5.2 1-8.9 5.5-8.9 10.8 0 4.7 3 8.9 7.4 10.4 1.5.5 2.3 2 1.9 3.5-.4 1.5-1.8 2.5-3.3 2.3-.6-.1-1.6-.2-2-.2-6.1 0-11 4.9-11 11 0 2.8 1 5.2 3 7.4 1.1 1.2 1.1 3.1-.2 4.2C14.6 100.3 13.9 100.5 13.1 100.5zM40.5 54.6c-.9 0-1.8-.4-2.4-1.1-2.9-3.6-4.4-8-4.4-12.6 0-11.3 9.2-20.5 20.5-20.5s20.5 9.2 20.5 20.5c0 1.7-1.3 3-3 3s-3-1.3-3-3c0-8-6.5-14.5-14.5-14.5s-14.5 6.5-14.5 14.5c0 3.3 1.1 6.3 3.1 8.9 1 1.3.8 3.2-.5 4.2C41.8 54.4 41.2 54.6 40.5 54.6zM61.1 113c-1.8 0-3.7-.1-5.7-.2-1.7-.1-2.9-1.5-2.8-3.2.1-1.7 1.5-2.9 3.2-2.8 6 .4 26.5.8 37.4-11.3 1.1-1.2 3-1.3 4.2-.2 1.2 1.1 1.3 3 .2 4.2C93.9 103.8 83.1 113 61.1 113z"
    />
    <path
      fill="#444B54"
      d="M95.5 100.5c-.7 0-1.5-.3-2-.8-1.2-1.1-1.3-3-.2-4.2 2-2.2 3-4.6 3-7.4 0-6.1-4.9-11-11-11-.4 0-1.5.1-2 .2-1.5.2-3-.8-3.3-2.3s.5-3 1.9-3.5c4.4-1.5 7.4-5.7 7.4-10.4 0-6.1-4.9-11-11-11-3.1 0-5.9 1.2-8 3.5-1.1 1.2-2.9 1.3-4.1.2-1.2-1-1.4-2.9-.4-4.1 2-2.6 3.1-5.7 3.1-8.9 0-8-6.5-14.5-14.5-14.5-1.7 0-3-1.3-3-3s1.3-3 3-3c11.3 0 20.5 9.2 20.5 20.5 0 1.3-.1 2.5-.3 3.8 1.3-.3 2.5-.4 3.8-.4 9.4 0 17 7.6 17 17 0 4.2-1.5 8-4.1 11.1 6.5 2.4 11.1 8.6 11.1 15.9 0 4.3-1.6 8.1-4.6 11.4C97.1 100.2 96.3 100.5 95.5 100.5zM59.1 125.7c-.9 0-1.8-.4-2.4-1.2-.5-.6-11.8-16.1-5.5-80.1.2-1.6 1.6-2.9 3.3-2.7 1.6.2 2.9 1.6 2.7 3.3-5.9 60.7 4.2 75.7 4.3 75.9 1 1.3.8 3.2-.5 4.2C60.4 125.5 59.7 125.7 59.1 125.7z"
    />
    <path
      fill="#444B54"
      d="M50.4 77.6c-.7 0-1.3-.2-1.9-.7l-9.8-8c-1.3-1-1.5-2.9-.4-4.2 1-1.3 2.9-1.5 4.2-.4l9.8 8c1.3 1 1.5 2.9.4 4.2C52.1 77.2 51.2 77.6 50.4 77.6zM52.8 65.9c-.9 0-1.8-.4-2.4-1.2-1-1.3-.7-3.2.6-4.2l6.3-4.7c1.3-1 3.2-.7 4.2.6 1 1.3.7 3.2-.6 4.2l-6.3 4.7C54 65.7 53.4 65.9 52.8 65.9zM61.5 90.6c-1 0-2-.5-2.6-1.4-.9-1.4-.4-3.3 1-4.1l10.3-6.4c1.4-.9 3.3-.4 4.1 1 .9 1.4.4 3.3-1 4.1l-10.3 6.4C62.6 90.4 62 90.6 61.5 90.6z"
    />
    <path
      fill="#FFF"
      d="M46.6 35.3c-.8 0-1.6-.3-2.2-1-1.1-1.2-1-3.1.2-4.2 2.7-2.4 6.1-3.7 9.7-3.7 1.7 0 3 1.3 3 3s-1.3 3-3 3c-2.1 0-4.1.8-5.7 2.2C48 35 47.3 35.3 46.6 35.3zM42.8 43.8c-.2 0-.4 0-.6-.1-.2 0-.4-.1-.6-.2-.2-.1-.3-.2-.5-.3-.2-.1-.3-.2-.5-.4-.6-.6-.9-1.3-.9-2.1s.3-1.6.9-2.1c1.1-1.1 3.1-1.1 4.2 0 .1.1.3.3.4.5.1.2.2.3.3.5.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5-.1.1-.3.3-.4.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2C43.2 43.8 43 43.8 42.8 43.8z"
    />
    <g>
      <path
        fill="#444B54"
        d="M88,127H34c-1.7,0-3-1.3-3-3s1.3-3,3-3h54c1.7,0,3,1.3,3,3S89.7,127,88,127z"
      />
    </g>
    <g>
      <path
        fill="#444B54"
        d="M103,127c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.4-0.5s0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.2c0.4-0.1,0.8-0.1,1.2,0c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.3-0.4,0.4C104.6,126.7,103.8,127,103,127z"
      />
    </g>
  </Svg>
);

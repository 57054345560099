import React from 'react';
import GatsbyImage from '~/components/common/ImageWithIEPolyfill';
import { fluid as getFluidProps } from './sharpAdapter';

/**
 * Works like fluid image (fixed not supported yet) from gatsby plugin sharp and
 * gatsby image.
 * Use all gatsby image props normally.
 * Supports maxWidth / maxHeight from gatsby plugin sharp with the args prop.
 *
 * See:
 *  - https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-plugin-sharp#fluid
 *  - https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#gatsby-image-props
 */

type CraftGatsbyImageProps = {
  fluid: any;
  args?: any;
  title?: string;
  altText?: string;
  imgStyle?: any;
  objectFit?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'contain'
    | 'cover'
    | 'fill'
    | 'none'
    | 'scale-down'
    | undefined;
  objectPosition?: string;
  aspectRatio?: number;
} & Omit<
  React.ComponentProps<typeof GatsbyImage>,
  'fluid' | 'objectFit' | 'objectPosition'
>;

export default function CraftGatsbyImage({
  fluid,
  args,
  title,
  altText,
  imgStyle = {},
  objectFit = 'cover',
  objectPosition = '50% 50%',
  aspectRatio,
  ...rest
}: CraftGatsbyImageProps) {
  const _fluid =
    fluid && fluid.width
      ? getFluidProps(fluid, args, { aspectRatio })
      : undefined;

  if (!_fluid) {
    console.warn('Image misconfigured.');
    return null;
  }

  return (
    <GatsbyImage
      fluid={_fluid}
      {...rest}
      title={title}
      alt={altText}
      objectFit={objectFit as any}
      objectPosition={objectPosition}
      imgStyle={imgStyle}
    />
  );
}

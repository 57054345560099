import React from 'react';

import { TextStyled } from './Text.styles';

/**
 * Component: Text
 */
export default function Text({
  className = undefined,
  children = undefined,
  fontSize = undefined,
  ...rest
}) {
  return (
    <TextStyled
      className={className}
      fontSize={fontSize || 2}
      as="p"
      {...rest}
    >
      {children}
    </TextStyled>
  );
}

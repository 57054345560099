import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 22 16">
    <path d="M1.28.11H20.72A1.28,1.28,0,0,1,22,1.4h0a1.28,1.28,0,0,1-1.27,1.29H1.28A1.3,1.3,0,0,1,0,1.4H0A1.28,1.28,0,0,1,1.28.11Z" />
    <path d="M1.28,6.71H20.72A1.28,1.28,0,0,1,22,8h0a1.28,1.28,0,0,1-1.27,1.29H1.28A1.3,1.3,0,0,1,0,8H0A1.28,1.28,0,0,1,1.28,6.71Z" />
    <path d="M1.28,13.31H20.72A1.28,1.28,0,0,1,22,14.6h0a1.28,1.28,0,0,1-1.28,1.29H1.28A1.28,1.28,0,0,1,0,14.6H0A1.3,1.3,0,0,1,1.28,13.31Z" />
  </Svg>
);

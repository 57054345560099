import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 123.59 30.18">
    <path
      fill="#253b80"
      d="M46.21,6.75H39.37a1,1,0,0,0-.94.8L35.67,25.09a.57.57,0,0,0,.47.65H39.5a1,1,0,0,0,.94-.81l.74-4.73a1,1,0,0,1,.94-.8h2.16c4.51,0,7.11-2.18,7.79-6.5A5.29,5.29,0,0,0,51.2,8.5C50.22,7.35,48.5,6.75,46.21,6.75Zm.79,6.4c-.37,2.46-2.25,2.46-4.06,2.46h-1L42.63,11a.57.57,0,0,1,.56-.48h.48c1.23,0,2.4,0,3,.71A2.25,2.25,0,0,1,47,13.15Zm19.65-.08H63.38a.58.58,0,0,0-.56.49l-.15.91-.23-.33c-.71-1-2.29-1.37-3.87-1.37a7.53,7.53,0,0,0-7.31,6.58,6.17,6.17,0,0,0,1.22,5,5.13,5.13,0,0,0,4.13,1.67,6.28,6.28,0,0,0,4.53-1.87l-.15.91a.57.57,0,0,0,.48.65h3a1,1,0,0,0,.94-.81l1.77-11.21a.58.58,0,0,0-.48-.65Zm-4.56,6.38a3.66,3.66,0,0,1-3.7,3.13,2.73,2.73,0,0,1-2.19-.89,2.77,2.77,0,0,1-.52-2.3,3.68,3.68,0,0,1,3.67-3.15,2.79,2.79,0,0,1,2.19.89A2.88,2.88,0,0,1,62.09,19.45Zm22-6.38H80.81a1,1,0,0,0-.79.42l-4.54,6.69-1.92-6.43a1,1,0,0,0-.92-.68H69.41a.57.57,0,0,0-.57.57.58.58,0,0,0,0,.19l3.62,10.64-3.4,4.81a.55.55,0,0,0,.13.79.57.57,0,0,0,.33.11h3.29a1,1,0,0,0,.78-.41L84.56,14a.56.56,0,0,0-.14-.79.53.53,0,0,0-.32-.1Z"
    />
    <path
      fill="#179bd7"
      d="M95,6.75H88.15a1,1,0,0,0-.94.8L84.45,25.09a.57.57,0,0,0,.47.65h3.6a.67.67,0,0,0,.66-.57l.78-5a1,1,0,0,1,.94-.8h2.16c4.51,0,7.11-2.18,7.79-6.5A5.26,5.26,0,0,0,100,8.5C99,7.35,97.28,6.75,95,6.75Zm.79,6.4c-.37,2.46-2.25,2.46-4.06,2.46h-1L91.41,11a.58.58,0,0,1,.57-.48h.47c1.23,0,2.4,0,3,.71A2.25,2.25,0,0,1,95.78,13.15Zm19.65-.08h-3.27a.57.57,0,0,0-.56.49l-.15.91-.23-.33c-.7-1-2.28-1.37-3.86-1.37a7.53,7.53,0,0,0-7.31,6.58,6.14,6.14,0,0,0,1.22,5,5.11,5.11,0,0,0,4.12,1.67,6.25,6.25,0,0,0,4.53-1.87l-.14.91a.57.57,0,0,0,.47.65h3a1,1,0,0,0,.94-.81L116,13.73a.57.57,0,0,0-.48-.65Zm-4.56,6.38a3.65,3.65,0,0,1-3.7,3.13,2.74,2.74,0,0,1-2.19-.89,2.77,2.77,0,0,1-.52-2.3,3.68,3.68,0,0,1,3.67-3.15,2.79,2.79,0,0,1,2.19.89A2.84,2.84,0,0,1,110.87,19.45ZM119.3,7.23l-2.81,17.86a.57.57,0,0,0,.47.65h2.91a1,1,0,0,0,.94-.81l2.77-17.53a.58.58,0,0,0-.47-.66h-3.25A.56.56,0,0,0,119.3,7.23Z"
    />
    <path
      fill="#253b80"
      d="M7.27,29.15l.52-3.32-1.17,0H1.06L4.93,1.29A.31.31,0,0,1,5.24,1h9.38C17.74,1,19.88,1.67,21,3a4.44,4.44,0,0,1,1,1.92,7,7,0,0,1,0,2.64l0,.08v.68l.53.29a3.87,3.87,0,0,1,1.07.82,3.73,3.73,0,0,1,.86,1.93,8.37,8.37,0,0,1-.12,2.82,9.78,9.78,0,0,1-1.16,3.18,6.56,6.56,0,0,1-1.82,2,7.49,7.49,0,0,1-2.46,1.11,12.52,12.52,0,0,1-3.07.35h-.73a2.22,2.22,0,0,0-1.43.53A2.18,2.18,0,0,0,13,22.62l-.06.3L12,28.78l0,.21a.18.18,0,0,1-.06.13.17.17,0,0,1-.1,0Z"
    />
    <path
      fill="#179bd7"
      d="M23.05,7.67c0,.18-.06.36-.1.55-1.23,6.35-5.47,8.54-10.87,8.54H9.33A1.33,1.33,0,0,0,8,17.89L6.6,26.83l-.4,2.53a.7.7,0,0,0,.58.81h5a1.17,1.17,0,0,0,1.16-1l.05-.25.92-5.83.06-.32a1.17,1.17,0,0,1,1.16-1h.73c4.73,0,8.43-1.92,9.51-7.47.45-2.32.22-4.26-1-5.62A4.75,4.75,0,0,0,23.05,7.67Z"
    />
    <path
      fill="#222d65"
      d="M21.75,7.15a11.62,11.62,0,0,0-1.2-.27,15.07,15.07,0,0,0-2.42-.17H10.77a1.17,1.17,0,0,0-1.16,1L8.05,17.6l0,.29a1.33,1.33,0,0,1,1.32-1.13h2.75c5.4,0,9.64-2.19,10.87-8.54,0-.19.07-.37.1-.55a6.49,6.49,0,0,0-1-.43Z"
    />
    <path
      fill="#253b80"
      d="M9.61,7.7a1.17,1.17,0,0,1,1.16-1h7.36a15.23,15.23,0,0,1,2.42.17A9.39,9.39,0,0,1,22,7.24a5.9,5.9,0,0,1,1,.43c.37-2.35,0-3.95-1.27-5.4S17.85,0,14.62,0H5.24A1.33,1.33,0,0,0,3.92,1.13L0,25.9a.8.8,0,0,0,.67.92H6.6L8.05,17.6Z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 24 24">
    <path d="M12,10.6L7.9,6.6c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l0,0l0,0l4.1,4.1l-4.1,4.1c-0.4,0.4-0.4,1,0,1.4
      c0.4,0.4,1,0.4,1.4,0l0,0l4.1-4.1l4.1,4.1c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4l0,0l0,0L13.4,12l4.1-4.1c0.4-0.4,0.4-1,0-1.4
      c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.3,0c0,0,0,0,0,0L12,10.6z"/>
  </Svg>
);

import styled from '~/utils/styled';

import Link from '~/components/common/Link';

export const TextLinkStyled = styled(Link)(props => ({
  color: props.theme.links.color,
  cursor: 'pointer',
  textDecoration: 'none',
  fontWeight: 700,

  // State: hover
  '&:hover': {
    textDecoration: 'underline',
    color: props.theme.links.colorHover,
  },
}));

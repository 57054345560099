import React from 'react';
import { Helmet } from 'react-helmet';
import { fontToPreload } from './Layout.styles';

export default function PreloadFonts() {
  return (
    <Helmet>
      {fontToPreload.map(font => (
        <link
          key={font}
          href={font}
          rel="preload"
          as="font"
          type="font/woff2"
          crossorigin="crossorigin"
        />
      ))}
    </Helmet>
  );
}

import { basicColors, pallet } from '~/themes/baseTheme';

export const colors = {
  ...basicColors,
  text: pallet.zodiac500,
  headline: pallet.zodiac500,

  primary: pallet.crusta500,
  primaryBright: pallet.zodiac100,
  primaryText: basicColors.background,
  primaryBrightText: basicColors.background,

  secondary: pallet.zodiac300,
  secondaryBright: pallet.crusta500,
  secondaryText: basicColors.background,
  secondaryBrightText: basicColors.background,

  tertiary: pallet.crusta100,
  tertiaryBright: pallet.grey050,
  tertiaryText: basicColors.text,
  tertiaryBrightText: basicColors.text,

  quaternary: pallet.zodiac100,
  quaternaryText: basicColors.text,

  errorBg: pallet.red025,
  errorText: pallet.red900,

  successBg: pallet.green025,
  successText: pallet.green750,

  pallet,
};

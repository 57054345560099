import styled from '~/utils/styled';
import { Box, Flex } from 'rebass';
import Link from '../Link';
import Button from '../Button';
import media from '~/utils/mediaqueries';

export const InlineBox = styled(Box)({
  display: 'inline-block',
});

type NavBarProps = {
  disableDimming?: boolean;
};

export const NavBar = styled(Flex)<NavBarProps>(({ disableDimming }) => ({
  '&:hover [data-navitem]': {
    opacity: disableDimming ? 1 : 0.5,
  },
}));

NavBar.defaultProps = {
  mx: { _: -1, xxl: -2 },
};

export const NavItemWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const NavItem = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  textTransform: 'uppercase',
  textDecoration: 'none',
  fontWeight: 600,
  cursor: 'pointer',
  transition:
    'opacity 200ms cubic-bezier(0.3, 0.1, 0.4, 0.9), color 180ms ease-in-out',
  padding: theme.space[1],
  [media('xxl')]: {
    padding: `${theme.space[1]}px ${theme.space[2]}px`,
  },
  '&:hover': {
    color: theme.colors.secondaryBright,
    // well… I guess opacity should be a number:
    opacity: ('1 !important' as unknown) as number,
  },
}));

export const NavButtonWrapper = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.space[1]}px`,
  [media('xxl')]: {
    padding: `0 ${theme.space[2]}px`,
  },
  position: 'relative',
}));

export const NavButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: 'inherit',
  fontWeight: 600,
}));

export const DropdownSlot = styled(Box)({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  perspective: '1500px',
});

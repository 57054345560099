import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#f8d0af"
      d="M98.2,49l-7.8-19V16.3c0-2.4-1.9-4.3-4.301-4.3H38.6h-4.3c-2.4,0-4.3,1.9-4.3,4.3l-2.6,22.1l9.5-8.7 l1.251,1.395L30.8,49c-1.7,4.2-2.6,8.6-2.6,13.2v49.5c0,2.399,1.9,4.3,4.3,4.3H80h10.4h6c2.5,0,4.399-1.9,4.399-4.3V62.2 C100.8,57.7,99.9,53.2,98.2,49z"
    />
    <path
      fill="#dfbb9d"
      d="M90.4,30L90.4,30l-7.8,19c-1.7,4.2-2.6,8.6-2.6,13.2V116l10.4-8.5V30"
    />
    <path
      fill="#fff"
      d="M63.6,59.7H46.3c-2.4,0-4.3,1.9-4.3,4.3v8.7c0,2.399,1.9,4.3,4.3,4.3h17.3c2.4,0,4.3-1.9,4.3-4.3V64 C67.9,61.6,66,59.7,63.6,59.7z"
    />
    <path
      fill="#454b54"
      d="M79.2,41.4c-0.8,0-1.7-0.4-2.2-1L71.2,34L64,40.6c-0.6,0.5-1.4,0.8-2.2,0.8s-1.5-0.4-2.1-1L54,34 l-7.3,6.6c-0.6,0.5-1.4,0.8-2.2,0.8s-1.5-0.4-2.1-1L36.7,34l-7.3,6.6c-0.9,0.8-2.3,1-3.4,0.5c-1.1-0.6-1.8-1.8-1.6-3l2.6-22 c0.1-4,3.3-7.1,7.3-7.1h51.8c4,0,7.301,3.3,7.301,7.3v4.3c0,1.7-1.301,3-3,3c-1.7,0-3-1.3-3-3v-4.3c0-0.7-0.601-1.3-1.301-1.3 c-0.699,0-1.3,0.6-1.3,1.3c0,0.1,0,0.2,0,0.3l-2.6,22.1c-0.101,1.2-0.9,2.2-2.101,2.5C79.8,41.4,79.5,41.4,79.2,41.4z M36.9,26.8 c0,0,0.1,0,0.2,0c0.8,0,1.5,0.4,2.1,1l5.8,6.4l7.3-6.6c0.6-0.5,1.4-0.8,2.2-0.8s1.5,0.4,2.1,1l5.8,6.4l7.3-6.6 c0.6-0.5,1.399-0.8,2.2-0.8c0.8,0,1.5,0.4,2.1,1l3.3,3.7l1.8-15.3c0-0.4,0-0.8,0.101-1.1H34.3c-0.7,0-1.3,0.6-1.3,1.3 c0,0.1,0,0.2,0,0.3l-1.7,14.1l3.6-3.3C35.4,27,36.1,26.8,36.9,26.8z"
    />
    <path
      fill="#454b54"
      d="M101,47.9l-7.8-19c-0.5-1.1-1.6-1.9-2.8-1.9s-2.301,0.7-2.801,1.9l-7.8,19C77.9,52.5,77,57.3,77,62.2 V113H32.6c-0.7,0-1.3-0.6-1.3-1.3V62.2c0-4.1,0.8-8.2,2.4-12c0.6-1.5-0.1-3.3-1.6-3.9c-1.5-0.6-3.3,0.1-3.9,1.6 c-1.9,4.6-2.8,9.4-2.8,14.3v49.5c0,4,3.3,7.3,7.3,7.3h47.5h16.4c4,0,7.301-3.3,7.301-7.3V62.2C103.8,57.2,102.9,52.4,101,47.9z M97.8,111.7c0,0.7-0.6,1.3-1.3,1.3H83.1V62.2c0-4.1,0.801-8.2,2.4-12l5-12.2l5,12.2c1.6,3.8,2.4,7.9,2.4,12v49.5H97.8z"
    />
  </Svg>
);

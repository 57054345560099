import React, { useContext } from 'react';
import { Box, Flex } from 'rebass';
import Segment from '../Segment';
import Logo from '../Logo';
import { PageContext } from '~/components/common/Layout';
import {
  useCraftGlobals,
  useCraftTranslations,
} from '~/utils/hooks/useCraftGlobals';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';

export enum FooterVariant {
  Normal = 'footerNormal',
  Small = 'footerSmall',
}

type FooterProps = {
  columnLeft?: React.ReactNode;
  columnRight?: React.ReactNode;
  meta?: React.ReactNode;
  metaReduced?: React.ReactNode;
  badges?: React.ReactNode;
  variant?: FooterVariant;
  children?: React.ReactNode;
  hideColumns?: boolean;
};

export default function Footer({
  columnLeft,
  columnRight,
  meta,
  metaReduced,
  badges,
  variant,
  children,
  hideColumns,
}: FooterProps) {
  const t = useCraftTranslations();

  const { site = '' } = useContext(PageContext);

  const paymentIconsImage = useCraftGlobals()?.globalCalculator
    ?.calculatorPaymentMehodsImage?.[0];

  const legalAndPaymentMethods = (
    <>
      {paymentIconsImage && (
        <Box
          pt="4px"
          css={{
            maxWidth: 320,
          }}
        >
          <CraftGatsbyImage fluid={paymentIconsImage} />
        </Box>
      )}

      <Box mt="0.25em" color="dim" fontSize={0}>
        {t('CalculatorLegalNoticeShort')}
      </Box>
    </>
  );

  const gridProps = {
    mx: { _: 0, lg: -3 },
  };

  const gridItemProps = {
    px: { _: 0, lg: 3 },
  };

  switch (variant) {
    case FooterVariant.Normal:
    default:
      return (
        <footer>
          <Segment themeName="tertiaryBright">
            {hideColumns ? null : (
              <Flex
                {...gridProps}
                pt={{ _: 2, lg: 3, xl: 4 }}
                pb={{ _: 1, lg: 3, xl: 4 }}
                flexDirection={{ _: 'column', xl: 'row' }}
                justifyContent={{ _: 'flex-start', xl: 'space-between' }}
                alignItems={{ _: 'flex-start', xl: 'flex-start' }}
              >
                <Box {...gridItemProps} width={{ _: 1, xl: 5 / 7 }}>
                  {columnLeft}
                </Box>
                <Box {...gridItemProps} width={{ _: 1, xl: 2 / 7 }}>
                  {columnRight}
                  {badges}
                </Box>
              </Flex>
            )}
          </Segment>
          <Segment themeName="white">
            <Flex
              mt={1}
              mb={1}
              flexDirection={{ _: 'column', md: 'row' }}
              justifyContent={{ _: 'flex-start', md: 'space-between' }}
              alignItems={{ _: 'flex-start', md: 'center' }}
              flexWrap="wrap"
            >
              <Box mb={{ _: 1, md: 0 }}>
                <Box>{meta}</Box>
                <Box>{legalAndPaymentMethods}</Box>
              </Box>
              {children ? (
                children
              ) : site.match(/^main/) ? (
                <Logo width={100} greyscale />
              ) : null}
            </Flex>
          </Segment>
        </footer>
      );
    case FooterVariant.Small:
      return (
        <footer>
          <Segment themeName="white">
            <Box mt={1} mb={1}>
              {metaReduced}
            </Box>
            <Box mt={1} mb={{ _: 2, xl: 1 }}>
              {legalAndPaymentMethods}
            </Box>
          </Segment>
        </footer>
      );
  }
}

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M17.6,11c-0.4,0-0.7,0.2-1,0.6c0,0-3.5,4.8-3.6,5c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
	s0.5-0.2,0.7-0.5l1.5-2.5V38c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.4-0.4,0.4-0.7V12
	c0-0.3-0.1-0.5-0.3-0.7C18.1,11.1,17.9,11,17.6,11z"
    />
    <path
      d="M32.3,11c-0.5,0-0.9,0.3-1.2,0.8l-9.6,18.9c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.7,0.3,1,1,1h9.8V38c0,0.3,0.1,0.6,0.4,0.7
	c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.4-0.4,0.4-0.7v-5.7h2c0.3,0,0.5-0.1,0.7-0.4c0.2-0.2,0.3-0.5,0.3-0.8
	c0-0.3-0.1-0.6-0.2-0.8S37,30,36.7,30h-2v-7.5c0-0.3-0.1-0.5-0.4-0.7s-0.5-0.2-0.9-0.2c-0.8,0-1.3,0.3-1.3,1V30h-7.6l8.8-17.5
	c0.1-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.5-0.4-0.7C32.9,11.1,32.6,11,32.3,11z"
    />
  </Svg>
);

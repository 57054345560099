import React from 'react';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';
import {
  ThemeProviderImplementation,
  ThemeName,
  ThemeGroupName,
} from './ThemeProviderImplementation';

/**
 * This translates legacy CMS themeNames to the new color-independent names.
 * Can be removed eventually if all branches have cought up.
 */
const legacyThemeNames: { [oldThemeName: string]: ThemeName } = {
  white: ThemeName._default,
  offwhite: ThemeName.faded,
  greenDark: ThemeName.primary,
  blueDark: ThemeName.secondary,
  greenBright: ThemeName.tertiary,
  greenLight: ThemeName.tertiaryBright,
  greenNew: ThemeName.quaternary,
};

type ThemeProviderProps = {
  name?: string;
  children: React.ReactNode;
} & typeof defaultProps;

const defaultProps = {
  name: '_default',
};

export default function ThemeProvider({ name, children }: ThemeProviderProps) {
  // DEMOTIME
  // const themeGroupName = useContext(ThemeGroupCtx);
  const globals = useCraftGlobals();
  const groupName =
    ((globals.globalBrand?.themeGroup as unknown) as ThemeGroupName) ??
    ThemeGroupName.zmart;

  const themeName = legacyThemeNames[name] ?? name;

  return (
    <ThemeProviderImplementation name={themeName} group={groupName}>
      {children}
    </ThemeProviderImplementation>
  );
}

ThemeProvider.defaultProps = defaultProps;

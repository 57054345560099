import { css } from '@emotion/core';

import { pallet } from '~/themes/baseTheme';
import { toPx } from '~/utils/styles';
import { _default as defaultTheme } from '~/themes/zmart';

// Font: Open Sans
import FontOpenSans600Woff from '~/assets/fonts/open-sans/open-sans-v16-latin-600.woff';
import FontOpenSans600Woff2 from '~/assets/fonts/open-sans/open-sans-v16-latin-600.woff2';
import FontOpenSans700Woff from '~/assets/fonts/open-sans/open-sans-v16-latin-700.woff';
import FontOpenSans700Woff2 from '~/assets/fonts/open-sans/open-sans-v16-latin-700.woff2';
import FontOpenSansItalicWoff from '~/assets/fonts/open-sans/open-sans-v16-latin-italic.woff';
import FontOpenSansItalicWoff2 from '~/assets/fonts/open-sans/open-sans-v16-latin-italic.woff2';
import FontOpenSansWoff from '~/assets/fonts/open-sans/open-sans-v16-latin-regular.woff';
import FontOpenSansWoff2 from '~/assets/fonts/open-sans/open-sans-v16-latin-regular.woff2';

// Font: Unit Rounded
import FontUnitRoundedWoff from '~/assets/fonts/unit-rounded/1378588/cdbecebd-e2d4-4ed4-8309-6ca6c9240c05.woff';
import FontUnitRoundedWoff2 from '~/assets/fonts/unit-rounded/1378588/e3a56a4d-ea2a-47a4-87d0-fcd77fd10a05.woff2';
import FontUnitRounded500Woff from '~/assets/fonts/unit-rounded/1378576/1d330248-e6cb-49f0-9fa2-f1e4eafc4f59.woff';
import FontUnitRounded500Woff2 from '~/assets/fonts/unit-rounded/1378576/2777391c-5cbf-48e5-823d-27116421e911.woff2';
import FontUnitRounded700Woff from '~/assets/fonts/unit-rounded/1378552/5094574f-d312-4f89-94e8-2a23250e5d5c.woff';
import FontUnitRounded700Woff2 from '~/assets/fonts/unit-rounded/1378552/7daa0c4a-0d33-469a-baa2-d3bc0576bc41.woff2';
import media from '~/utils/mediaqueries';

export const fontToPreload = [
  FontOpenSans600Woff2,
  FontOpenSans700Woff2,
  FontOpenSansItalicWoff2,
  FontOpenSansWoff2,
  FontUnitRoundedWoff2,
  FontUnitRounded500Woff2,
  FontUnitRounded700Woff2,
];

export const globalStyles = css`
  @font-face {
    font-family: 'Unit Rounded';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(${FontUnitRoundedWoff2}) format('woff2'),
      url(${FontUnitRoundedWoff}) format('woff');
  }

  @font-face {
    font-family: 'Unit Rounded';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url(${FontUnitRounded500Woff2}) format('woff2'),
      url(${FontUnitRounded500Woff}) format('woff');
  }

  @font-face {
    font-family: 'Unit Rounded';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(${FontUnitRounded700Woff2}) format('woff2'),
      url(${FontUnitRounded700Woff}) format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
      url(${FontOpenSansWoff2}) format('woff2'),
      url(${FontOpenSansWoff}) format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
      url(${FontOpenSansItalicWoff2}) format('woff2'),
      url(${FontOpenSansItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url(${FontOpenSans600Woff2}) format('woff2'),
      url(${FontOpenSans600Woff}) format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
      url(${FontOpenSans700Woff2}) format('woff2'),
      url(${FontOpenSans700Woff}) format('woff');
  }

  html {
    box-sizing: border-box;
  }

  body {
    background-color: ${pallet.white};
    font-family: ${defaultTheme.fonts.base};
    font-size: ${toPx(defaultTheme.fontSizes[2])};
    line-height: 1.7;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }

  * {
    margin: 0;
    padding: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  *:focus {
    outline-color: ${pallet.green900};
  }

  button {
    cursor: pointer;

    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize line-height. Cannot be changed from normal in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable input types in iOS */
    -webkit-appearance: none;
  }

  dl {
    margin: ${toPx(defaultTheme.space[1])} 0;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  dt {
    flex-basis: 50%;
    max-width: 50%;
    font-weight: 600;
    text-transform: uppercase;
    font-size: ${toPx(defaultTheme.fontSizes[0])};
  }

  dd {
    flex-basis: 50%;
    max-width: 50%;
  }

  .mandatetext {
    p + p {
      margin-top: ${toPx(defaultTheme.space[1])};
    }
  }

  .mui-snackbar-success,
  .mui-snackbar-info {
    background-color: ${pallet.green100} !important;
    color: ${pallet.grey800} !important;
    border: 1px solid #d3e6ab;
  }

  .mui-snackbar-warning {
    color: ${pallet.grey800} !important;
    border: 1px solid #f19600;
  }

  .mui-snackbar-error {
    background-color: ${pallet.red900} !important;
    color: ${pallet.white} !important;
    border: 1px solid #840c0c;
  }

  .mui-snackbar {
    flex-wrap: nowrap !important;
    padding: 17px !important;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16) !important;

    > div > span > svg {
      display: none;
    }

    .MuiSnackbarContent-message {
      padding: 0;
      hyphens: auto;
      word-break: break-word;
      overflow: hidden;
    }

    p {
      margin: 0;
    }

    ${media('md')} {
      max-width: 50vw;
    }
  }

  .ReactCollapse--collapse {
    transition: height 600ms cubic-bezier(0.46, 0, 0.08, 1);
  }
`;

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path fill="#fff" d="M92.5 114L40.5 114 33.5 41 99.5 41z" />
    <path fill="#fff" d="M92.5 114L40.5 114 33.5 41 99.5 41z" />
    <path
      fill="#c3dbea"
      d="M85.5,41l-5.2,54c-0.5,5.1-4.8,9-10,9H55c-2.9,0-5.3,2.5-5,5.5l0,0c0.2,2.6,2.4,4.5,5,4.5h37.5l7-73H85.5z"
    />
    <path
      fill="#228ec4"
      d="M37.5,57.5c-0.8,0-1.6,0.1-2.4,0.2l3.8,39.8c10.4-0.7,18.6-9.4,18.6-19.9C57.5,66.5,48.5,57.5,37.5,57.5z"
    />
    <path
      fill="#fff0b3"
      d="M26.5 28H106.5V41H26.5zM97.5 13L34.5 20 34.5 28 97.5 28z"
    />
    <path
      fill="#f2b630"
      d="M97.5,28V13l-15,1.7V21c0,3.9,3.1,7,7,7h0v6c0,3.9,3.1,7,7,7h10V28H97.5z"
    />
    <path
      fill="none"
      stroke="#444b54"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="6"
      d="M99.5 41L92.5 114 40.5 114 34.5 51.4M102.5 114L30.5 114M87.5 28L26.5 28 26.5 41 106.5 41 106.5 28 97.5 28M97.5 28L97.5 13 34.5 20"
    />
    <path
      fill="#444b54"
      d="M117.5 111A3 3 0 1 0 117.5 117A3 3 0 1 0 117.5 111Z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M24.7,10.9c-0.5,0-0.7,0.4-0.8,1.1l-0.7,10.9c0,0.1-0.1,0.7-0.1,0.8c0,0.6,0.4,0.9,1.3,0.9h5.3c1.7,0,3,0.5,4,1.4
	c0.9,0.9,1.4,2.3,1.4,4.2v0.7c0,1.9-0.5,3.4-1.4,4.3s-2.3,1.4-4,1.4c-1.5,0-2.8-0.4-3.8-1.2s-1.5-1.8-1.5-3.2c0-0.7-0.4-1.1-1.3-1.1
	c-0.8,0-1.2,0.4-1.2,1.3c0,1.8,0.7,3.4,2.1,4.6c1.4,1.2,3.3,1.9,5.8,1.9c2.4,0,4.3-0.7,5.7-2s2.1-3.3,2.1-6.1v-0.7
	c0-2.7-0.7-4.7-2.1-5.9c-1.4-1.2-3.3-1.9-5.7-1.9h-4.1l0.6-9.1h9.2c0.6,0,0.8-0.4,0.8-1.2s-0.3-1.1-0.8-1.1H24.7L24.7,10.9z"
    />
    <path
      d="M17,11c-0.4,0-0.7,0.2-0.9,0.6c0,0-3.4,4.7-3.5,4.9s-0.1,0.4-0.1,0.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
	s0.5-0.2,0.7-0.5l1.5-2.5v22.5c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3s0.4-0.4,0.4-0.7V12
	c0-0.3-0.1-0.5-0.3-0.7C17.5,11.1,17.2,11,17,11z"
    />
  </Svg>
);

import React from 'react';
import {
  useCraftGlobals
} from '~/utils/hooks/useCraftGlobals';

import BrowserSupport from '~/components/common/BrowserSupport';

export default function CraftGlobalBrowserSupport() {
  const { browserSupport } = useCraftGlobals();

  return (
    <BrowserSupport
      headline={browserSupport?.browserSupportHeadline}
      text={browserSupport?.browserSupportText}
    />
  );
}

import { getBaseTheme } from '~/themes/baseTheme';
import { colors } from './colors';

export const _default = getBaseTheme({ colors });

export const faded = getBaseTheme({
  colors: { ...colors, background: colors.faded },
});

export const transparent = getBaseTheme({
  colors: { ...colors, background: colors.transparent },
});

export const primary = getBaseTheme({
  colors: {
    ...colors,
    text: colors.primaryText,
    headline: colors.primaryText,
    background: colors.primary,
  },
});

export const quaternary = getBaseTheme({
  colors: {
    ...colors,
    text: colors.quaternaryText,
    headline: colors.quaternaryText,
    background: colors.quaternary,
  },
});

export const secondary = getBaseTheme({
  colors: {
    ...colors,
    text: colors.secondaryText,
    headline: colors.secondaryText,
    background: colors.secondary,
  },
});

export const tertiary = getBaseTheme({
  colors: {
    ...colors,
    text: colors.tertiaryText,
    headline: colors.tertiaryText,
    background: colors.tertiary,
  },
});

export const tertiaryBright = getBaseTheme({
  colors: {
    ...colors,
    text: colors.tertiaryBrightText,
    headline: colors.tertiaryBrightText,
    background: colors.tertiaryBright,
  },
});

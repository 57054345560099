import React, { useContext, useState, useEffect } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { Location } from '@reach/router';

import { useSwitch } from '~/utils/hooks/useSwitch';
import {
  NavBar,
  NavItemWrapper,
  NavItem,
  NavButtonWrapper,
  NavButton,
  DropdownSlot,
} from './Navigation.styles';
import { HeaderIsPinnedContext } from '../Header';
import useTimeout from '~/utils/hooks/useTimeout';
import NavigationDropdown from '../NavigationDropdown';
import NavigationDropdownContainer from '../NavigationDropdownContainer';
import { PageContext } from '../Layout';

type NavItemBase = {
  id?: number;
  link?: {
    url?: string;
    text?: string;
    target?: string;
  };
  appearance?: 'default' | 'highlight';
};

type NavItemSub = NavItemBase & {
  description?: string;
};

type NavItem = NavItemBase & {
  children?: NavItemSub[];
};

type NavigationProps = {
  navItems: NavItem[];
  duration?: number;
} & typeof defaultProps;

const defaultProps = {
  duration: 300,
};

export default function Navigation({ navItems, duration }: NavigationProps) {
  const [
    buttonIsHovered,
    handleMouseEnterButton,
    handleMouseLeaveButton,
  ] = useSwitch(false);

  const headerIsPinned = useContext(HeaderIsPinnedContext);

  const { homeUri } = useContext(PageContext);

  const [activeIndices, setActiveIndices] = useState<number[]>([]);
  const [animatingOut, setAnimatingOut] = useState(false);

  const resetDropdownStateAfterAnimation = useTimeout(() => {
    setActiveIndices([]);
    setAnimatingOut(false);
  }, 300);

  const triggerDropdownForIndex = (i: number) => {
    if (resetDropdownStateAfterAnimation.stop()) {
      setActiveIndices([i]);
      setAnimatingOut(false);
      return;
    }

    if (activeIndices[activeIndices.length - 1] === i) return;

    setActiveIndices(activeIndices.concat(i));
    setAnimatingOut(false);
  };

  const closeAllDropdowns = () => {
    setAnimatingOut(true);
    resetDropdownStateAfterAnimation.start();
  };

  let currentDropdownItems: NavItemSub[] | undefined;
  let prevDropdownItems: NavItemSub[] | undefined;
  let direction: 'left' | 'right';

  const currentIndex = activeIndices[activeIndices.length - 1];
  const prevIndex =
    activeIndices.length > 1 && activeIndices[activeIndices.length - 2];

  if (typeof currentIndex === 'number') {
    currentDropdownItems = navItems[currentIndex].children;
  }
  if (typeof prevIndex === 'number') {
    prevDropdownItems = navItems[prevIndex].children;
    direction = currentIndex > prevIndex ? 'right' : 'left';
  }

  return (
    <Location>
      {({ location }) => (
        <Flipper flipKey={currentIndex} spring="noWobble">
          <NavBar
            as="nav"
            flexWrap="wrap"
            disableDimming={buttonIsHovered}
            onMouseLeave={closeAllDropdowns}
          >
            {navItems.map(({ id, link, appearance, children }, index) => {
              if (!link) return;
              if (appearance === 'highlight') {
                // Hide highlighted menu item when the page is the same. See ZMAR-194
                if (
                  location.pathname !== homeUri &&
                  link.url &&
                  link.url.indexOf(location.pathname) >= 0
                ) {
                  return null;
                }

                return (
                  <NavButtonWrapper
                    key={`nav-main-${id}`}
                    onMouseEnter={() => {
                      handleMouseEnterButton();
                      closeAllDropdowns();
                    }}
                    onMouseLeave={handleMouseLeaveButton}
                  >
                    <NavButton
                      to={link.url}
                      target={link.target}
                      variant={headerIsPinned ? 'primary' : 'filled'}
                    >
                      {link.text}
                    </NavButton>
                  </NavButtonWrapper>
                );
              }

              return (
                <NavItemWrapper
                  key={`nav-main-${id}`}
                  onMouseEnter={() => {
                    if (children && children.length >= 1) {
                      triggerDropdownForIndex(index);
                    } else {
                      closeAllDropdowns();
                    }
                  }}
                >
                  <NavItem to={link.url} target={link.target} data-navitem>
                    {link.text}
                  </NavItem>
                  {children && children.length >= 1 ? (
                    <DropdownSlot>
                      {currentIndex === index && (
                        <NavigationDropdownContainer
                          direction={direction}
                          animatingOut={animatingOut}
                          duration={duration}
                        >
                          {currentDropdownItems ? (
                            <NavigationDropdown
                              onLinkClick={closeAllDropdowns}
                              navItems={currentDropdownItems}
                            />
                          ) : null}
                          {prevDropdownItems ? (
                            <NavigationDropdown navItems={prevDropdownItems} onLinkClick={closeAllDropdowns}/>
                          ) : null}
                        </NavigationDropdownContainer>
                      )}
                    </DropdownSlot>
                  ) : null}
                </NavItemWrapper>
              );
            })}
          </NavBar>
        </Flipper>
      )}
    </Location>
  );
}

Navigation.defaultProps = defaultProps;

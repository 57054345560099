import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#444b54"
      d="M101.6 34.2c-.8 0-1.5-.3-2.1-.9-6.5-6.5-14.6-11-23.4-13.2-1.6-.4-2.6-2-2.2-3.6.4-1.6 2-2.6 3.6-2.2 9.9 2.4 19 7.6 26.2 14.8 1.2 1.2 1.2 3.1 0 4.2C103.1 33.9 102.4 34.2 101.6 34.2zM26.4 34.2c-.8 0-1.5-.3-2.1-.9-1.2-1.2-1.2-3.1 0-4.2 7.2-7.2 16.3-12.3 26.2-14.8 1.6-.4 3.2.6 3.6 2.2s-.6 3.2-2.2 3.6c-8.8 2.2-16.9 6.7-23.4 13.2C28 33.9 27.2 34.2 26.4 34.2z"
    />
    <path
      fill="#fff"
      d="M64 30.599999999999994A38.2 38.2 0 1 0 64 107A38.2 38.2 0 1 0 64 30.599999999999994Z"
    />
    <path
      fill="#adf9d2"
      d="M64,103.9c-1.7,0-3-1.3-3-3s1.3-3,3-3c16.1,0,29.2-13.1,29.2-29.2c0-1.7,1.3-3,3-3s3,1.3,3,3C99.2,88.1,83.4,103.9,64,103.9z"
    />
    <path
      fill="#444b54"
      d="M95.2,95.6c6.4-7.5,10-16.9,10-26.8c0-11-4.3-21.3-12.1-29.1c-16-16-42.2-16-58.2,0c-15.3,15.3-16,39.9-2,56l-8,8.2c-1.2,1.2-1.1,3.1,0.1,4.2c0.6,0.6,1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9l8-8.2c7.5,6.5,16.9,10,26.9,10c10,0,19.5-3.6,27-10.1l9,8.3c0.6,0.5,1.3,0.8,2,0.8c0.8,0,1.6-0.3,2.2-1c1.1-1.2,1.1-3.1-0.2-4.2L95.2,95.6z M39.2,93.6c-13.7-13.7-13.7-36,0-49.7C46,37,55,33.6,64,33.6c9,0,18,3.4,24.9,10.3c6.6,6.6,10.3,15.5,10.3,24.9c0,9.4-3.7,18.2-10.3,24.9c0,0,0,0,0,0c-6.6,6.6-15.5,10.3-24.9,10.3C54.6,103.9,45.8,100.3,39.2,93.6z"
    />
    <path
      fill="#444b54"
      d="M78.6,71.8H64c-1.7,0-3-1.3-3-3V50.9c0-1.7,1.3-3,3-3s3,1.3,3,3v14.9h11.6c1.7,0,3,1.3,3,3S80.2,71.8,78.6,71.8z"
    />
  </Svg>
);

import ls from 'localstorage-ttl';

export function loadWeglotCode() {
  if (document) {
    // Execute weglot code
    const weglotCode = document.createElement('script');
    weglotCode.id = 'weglot-code'
    weglotCode.innerHTML = `Weglot.initialize({
      api_key: 'wg_3f843f5f9e3b6aeab970cc65fac60db37'
    });`

    document
      .getElementsByTagName('head')[0]
      .appendChild(weglotCode, document.getElementsByTagName('head')[0]);
  }
}

export function renderWeglot() {
  if (document) {
    // Load script.
    const weglotScript = document.createElement('script');
    weglotScript.id = 'weglot-script'
    weglotScript.src = 'https://cdn.weglot.com/weglot.min.js';

    weglotScript.addEventListener('load', loadWeglotCode);

    document
      .getElementsByTagName('head')[0]
      .appendChild(weglotScript, document.getElementsByTagName('head')[0]);
  }
}

/**
 * Disable tracking.
 * Set typical google disable cookie.
 */

export function disableGoogleAnalytics() {
  if (window && document) {
    const disableStr = `ga-disable-${ls.get('gaId')}`;
    window[disableStr] = true;
    document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
  }
}

/**
 * Send page view.
 * Used in gatsby-browser.js onRouteUpdate
 *
 * @param {*} location
 */
export function sendPageView(location) {
  if (!window || typeof window.gtag !== 'function') return null;

  window.gtag('config', ls.get('gaId'), {
    page_path: `${location.pathname}${location.search}${location.hash}`,
    anonymize_ip: true,
    custom_map: {
      dimension1: 'netlify',
      dimension2: 'context',
      dimension3: 'branch',
    },
  });
}

/**
 * Send conversion
 * for contract conclusion button.
 */
export function reportConversionContractConclusionButton(url, value) {
  if (!window || typeof window.gtag !== 'function') return null;

  const callback = function() {
    if (typeof url !== 'undefined') {
      window.location = url;
    }
  };

  window.gtag('event', 'contract', {
    event_category: 'calculator',
    event_label: 'Contract conclusion',
    value,
  });

  window.gtag('event', 'conversion', {
    send_to: 'AW-988921133/pejzCJWL37EBEK36xtcD',
    transaction_id: '',
    event_callback: callback,
  });


  return false;
}

export function enableEtrackerCookies(tries = 0) {
  // Custom etracker foo, was no nicer way currently (ZMAR-553)
  const maxTries = 10;
  let currentTries = tries;
  if (window && window._etracker) {
    window._etracker.enableCookies(window.location.origin);
  } else if(currentTries <= maxTries) {
    setTimeout(() => {
      currentTries += 1;
      enableEtrackerCookies(currentTries);
    }, 500)
  } else {
    console.warn(`Etracker could not be enabled as it was not ready after ${maxTries} tries`)
  }
}

// Enables Google Analytics
export function enableGoogleAnalytics(googleAnalyticsId) {
  ls.set('gaId', googleAnalyticsId);
  // Also enable etracker cookies when enabling google analytics (ZMAR-553)
  enableEtrackerCookies()
  if (window && document && !document.getElementById('google-analytics-script')) {
    // eslint-disable-next-line no-inner-declarations
    function gtag() {
      // eslint-disable-next-line no-undef, prefer-rest-params
      dataLayer.push(arguments);
    }

    window.dataLayer = window.dataLayer || [];
    window.gtag = gtag;

    gtag('js', new Date());
    gtag('config', googleAnalyticsId, {
      anonymize_ip: true,
      custom_map: {
        dimension1: 'netlify',
        dimension2: 'context',
        dimension3: 'branch',
      },
    });

    gtag('event', 'app_environment', {
      netlify: APP_NETLIFY,
      context: APP_CONTEXT,
      branch: APP_BRANCH,
    });

    // Load script.
    const gascript = document.createElement('script');
    gascript.id = 'google-analytics-script'
    gascript.async = true;
    gascript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;

    document
      .getElementsByTagName('head')[0]
      .appendChild(gascript, document.getElementsByTagName('head')[0]);
  }
}

// Enables Google Optimize
export function enableGoogleOptimize() {
  if (window && document && !document.getElementById('google-optimize-script')) {
    const googleOptimizeScriptTag = document.createElement('script');
    googleOptimizeScriptTag.id = 'google-optimize-script';
    googleOptimizeScriptTag.async = true;
    googleOptimizeScriptTag.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-MFKZ6ZV';

    document
      .getElementsByTagName('head')[0]
      .appendChild(googleOptimizeScriptTag, document.getElementsByTagName('head')[0]);
  }
}

// Enables Hotjar User Statistics
export function enableHotjar() {
  if (window && document && !document.getElementById('hotjar-script')) {
    const hotjarTag = document.createElement('script');
    hotjarTag.id = 'hotjar-script';
    // eslint-disable-next-line
    hotjarTag.innerHTML = "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2555809,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"

    document
      .getElementsByTagName('head')[0]
      .appendChild(hotjarTag, document.getElementsByTagName('head')[0]);
  }
}

/**
 * Enable tracking.
 * Loads google analytics.
 *
 * Used in gatsby-browser.js onInitialClientRender
 */
 export function enableStatisticsTracking(googleAnalyticsId) {
  enableGoogleAnalytics(googleAnalyticsId);
  enableGoogleOptimize();
  enableHotjar();
}

export function disableStatisticsTracking() {
  disableGoogleAnalytics();
  if (document) {
    // Remove custom script tags
    const hj = document.getElementById('hotjar-script')
    const opt = document.getElementById('google-optimize-script')
    const ga = document.getElementById('google-analytics-script')

    ga?.remove();
    opt?.remove();
    hj?.remove();

    // Remove automatically injected Tags for analytics/hotjar
    const blacklist = [
      'script.hotjar.com',
      'static.hotjar.com',
      'google-analytics.com',
    ];
    Array.from(document.getElementsByTagName('script'))
    .forEach((tag)=> {
      const src = tag?.src
      blacklist.some(element => src?.includes(element))
      tag?.remove();
    })
  }
}
import { useRef, useMemo, useEffect } from 'react';

export default function useTimeout(callback: () => void, timeout = 0) {
  const timeoutId = useRef<number>();
  const handler = useMemo(() => {
    return {
      start(overrideTimeout?: number) {
        handler.stop();
        timeoutId.current = setTimeout(
          () => {
            timeoutId.current = undefined;
            callback();
          },
          overrideTimeout === undefined ? timeout : overrideTimeout,
        );
      },

      stop() {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
          return true;
        }
        return false;
      },

      restart() {
        handler.stop();
        handler.start();
      },
    };
  }, [callback, timeout]);

  useEffect(() => {
    return () => {
      handler.stop();
    };
  }, []);

  return handler;
}

import React from 'react';
import { TrustBadgeStyled } from './TrustBadge.styles';
import { useExternalScriptEffect } from '~/utils/hooks/useExternalScriptEffect';

/**
 * Component: TrustBadge
 */
export default function TrustBadge({ trustedShopsId }) {
  if (!trustedShopsId || typeof window === 'undefined') {
    if (typeof window !== 'undefined') {
      console.warn('Cannot display trusted shops badge.');
    }
    return null;
  }

  // Load trustedshops script with config set in gatsby-browser.js
  useExternalScriptEffect(
    `//widgets.trustedshops.com/js/${trustedShopsId}.js`,
    () => {
      window?.trustbadge?.remove();
      // trustbagde fails to remove itself from the dom…
      document
        .querySelectorAll('.etrusted-badge-container')
        .forEach(el => el.remove());
      window?.trustbadge?.reInitialize();
    },
    [],
  );

  return <TrustBadgeStyled id="trusted-badge" />;
}

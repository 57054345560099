import styled from '~/utils/styled';
import { Button as BaseButton } from 'rebass';
import media from '~/utils/mediaqueries';
import BaseIcon from '~/components/common/Icon';
import { isBrowserIE11 } from '~/utils/styles';
import { TextTransformProperty } from 'csstype';

interface ButtonProps {
  as?: React.ElementType;
  to?: string;
  variant?: string;
  hasChildren?: boolean;
  disabled?: boolean;
  hoverStyle?: 'moveRight' | 'moveLeft' | 'zoom' | 'none';
  mobileWidth?: string | number;
  width?: string | number;
  paddingSize?: 'normal' | 'big' | 'small';
  textTransform?: TextTransformProperty;
  bg?: string;
}

export const ButtonStyled = styled(BaseButton)<ButtonProps>(
  ({ theme, mobileWidth, width, textTransform, paddingSize }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
    maxWidth: '100%',
    width: width || mobileWidth,
    textDecoration: 'none',
    cursor: 'pointer',
    borderWidth: 1,
    fontSize: theme.fontSizes[1],
    padding:
      paddingSize === 'small'
        ? '0.5rem 1.25rem'
        : paddingSize === 'big'
        ? '2rem 3rem'
        : '1rem 2rem',
    borderStyle: 'solid',
    textTransform,
    transition:
      'opacity .3s, color .3s ease-in-out, background-color .3s ease-in-out, border .3s ease-in-out',

    [media('lg')]: {
      width: width || 'auto',
    },

    '&:focus': {
      outline: 0,
      WebkitTapHighlightColor: 'transparent',
    },

    '&:hover [data-icon]': {
      transform: 'translate(5px, 0)',
    },
  }),
  props =>
    props.bg && {
      background: props.bg,
    },
  props =>
    props.variant === 'blank' && {
      minWidth: isBrowserIE11() ? 200 : 'auto', // IE cannot live without a max width.
      width: 'max-content',
      padding: '.5rem 1rem',
    },
  props =>
    props.variant === 'blue' && {
      borderColor: props.theme.colors.secondaryBright,
      color: props.theme.colors.secondaryBright,
      fontWeight: 'bold',

      '&:hover': {
        color: props.theme.colors.secondaryBright,
      },
    },
  props =>
    props.hasChildren === false && {
      minWidth: 'auto',
      padding: '1rem',
    },
  props =>
    props.disabled === true && {
      pointerEvents: 'none',
      opacity: 0.5,
    },
  props =>
    props.hoverStyle === 'moveLeft' && {
      '&:hover [data-icon]': {
        transform: 'translate(-5px, 0)',
      },
    },
  props =>
    props.hoverStyle === 'zoom' && {
      '&:hover [data-icon]': {
        transform: 'scale(1.3)',
      },
    },
  props =>
    props.hoverStyle === 'none' && {
      '&:hover [data-icon]': {
        transform: 'scale(1)',
      },
    },
);

ButtonStyled.defaultProps = {
  as: 'button',
};

type IconTextProps = { theme?: object };

export const Text = styled('span')<IconTextProps>(({ theme }) => ({
  flex: 'none',
  flexGrow: 1,
  transition: 'width .3s ease-in-out',
}));

type IconWrapperProps = {
  'data-icon'?: boolean;
  iconPosition: 'before' | 'after' | '';
};

const IconWrapperDefaultProps = {
  'data-icon': true,
};

export const IconWrapper = styled('span')<IconWrapperProps>(
  ({ iconPosition }) => ({
    flex: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: iconPosition === 'after' ? 11 : undefined,
    marginRight: iconPosition === 'before' ? 11 : undefined,
    transform: 'translate(0, 0)',
    transition: 'transform .3s ease-in-out',
    backfaceVisibility: 'hidden',
  }),
);

IconWrapper.defaultProps = IconWrapperDefaultProps;

type IconProps = {
  iconSize: 'default' | 'small';
};

export const Icon = styled(BaseIcon)<IconProps>(({ iconSize }) => ({
  fontSize: iconSize === 'small' ? '1em' : '1.6em',
}));

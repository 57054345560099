import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M27.7,11c-0.5,0-0.9,0.3-1.2,0.8l-9.4,18.9C17,30.9,17,31.1,17,31.3c0,0.7,0.3,1,1,1h9.5V38c0,0.3,0.1,0.6,0.4,0.7
	s0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.2-0.2,0.4-0.4,0.4-0.7v-5.7h2.1c0.3,0,0.5-0.1,0.7-0.4c0.2-0.2,0.3-0.5,0.3-0.8
	c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.4-0.7-0.4H30v-7.5c0-0.3-0.1-0.5-0.4-0.7c-0.2-0.2-0.5-0.3-0.9-0.3c-0.8,0-1.2,0.3-1.2,1V30
	h-7.4l8.7-17.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.5-0.4-0.7C28.3,11.1,28,11,27.7,11z"
    />
  </Svg>
);

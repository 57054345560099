import React from 'react';
import { Svg } from '../Logo.styles';

export default () => (
  <Svg viewBox="0 0 145.81 44.67">
    <path
      d="M0,25.6V21.42l9.35-11H.5V5.91H15.7v4.18l-9.35,11h9.72V25.6Z"
      fill="#203768"
    />
    <path
      d="M20.73,5.91H26V8.09a6.82,6.82,0,0,1,5.54-2.6A7.23,7.23,0,0,1,35,6.4a7,7,0,0,1,2.68,2.5,7.91,7.91,0,0,1,2.8-2.5,7.55,7.55,0,0,1,3.69-.93A7.31,7.31,0,0,1,49.7,7.69a8,8,0,0,1,2.14,5.74V25.6H46.62V14a4,4,0,0,0-1-2.76,3.07,3.07,0,0,0-2.39-1.11c-1.75,0-3.2.94-4.37,2.83V25.6H33.69V14a4,4,0,0,0-1-2.78,3.12,3.12,0,0,0-2.37-1.09q-2.59,0-4.34,2.78V25.6H20.73Z"
      fill="#203768"
    />
    <path
      d="M65.42,5.47A8.58,8.58,0,0,1,71.66,7.9v-2h5.08V25.6H71.66v-2A8.59,8.59,0,0,1,65.42,26a9.2,9.2,0,0,1-5-1.38,9.76,9.76,0,0,1-3.51-3.76,10.71,10.71,0,0,1-1.26-5.14,10.94,10.94,0,0,1,1.26-5.17,9.59,9.59,0,0,1,3.51-3.75A9.37,9.37,0,0,1,65.42,5.47Zm1.1,16a5.77,5.77,0,0,0,3.08-.85,5.43,5.43,0,0,0,2.06-2.29V13.21a5.4,5.4,0,0,0-2.06-2.28,5.74,5.74,0,0,0-8.76,4.64v.21a5.57,5.57,0,0,0,5.46,5.68h.22Z"
      fill="#203768"
    />
    <path
      d="M91.85,10.07a4.07,4.07,0,0,0-2.39.85,6.55,6.55,0,0,0-2,2.34V25.6H82.24V5.91h5.23V8.33a6.37,6.37,0,0,1,5.26-2.86,3.89,3.89,0,0,1,1.8.41l-.38,4.58A7,7,0,0,0,91.85,10.07Z"
      fill="#203768"
    />
    <path
      d="M104.87,18.74a2.39,2.39,0,0,0,2,2.72,2.66,2.66,0,0,0,.69,0,19.51,19.51,0,0,0,2.16-.09v4.41a13.18,13.18,0,0,1-2.73.28q-7.36,0-7.36-6.93V10.52h-3V5.91h3V.68l5.23-.46V5.91h4.62v4.61h-4.62Z"
      fill="#203768"
    />
    <circle
      cx="114.68"
      cy="25.49"
      r="2.13"
      fill="none"
      stroke="#203768"
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M114,26.42V24.55h.83a1.2,1.2,0,0,1,.38.05.52.52,0,0,1,.21.18.55.55,0,0,1,.07.28.47.47,0,0,1-.13.34.61.61,0,0,1-.39.17l.15.09a1.22,1.22,0,0,1,.2.25l.33.51h-.31l-.25-.39a2.4,2.4,0,0,0-.18-.26.49.49,0,0,0-.13-.12h-.53v.83Zm.25-1h.53a.57.57,0,0,0,.26,0,.28.28,0,0,0,.15-.11.49.49,0,0,0,0-.17.27.27,0,0,0-.1-.22.46.46,0,0,0-.3-.08h-.59Z"
      fill="#203768"
    />
    <polygon
      points="127.36 9.13 132.92 5.91 133.19 3.27 130.63 2.13 122.88 6.57 122.88 6.57 122.88 6.57 122.88 6.64 122.87 11.69 122.88 11.69 122.88 19.71 125.19 21.04 124.91 18.28 127.38 17.17 127.38 9.14 127.36 9.13"
      fill="#aed6b1"
    />
    <polygon
      points="134.57 2.49 132.03 1.34 130.65 2.12 133.19 3.27 132.92 5.91 134.28 5.17 134.57 2.49"
      fill="#fff"
    />
    <path
      d="M134.34,21.18,128.74,18l-2.45,1.12.16,2.69,7.89,4.51h0l11.47-6.58V17.09l-2.26,1.62-2.15-1.59Z"
      fill="#007257"
    />
    <path
      d="M124.91,18.28l.28,2.76,1.26.72-.16-2.69L128.74,18l-1.36-.78Z"
      fill="#fff"
    />
    <path
      d="M145.81,6.57,134.34,0,132,1.34l2.54,1.15-.28,2.67,7,4.06h0l.08,6.39,2.17,1.53,2.26-1.63V6.56Z"
      fill="#5da793"
    />
    <path
      d="M143.55,18.71l2.26-1.62V15.51l-2.26,1.62-2.17-1.53v1.52C142.13,17.64,142.81,18.18,143.55,18.71Z"
      fill="#fff"
    />
    <path
      d="M4.32,40.77l2.81-7.39h.92L4.78,41.84H3.85L.58,33.38H1.5Z"
      fill="#203767"
    />
    <path
      d="M11.62,41.27a2.74,2.74,0,0,0,2.08-1l.57.52A3.38,3.38,0,0,1,11.64,42a3.28,3.28,0,0,1-.44-6.55h.35a3.06,3.06,0,0,1,3.13,3v.13a2.67,2.67,0,0,1,0,.4H9.14A2.46,2.46,0,0,0,11.62,41.27Zm-2.45-3h4.65a2.27,2.27,0,0,0-2.27-2.12,2.46,2.46,0,0,0-2.38,2.11Z"
      fill="#203767"
    />
    <path
      d="M16.89,37.81v4h-.82V35.58h.82v1.25a2.68,2.68,0,0,1,2.05-1.4,1.58,1.58,0,0,1,.61.12l-.08.74a1.68,1.68,0,0,0-.63-.12C18.08,36.17,17.35,36.92,16.89,37.81Z"
      fill="#203767"
    />
    <path
      d="M27.1,38.71A3.22,3.22,0,0,1,24,42H23.9a3,3,0,0,1-2.33-1.09v3.75h-.82V35.58h.82v.94a3,3,0,0,1,2.33-1.09,3.21,3.21,0,0,1,3.2,3.22Zm-.83,0a2.51,2.51,0,0,0-2.48-2.54h0a2.47,2.47,0,0,0-2.21,1.37v2.34a2.46,2.46,0,0,0,2.21,1.36,2.51,2.51,0,0,0,2.49-2.53Z"
      fill="#203767"
    />
    <path
      d="M33.57,36.52v-.94h.83v6.26h-.83v-1A3,3,0,0,1,31.24,42a3.28,3.28,0,0,1,0-6.55A3,3,0,0,1,33.57,36.52Zm0,3.36V37.54a2.47,2.47,0,0,0-2.21-1.37,2.54,2.54,0,0,0,0,5.07A2.46,2.46,0,0,0,33.57,39.88Z"
      fill="#203767"
    />
    <path
      d="M35.78,38.71A3.26,3.26,0,0,1,39,35.43h.06a3.36,3.36,0,0,1,2.26.85l-.43.66a2.62,2.62,0,0,0-1.83-.77,2.54,2.54,0,0,0,0,5.07,2.59,2.59,0,0,0,1.9-.83l.49.61a3.33,3.33,0,0,1-2.38,1,3.26,3.26,0,0,1-3.33-3.19Z"
      fill="#203767"
    />
    <path
      d="M44.6,38.71h-1v3.13h-.82V33h.82v5h1l2-2.42h1l-2.3,2.78L48,41.84H47Z"
      fill="#203767"
    />
    <path
      d="M53.47,35.58h.82v6.26h-.82V40.65A2.7,2.7,0,0,1,51.28,42a2.41,2.41,0,0,1-2.49-2.33V35.6h.82v3.85a1.75,1.75,0,0,0,1.69,1.81h.12a2.53,2.53,0,0,0,2-1.51Z"
      fill="#203767"
    />
    <path
      d="M56.94,41.84h-.82V35.58h.82v1.19a2.69,2.69,0,0,1,2.2-1.34,2.41,2.41,0,0,1,2.48,2.34v4.07H60.8V38a1.75,1.75,0,0,0-1.67-1.83H59a2.53,2.53,0,0,0-2,1.52Z"
      fill="#203767"
    />
    <path
      d="M68.43,40.89A3,3,0,0,1,66.09,42a3.28,3.28,0,0,1,0-6.55,3,3,0,0,1,2.34,1.09V35.6h.82v6.19a2.75,2.75,0,0,1-2.62,2.88h-.38a3.59,3.59,0,0,1-2.76-1.24l.54-.54A3,3,0,0,0,66.25,44a2,2,0,0,0,2.2-1.77,2,2,0,0,0,0-.34Zm0-1V37.54a2.47,2.47,0,0,0-2.21-1.37,2.54,2.54,0,0,0,0,5.07,2.49,2.49,0,0,0,2.21-1.36Z"
      fill="#203767"
    />
    <path
      d="M71.09,40.37a2.38,2.38,0,0,0,1.87.9c.91,0,1.48-.45,1.48-1.1s-.53-1-1.58-1.24c-1.36-.26-2-.89-2-1.8A1.89,1.89,0,0,1,73,35.43a2.93,2.93,0,0,1,2,.82l-.44.57A2.26,2.26,0,0,0,73,36.14c-.76,0-1.34.43-1.34,1s.5.94,1.48,1.13c1.39.27,2.14.9,2.14,1.91S74.43,42,73,42a3,3,0,0,1-2.38-1Z"
      fill="#203767"
    />
    <path d="M76.88,33h.82v8.83h-.82Z" fill="#203767" />
    <path
      d="M80.41,33.2a.58.58,0,0,1,.58.56v0a.58.58,0,0,1-.57.59h0a.58.58,0,0,1-.59-.57v0a.58.58,0,0,1,.58-.58ZM80,35.58h.82v6.26H80Z"
      fill="#203767"
    />
    <path
      d="M82.58,41.11l3.69-4.81H82.73v-.72h4.53v.72l-3.69,4.81h3.81v.73h-4.8Z"
      fill="#203767"
    />
    <path
      d="M91.74,41.27a2.78,2.78,0,0,0,2.09-1l.57.52A3.39,3.39,0,0,1,91.76,42a3.28,3.28,0,1,1-.43-6.55h.35a3.06,3.06,0,0,1,3.13,3v.13a1.34,1.34,0,0,1,0,.4H89.27A2.44,2.44,0,0,0,91.74,41.27Zm-2.44-3H94a2.27,2.27,0,0,0-2.27-2.12,2.46,2.46,0,0,0-2.43,2.11Z"
      fill="#203767"
    />
    <path
      d="M97,41.84h-.82V35.58H97v1.19a2.69,2.69,0,0,1,2.2-1.34,2.41,2.41,0,0,1,2.48,2.34v4.07h-.82V38a1.75,1.75,0,0,0-1.69-1.81h-.12a2.55,2.55,0,0,0-2,1.52Z"
      fill="#203767"
    />
    <path
      d="M103.1,41.11l3.69-4.81h-3.53v-.72h4.53v.72l-3.69,4.81h3.81v.73H103.1Z"
      fill="#203767"
    />
    <path
      d="M110.1,33.2a.58.58,0,0,1,.58.56v0a.59.59,0,0,1-.51.66.59.59,0,0,1-.66-.51.38.38,0,0,1,0-.15.57.57,0,0,1,.56-.58Zm-.41,2.38h.82v6.26h-.82Z"
      fill="#203767"
    />
    <path
      d="M115.44,41.27a2.78,2.78,0,0,0,2.09-1l.56.52A3.38,3.38,0,0,1,115.46,42a3.28,3.28,0,1,1-.43-6.55h.35a3.06,3.06,0,0,1,3.13,3v.13a1.83,1.83,0,0,1-.05.4H113A2.44,2.44,0,0,0,115.44,41.27Zm-2.44-3h4.65a2.27,2.27,0,0,0-2.27-2.12A2.46,2.46,0,0,0,113,38.26Z"
      fill="#203767"
    />
    <path
      d="M120.71,37.81v4h-.82V35.58h.82v1.25a2.71,2.71,0,0,1,2.06-1.4,1.53,1.53,0,0,1,.6.12l-.07.74a1.73,1.73,0,0,0-.64-.12C121.9,36.17,121.17,36.92,120.71,37.81Z"
      fill="#203767"
    />
    <path
      d="M129.13,35.58H130v6.26h-.82V40.65A2.67,2.67,0,0,1,126.94,42a2.4,2.4,0,0,1-2.48-2.32V35.6h.82v3.85A1.75,1.75,0,0,0,127,41.26h.11a2.51,2.51,0,0,0,2-1.51Z"
      fill="#203767"
    />
    <path
      d="M132.61,41.84h-.82V35.58h.82v1.19a2.66,2.66,0,0,1,2.19-1.34,2.4,2.4,0,0,1,2.48,2.32v4.09h-.82V38a1.75,1.75,0,0,0-1.69-1.81h-.11a2.51,2.51,0,0,0-2,1.52Z"
      fill="#203767"
    />
    <path
      d="M144.09,40.89A3,3,0,0,1,141.76,42a3.28,3.28,0,0,1,0-6.55,3,3,0,0,1,2.33,1.09V35.6h.82v6.19a2.75,2.75,0,0,1-2.62,2.88h-.38a3.59,3.59,0,0,1-2.76-1.24l.55-.54A2.88,2.88,0,0,0,141.91,44a2,2,0,0,0,2.2-1.77,2,2,0,0,0,0-.34Zm0-1V37.54a2.47,2.47,0,0,0-2.21-1.37,2.54,2.54,0,0,0,0,5.07,2.46,2.46,0,0,0,2.21-1.36Z"
      fill="#203767"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 533.84 202.16">
    <path
      fill="#005da0"
      d="M278.38,117.24a52.58,52.58,0,0,1-24.85-6l2.35-15.53a46.83,46.83,0,0,0,22.29,5.89c8.78,0,12.64-3.21,12.64-8.78,0-12.64-37-7.29-37-34.18C253.85,45,262.63,34,283,34a62.38,62.38,0,0,1,22.5,4.18l-2.25,15.21A64.55,64.55,0,0,0,283.85,50c-10,0-12.65,3.43-12.65,7.72,0,12.53,37,6.75,37,34C308.17,107.49,297.13,117.24,278.38,117.24Z"
    />
    <path
      fill="#005da0"
      d="M377.82,100v15.74H323.18V35.39h54.64v16H341.29v16H371V81.67H341.29V100Z"
    />
    <path
      fill="#005da0"
      d="M422.62,89.6H411.91v26.14H393.8V35.39h28.82c22.28,0,31.39,8.79,31.39,27.54C454,79.85,445,89.6,422.62,89.6Zm0-39.1H411.91V75.14h10.71c8.89,0,12.75-2.25,12.75-12.32C435.37,53.18,431.94,50.5,422.62,50.5Z"
    />
    <path
      fill="#005da0"
      d="M509.2,100.1H480.06l-4.5,15.64H456.5l27.74-80.35h21.54l28.06,80.35H513.7ZM492.49,57.46l-8,27.54h20.46l-7.71-27.54L495.06,49h-.32Z"
    />
    <path
      fill="#005da0"
      d="M277.74,167.89l-.2,7.56H253.91V138.14h8.45v29.75Z"
    />
    <path
      fill="#005da0"
      d="M300.32,172.56l-.14-.05A10.4,10.4,0,0,1,292,176c-5.72,0-9.36-3.24-9.36-10,0-5.58,3.89-9.4,10.75-9.4a19.59,19.59,0,0,1,5.77.84v-1.74c0-3-1.59-4.43-5.72-4.43a19.62,19.62,0,0,0-8.31,1.65l-.65-6.17a26,26,0,0,1,10.35-2c8.91,0,12,3.83,12,12.08v18.66h-5.92Zm-1.14-10a12.22,12.22,0,0,0-4.28-.65c-3.43,0-5,1.34-5,4,0,2.94,1.74,4,4.28,4s5-1.59,5-3.54Z"
    />
    <path
      fill="#005da0"
      d="M323.51,176.05a19.84,19.84,0,0,1-9.45-2.09l.8-6.37a18.71,18.71,0,0,0,8.6,2.14c3.09,0,4.38-1.24,4.38-3,0-4.82-14-2-14-12.58,0-5.42,3.68-9.31,11.45-9.31a22.69,22.69,0,0,1,8.55,1.65l-.64,6.31a23.23,23.23,0,0,0-8.07-1.54c-2.53,0-3.93,1-3.93,2.54,0,4.58,13.79,1.84,13.79,12.24C335,172,331.67,176.05,323.51,176.05Z"
    />
    <path
      fill="#005da0"
      d="M350.18,151.07V166.5c0,2.33,1.2,3.33,3.58,3.33a8.31,8.31,0,0,0,2.39-.35l.45,5.62a14.62,14.62,0,0,1-5.18.85c-6,0-8.95-3.48-8.95-9.21V151.07h-3.38V145.3h3.73l1.29-6.81,6.07-.55v7.36h6.72v5.77Z"
    />
    <path
      fill="#005da0"
      d="M371.82,176.05a19.84,19.84,0,0,1-9.45-2.09l.8-6.37a18.71,18.71,0,0,0,8.6,2.14c3.09,0,4.38-1.24,4.38-3,0-4.82-14-2-14-12.58,0-5.42,3.68-9.31,11.45-9.31a22.65,22.65,0,0,1,8.55,1.65l-.64,6.31a23.18,23.18,0,0,0-8.06-1.54c-2.54,0-3.94,1-3.94,2.54,0,4.58,13.79,1.84,13.79,12.24C383.32,172,380,176.05,371.82,176.05Z"
    />
    <path
      fill="#005da0"
      d="M409.49,152.67a15.77,15.77,0,0,0-5.13-.95c-5.62,0-7.46,2.34-7.46,8.71s2.64,9,7.32,9a12.9,12.9,0,0,0,5.47-1.15l.54,6a15,15,0,0,1-7.7,1.75c-8.91,0-13.64-5.08-13.64-15.62,0-10,4.13-15.63,13.78-15.63a21.47,21.47,0,0,1,7.27,1.3Z"
    />
    <path
      fill="#005da0"
      d="M434.91,175.45v-19.2c0-3.09-1.54-4.68-4.82-4.68a6.45,6.45,0,0,0-5.13,2.29v21.59h-7.71v-38.7l7.71-.4v6.86l-.09,4,.14.1a11.52,11.52,0,0,1,7.52-2.58c6.36,0,10.09,4.27,10.09,11.54v19.2Z"
    />
    <path
      fill="#005da0"
      d="M450.74,175.45V145.5l6.16-.3.7,5h.15c1.89-3.63,4.83-5.43,8.26-5.43a11.24,11.24,0,0,1,3.28.45l-.5,7.62a11,11,0,0,0-3.28-.5c-4,0-7,2.93-7,8v15.12Z"
    />
    <path
      fill="#005da0"
      d="M480.64,142.07c-3,0-4.43-.85-4.43-3.29,0-2.13,1.45-3.33,4.43-3.33s4.38,1.15,4.38,3.33S483.68,142.07,480.64,142.07Zm-4,33.38V145.5l7.91-.35v30.3Z"
    />
    <path
      fill="#005da0"
      d="M495.66,175.45V151h-3.77V145.3h3.77V144c0-4.53,3.14-8.26,10.35-8.26a18,18,0,0,1,4.88.65l-.45,5a13.48,13.48,0,0,0-2.89-.3c-3,0-4.17,1.2-4.17,3.14v1.09h6.41V151h-6.41v24.43Z"
    />
    <path
      fill="#005da0"
      d="M525.62,151.07V166.5c0,2.33,1.19,3.33,3.58,3.33a8.37,8.37,0,0,0,2.39-.35l.44,5.62a14.51,14.51,0,0,1-5.17.85c-6,0-9-3.48-9-9.21V151.07h-3.38V145.3h3.73l1.29-6.81,6.08-.55v7.36h6.71v5.77Z"
    />
    <path
      fill="#aeb1bc"
      d="M116.54,52.92,82,27,47.54,1.09a5.45,5.45,0,0,0-7.62,1.08L9.56,42.59a5.18,5.18,0,0,0-.93,2.06,5.44,5.44,0,0,0,2,5.7L31.18,65.79,59,86.65l20.55,15.44a5.44,5.44,0,0,0,6,.34A5.36,5.36,0,0,0,87.26,101l30.37-40.42A5.45,5.45,0,0,0,116.54,52.92ZM45.31,4.18,65.86,19.63h0L79.75,30.06,93.63,40.5h0l20.56,15.44a1.77,1.77,0,0,1,.37,2.47l-4.93,6.79L86.37,47.71,61.17,28.78,37.91,11.31l4.92-6.79A1.79,1.79,0,0,1,45.31,4.18ZM84.19,98.82a1.73,1.73,0,0,1-1,.6,1.78,1.78,0,0,1-1.29-.34L61.19,83.56,33.53,62.78,12.86,47.26a1.84,1.84,0,0,1-.68-1.15,1.73,1.73,0,0,1,.28-1.17l5.64-7.5s6.19-8,11.62-15.24L53,39.68l25.2,18.93,23.26,17.47C96,83.32,89.83,91.32,89.83,91.32Z"
    />
    <path
      fill="#005da0"
      d="M122.74,114.73l52-69.59,5.3-7,.16-.23a1.1,1.1,0,0,0-.21-1.58L165.3,25.66a1.12,1.12,0,0,0-1.57.29l-.17.21-2,2.55-64.27,86H26.53L0,202.16H177.08l-25-87.43Zm-117.93,84,24.24-80.47H94.7L89,125.78,80.9,137,80,138.7l-.85,1.94-.88,2.17-.91,2.31-.88,2.52-.86,2.48-.82,2.49-.8,2.44-.72,2.32-.67,2.13L72,161.37l-.44,1.55-.35,1.19-.28.74a1.61,1.61,0,0,1-.2.35c-.16.22-1.15,1.14-2,.53s-.7-2.62-.62-3.59l.72-8.93a1.35,1.35,0,0,0-2-1.16,22.83,22.83,0,0,0-7.76,7.5c.18-.67.36-1.35.55-2a80.68,80.68,0,0,0,2.46-9.4c.26-1.8-.2-3.94-2.31-4.29-2.36-.39-3.91,2.1-5.07,3.74-3.51,5-5.58,11.24-9.93,15.54-1.64,1.63-3.46,2.82-5.82,2.15-2.73-.78-3.79,2.44,1,2.87s8.26-4.58,10.42-8.08c1.56-2.54,2.93-5.2,4.46-7.77.73-1.23,1.49-2.44,2.33-3.61.36-.49,1-1.82,1.69-2,.84-.23.62.26.57.92a25,25,0,0,1-1.14,4.48c-.43,1.58-.85,3.16-1.28,4.74L54.28,167a1.35,1.35,0,0,0,2.46,1c3.38-4.81,5.06-8.89,9.22-12.11-.08,1.05-.17,2.11-.26,3.17a37,37,0,0,0-.35,5.35c.2,2.85,2.36,4.83,5.07,3.58a9.42,9.42,0,0,0,2.43-1.65l.17-.11.57-.43,1-.69,1.43-1,1.58-1.18,1.74-1.23,2.05-1.44,2.08-1.54,2.07-1.53,2.08-1.53,2-1.54,1.9-1.44,1.76-1.43,1.53-1.19,1.22-1.14,1-.91,0-.05,0,0,6.47-7.65,16.55-22.13H149.5l22.86,80.47Z"
    />
    <rect
      width="42.93"
      height="4.19"
      x="151.42"
      y="55.76"
      fill="#005da0"
      rx="0.88"
      transform="translate(22.76 161.3) rotate(-53.07)"
    />
    <polygon
      fill="#005da0"
      points="87.4 181.56 136.61 181.56 135.57 177.66 87.4 177.66 39.23 177.66 38.19 181.56 87.4 181.56"
    />
  </Svg>
);

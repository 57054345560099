import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#91f2bd"
      d="M55.8,49.9c1.7,13.7-8,26.2-21.7,27.9c-4.7,0.6-9.2-0.2-13.3-2C13.1,72.4,7.3,65.1,6.2,56.1c-1.7-13.7,8-26.2,21.7-27.9C41.6,26.5,54.1,36.2,55.8,49.9z"
    />
    <path
      fill="#6dc289"
      d="M34.1,80.8c-1.5,0-2.8-1.1-3-2.6c-0.2-1.6,1-3.1,2.6-3.3c5.8-0.7,11-3.7,14.6-8.3c3.6-4.6,5.2-10.4,4.5-16.2c-0.2-1.6,1-3.1,2.6-3.3c1.6-0.2,3.1,1,3.3,2.6c0.9,7.4-1.1,14.8-5.7,20.7c-4.6,5.9-11.2,9.7-18.6,10.6C34.3,80.8,34.2,80.8,34.1,80.8z"
    />
    <path
      fill="#6dc289"
      d="M31,75c-1.7,0-3-1.3-3-3s1.3-3,3-3c0.7,0,1.3,0,2-0.1c4.2-0.5,8-2.7,10.6-6c1-1.3,2.9-1.5,4.2-0.5c1.3,1,1.5,2.9,0.5,4.2c-3.6,4.6-8.8,7.6-14.6,8.3C32.8,74.9,31.9,75,31,75z"
    />
    <path
      fill="#444b54"
      d="M31,81c-3.9,0-7.8-0.8-11.4-2.4c-9-4-15.2-12.3-16.4-22.1c-0.9-7.2,1-14.4,5.4-20.3c1-1.3,2.9-1.6,4.2-0.6c1.3,1,1.6,2.9,0.6,4.2C10,44.4,8.5,50,9.2,55.7c1,7.7,5.8,14.2,12.9,17.4c3.6,1.6,7.7,2.2,11.7,1.7c1.6-0.2,3.1,1,3.3,2.6c0.2,1.6-1,3.1-2.6,3.3C33.3,80.9,32.1,81,31,81z"
    />
    <path
      fill="#fff"
      d="M12.1,58.3c-1.5,0-2.8-1.1-3-2.6c-0.7-5.8,0.9-11.6,4.5-16.2c1-1.3,2.9-1.5,4.2-0.5c1.3,1,1.5,2.9,0.5,4.2c-2.6,3.4-3.8,7.6-3.3,11.8c0.2,1.6-1,3.1-2.6,3.3C12.4,58.3,12.3,58.3,12.1,58.3z"
    />
    <path
      fill="#fff0b3"
      d="M121.8,42.5c4.7,15.9-4.4,32.6-20.3,37.3c-5.4,1.6-11,1.6-16.1,0.3c-9.8-2.6-18.1-10.1-21.2-20.5c-4.7-15.9,4.4-32.6,20.3-37.3S117.1,26.6,121.8,42.5z"
    />
    <path
      fill="#efcd89"
      d="M108.2,70.9c0,0-0.1-0.1-0.1-0.1c-4.3-3.2-6.5-8.3-4.2-13.2c0.3-0.7,0.6-1.4,0.9-2.1c0.5-1.3,0.1-2.9-1.1-3.7c-1.6-1-3.7-0.3-4.4,1.4c-3,7.4-8.2,13.6-14.9,17.8c0,0,0,0,0,0c-7.2-3.1-12-9.6-13-17.3c-0.2-1.6-1.6-2.8-3.2-2.7c-1.7,0.1-3,1.7-2.8,3.4c0.1,0.9,0.3,1.7,0.4,2.5c-0.8-0.5-1.9-0.6-2.9-0.1c-1.4,0.7-1.9,2.3-1.5,3.7c2.5,8.2,7.9,15,15.2,19.1c0.3,0.3,0.7,0.5,1.1,0.6c2.2,1.1,4.5,2,6.9,2.7c0.3,0.1,0.5,0.1,0.8,0.1c1.3,0,2.5-0.9,2.9-2.2c0.2-0.9,0-1.7-0.4-2.4c1.7,0.3,3.4,0.5,5.1,0.5c1.2,0,2.3-0.1,3.5-0.2c3.8-0.5,7.4-1.7,10.7-3.6C108.5,74.2,109,72.3,108.2,70.9z"
    />
    <path
      fill="#444b54"
      d="M93,84c-2.2,0-4.4-0.2-6.6-0.7c-1.6-0.3-2.7-1.9-2.3-3.5s1.9-2.7,3.5-2.3c2.9,0.6,5.8,0.7,8.8,0.3c7.2-0.9,13.5-4.5,18-10.2s6.4-12.8,5.5-19.9c-1-8.2-5.7-15.4-12.7-19.7c-1.4-0.9-1.9-2.7-1-4.1c0.9-1.4,2.7-1.9,4.1-1c8.6,5.3,14.3,14.1,15.5,24.1c1.1,8.7-1.3,17.4-6.7,24.3c-5.4,7-13.2,11.4-22,12.5C95.7,83.9,94.3,84,93,84z"
    />
    <path
      fill="#ff5576"
      d="M98.7,34.7c2.4,19.2-11.2,36.7-30.4,39c-6.6,0.8-12.9-0.2-18.6-2.8c-10.8-4.8-18.9-15-20.5-27.7c-2.4-19.2,11.2-36.7,30.4-39S96.4,15.5,98.7,34.7z"
    />
    <path
      fill="#db3e64"
      d="M64,71c-1.7,0-3-1.3-3-3s1.3-3,3-3c14.3,0,26-11.7,26-26c0-1.7,1.3-3,3-3s3,1.3,3,3C96,56.6,81.6,71,64,71z"
    />
    <path
      fill="#fff"
      d="M38.1 28.9c-.5 0-.9-.1-1.4-.3-1.5-.8-2.1-2.6-1.3-4C39.3 17 46 11.2 54 8.6c1.6-.5 3.3.3 3.8 1.9.5 1.6-.3 3.3-1.9 3.8-6.5 2.1-11.9 6.8-15 12.9C40.3 28.3 39.2 28.9 38.1 28.9zM35 35A3 3 0 1 0 35 41 3 3 0 1 0 35 35z"
    />
    <path
      fill="#444b54"
      d="M102,39c0-21-17-38-38-38S26,18,26,39c0,13.4,6.9,25.6,18.4,32.5c1.4,0.9,3.3,0.4,4.1-1c0.9-1.4,0.4-3.3-1-4.1C37.8,60.5,32,50.3,32,39C32,21.4,46.4,7,64,7s32,14.4,32,32S81.6,71,64,71c0,0,0,0,0,0c-0.2,0-0.4,0-0.5,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0l-8,6c-1.3,1-1.6,2.9-0.6,4.2c1,1.3,2.9,1.6,4.2,0.6l6.2-4.6l6.2,4.6c0.5,0.4,1.2,0.6,1.8,0.6c0.9,0,1.8-0.4,2.4-1.2c1-1.3,0.7-3.2-0.6-4.2l-1.9-1.4C89.1,72.5,102,57.2,102,39z"
    />
    <path
      fill="#444b54"
      d="M58.3,114.1c-1.5,0-2.7-1.1-3-2.6c-0.8-5.2,1.3-9.1,3.1-12.6c1.9-3.5,3.6-6.9,2.8-12c-0.3-1.6,0.9-3.2,2.5-3.4c1.6-0.2,3.2,0.9,3.4,2.5c1.1,7.1-1.4,11.9-3.4,15.7c-1.6,3.1-2.9,5.6-2.4,8.9c0.2,1.6-0.9,3.2-2.5,3.4C58.6,114.1,58.5,114.1,58.3,114.1z"
    />
    <path fill="#444b54" d="M60 119A3 3 0 1 0 60 125A3 3 0 1 0 60 119Z" />
  </Svg>
);

import { useState, Dispatch, SetStateAction } from 'react';
import ls from 'localstorage-ttl';

export function useLocalStorage<T = any>(
  key: string,
  initialValue?: T | (() => T),
  ttl?: number,
  /**
   * if true initialValue will always take precedence over localStorage
   * as long as it does not return undefined or null
   */
  initialValueTakesPrecendence?: boolean,
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    function getInitialValue() {
      return initialValue instanceof Function ? initialValue() : initialValue;
    }
    try {
      if (initialValueTakesPrecendence) {
        const _initialValue = getInitialValue();
        if (_initialValue != null) {
          console.log(key, 'initialValueTakesPrecendence', _initialValue);
          ls.set(key, JSON.stringify(_initialValue), ttl);
        }
      }

      const storedValue = JSON.parse(ls.get(key));
      if (storedValue != undefined) {
        console.log(key, 'recover stored', storedValue);
        return storedValue;
      }

      const _initialValue = getInitialValue();
      ls.set(key, JSON.stringify(_initialValue), ttl);

      console.log(key, 'initial', _initialValue);
      return _initialValue;
    } catch (o_O) {
      return getInitialValue();
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((prevValue: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      ls.set(key, JSON.stringify(valueToStore), ttl);
    } catch (error) {
      // A more advanced implementation would handle the error case
      // console.log(error);
    }
  };

  console.log(key, storedValue);

  return [storedValue, setValue] as [T, Dispatch<SetStateAction<T>>];
}

import styled from '~/utils/styled';
import isPropValid from '@emotion/is-prop-valid';
import media from '~/utils/mediaqueries';
import Link from '../Link';

type NavItemProps = {
  appearance?: 'default' | 'highlight';
  isVertical?: boolean;
};

export const NavItem = styled(Link)<NavItemProps>(
  ({ theme }) => ({
    display: 'block',
    textAlign: 'left',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 400,
    color: theme.colors.dim,
    fontSize: theme.fontSizes[0],
    paddingRight: theme.space[2],
    paddingBottom: theme.space[1],

    [media('xl')]: {
      paddingRight: 0,
      textAlign: 'right',
    },

    '&:not(span):hover, &:not(span):focus': {
      color: theme.colors.secondaryBright,
      WebkitTapHighlightColor: 'transparent',
    },
  }),
  ({ theme, appearance }) =>
    // Appearing as highlighted link
    appearance === 'highlight' && {
      paddingRight: theme.space[3],

      [media('xl')]: {
        paddingBottom: theme.space[2],
        paddingRight: 0,
        textAlign: 'right',
      },
    },
  ({ theme, isVertical }) =>
    isVertical && {
      [media('lg')]: {
        paddingLeft: theme.space[2],
        paddingRight: 0,
        paddingBottom: 0,
      },
    },
);

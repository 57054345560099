import { useEffect } from 'react';

export function cleanInnerHTML(innerHTML: string) {
  // Remove some tags from innerHTML that may come from cms (sanitized script tags, p tags, newlines)
  return innerHTML
    .replace(/&lt;\/script/gi, '')
    .replace(/&gt;/gi, '')
    .replace(/<p>/gi, '')
    .replace(/<\/p>/gi, '')
    .replace(/(\r\n|\n|\r)/gm, "");
}

export function mountScript(script: string) {
  const scriptElement = document.createElement('script');
  // un-sanitize script from CMS
  script = script.replace('&lt;', '<').replace('&gt;', '>')
  // Create temporary HTML element to extract attributes and script content
  let temp = document.createElement("div");
  temp.innerHTML  = script;
  // Get Script body, set to script element body if present, strip closing script tags
  const innerHTML = temp.firstElementChild?.innerHTML;
  innerHTML ? scriptElement.innerHTML = cleanInnerHTML(innerHTML) : null;
  // Get Script attributes and assign to script
  const attributes: NamedNodeMap | undefined = temp.firstElementChild?.attributes;
  if (attributes) {
    Object.keys(attributes).map(function( attr, index ) {
      const attribute = attributes[ index ]
      if (attribute) {
        scriptElement.setAttribute(attribute.name, attribute.value)
      }
    } );
  }

  // Append Script to body
  document.body.appendChild(scriptElement);
}

export function useScriptEffect(
  scripts: string[],
  deps?: readonly any[],
) {
  useEffect(() => {
    scripts.forEach(function (script) {
      mountScript(script);
    })
  }, deps);
}

export function searchAndExecuteScriptsFromHtml(html: string) {
  // Get all types of script tags that may come from CMS
  const sanitizedScripts = html.match(/&lt;script([\S\s]*?)&lt;\/script&gt;/gi) ?? [];
  const scripts = html.match(/<script([\S\s]*?)<\/script>/gi) ?? [];
  const allScripts = sanitizedScripts.concat(scripts);
  // Read, format, append scripts to body
  useScriptEffect(allScripts);
}

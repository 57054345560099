import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M23.6,11.1c-0.3,0-0.5,0-0.8,0.2c-0.2,0.2-0.4,0.4-0.4,0.6v5.8c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3
	c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.3-0.4,0.3-0.7v-4.4h11v2.1L24.4,37.2c-0.1,0.3-0.2,0.4-0.2,0.5c0,0.4,0.2,0.7,0.5,0.9
	s0.6,0.3,1,0.3s0.7-0.2,0.8-0.5l11.4-22.2c0.3-0.4,0.4-0.8,0.4-1.1V12c0-0.2-0.1-0.4-0.4-0.6c-0.3-0.2-0.5-0.3-0.8-0.3H23.6
	L23.6,11.1z"
    />
    <path
      d="M17.3,11c-0.4,0-0.7,0.2-1,0.6L13.2,16c-0.4,0.3-0.6,0.7-0.6,1.1c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
	s0.5-0.2,0.7-0.5l1.5-2.5V38c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3s0.4-0.4,0.4-0.7V12
	c0-0.3-0.1-0.5-0.3-0.7S17.6,11,17.3,11z"
    />
  </Svg>
);

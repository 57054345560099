import { css } from '@emotion/core';
import styled from '~/utils/styled';
import { Box, Flex } from 'rebass';
import { toPx } from '~/utils/styles';
import media from '~/utils/mediaqueries';
import { sizes } from '~/themes/baseTheme';
import Segment from '~/components/common/Segment';
import Icon from '~/components/common/Icon';
import { breakpointNavigation } from '~/themes/baseTheme';

export const headroomStyles = css`
  .headroom-wrapper {
    position: absolute !important;
    width: 100%;
    z-index: 200;
  }

  .header-main {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }

  .header--transparent-at-top .headroom--unfixed {
    .header-main {
      background-color: transparent;
      box-shadow: 0px 2px 50px rgba(0, 0, 0, 0);
    }
  }

  /* disable scroll unfixed/unpinned behaviour (ZMAR-237) */
  .headroom--unfixed {
    position: fixed !important;
  }

  /* disable scroll unfixed/unpinned behaviour (ZMAR-237) */
  .headroom--unpinned {
    transform: translate3d(0, 0, 0) !important;
  }
`;

export const HeaderDesktop = styled(Box)({
  display: 'none',

  [media(breakpointNavigation)]: {
    display: 'block',
  },
});

type HeaderMobileProps = {
  expanded?: boolean;
};

export const HeaderMobile = styled(Box)<HeaderMobileProps>(
  {
    display: 'block',

    [media(breakpointNavigation)]: {
      display: 'none',
    },
  },
  ({ expanded }) =>
    expanded && {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      minHeight: '100vh',
    },
);

type SegmentWithShadowProps = {
  expanded?: boolean;
};

export const SegmentWithShadow = styled(Segment)<SegmentWithShadowProps>(
  ({ theme }) => ({
    zIndex: 1,

    [media('sm')]: {
      paddingTop: theme.space[1],
      paddingBottom: theme.space[1],
    },
  }),
  ({ expanded }) =>
    expanded && {
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1) !important',
    },
);

export const HeaderMobileInner = styled(Flex)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '0', // for Firefox
  height: `calc(100vh - ${toPx(sizes.header.height.default)})`,

  [media('sm')]: {
    height: `calc(100vh - ${toPx(sizes.header.height.sm)})`,
  },
});

export const HeaderMobileWrapNav = styled('div')({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '0', // for Firefox
});

export const HeaderMobileWrapMeta = styled('div')({
  flex: 'none',
});

export const Toggle = styled(Icon)(({ theme }) => ({
  fontSize: theme.fontSizes[3],
  display: 'inline-flex',
}));

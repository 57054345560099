import { Table } from 'rebass';
import styled from '~/utils/styled';
import { toPx } from '~/utils/styles';

export const TableScrollWrapper = styled('div')(({ theme }) => ({
  overflow: 'auto',
}));

export const TableStyled = styled('table')(({ theme, appearance }) => ({
  marginTop: 0,
  marginBottom: theme.space[2],
  borderCollapse: 'collapse',
  width: '100%',
  maxWidth: '100%',

  thead: {
    // First line of thead
    th: {
      fontFamily: theme.fonts.headline,
      fontSize: theme.fontSizes[3],
      textTransform: 'uppercase',
      fontWeight: 500,
      padding:
        appearance === 'alternate' ? '.5em 2em .5em .5em' : '.5em 2em .5em 0',
    },

    // Second line of thead
    'tr:nth-of-type(2) th': {
      fontSize: theme.fontSizes[1],
    },
  },

  tbody: {
    td: {
      verticalAlign: 'top',
      borderBottom:
        appearance === 'alternate' ? 0 : `1px solid ${theme.colors.border}`,
      padding:
        appearance === 'alternate' ? '.5em 2em .5em .5em' : '.5em 2em .5em 0',
    },
  },

  'th, td': {
    paddingLeft: theme.space[1],
    paddingRight: theme.space[1],
  },

  'tr:nth-of-type(even)': {
    backgroundColor:
      appearance === 'alternate' ? theme.colors.tertiaryBright : 'transparent',
    color:
      appearance === 'alternate' ? theme.colors.tertiaryBrightText : 'inherit',
  },
}));

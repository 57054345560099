import styled from '~/utils/styled';
// we use this to prevent passing of unvalid props to the DOM
// https://emotion.sh/docs/styled#customizing-prop-forwarding ~DH
import shouldForwardProp from '@emotion/is-prop-valid';
import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

// General styles
const sharedLinkStyles = {
  color: 'inherit',
  cursor: 'pointer',

  '&:focus, &:active, &:hover': {
    color: 'inherit',
  },
};

// Internal link in new tab
export const LinkStyled = styled('a', {
  shouldForwardProp,
})(sharedLinkStyles);

// Internal link
export const GatsbyLinkStyled = styled(GatsbyLink, {
  shouldForwardProp,
})(sharedLinkStyles);

// External link
export const OutboundLinkStyled = styled(OutboundLink, {
  shouldForwardProp,
})(sharedLinkStyles);

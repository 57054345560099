import React, { createElement } from 'react';
import { IconStyled } from './Icon.styles';
import * as icons from './icons';

export type IconNames = keyof typeof icons;

type IconProps = {
  name: IconNames;
  fillColor?: React.ComponentProps<typeof IconStyled>['fillColor'];
  [key: string]: any;
};

/**
 * Available icons.
 *
 * To add a new icon, simply create a component in icons/ and
 * import it in icons/index.js
 */
interface IconMap {
  [icon: string]: string;
}

const availableIcons: IconMap = (Object.keys(icons) as Array<IconNames>).reduce(
  (iconElements, iconKey) => ({
    ...iconElements,
    [iconKey]: createElement(icons[iconKey]),
  }),
  {},
);

export default function Icon({ name, ...rest }: IconProps) {
  return availableIcons[name] ? (
    <IconStyled
      fillColor={name.match(/^Number/) ? 'primary' : undefined}
      {...rest}
    >
      {availableIcons[name]}
    </IconStyled>
  ) : null;
}

import React from 'react';
import {
  useCraftGlobals,
  useCraftTranslations,
} from '~/utils/hooks/useCraftGlobals';
import MetaReduced from '~/components/common/MetaReduced';

import { Craft_MenuItemsMenuItemSub } from '~/query';

export default function CraftGlobalMetaReduced({}) {
  const { globalMeta } = useCraftGlobals();
  const t = useCraftTranslations();

  if (!globalMeta) return null;

  const { globalNavigationMeta } = useCraftGlobals();

  const navItems = (globalNavigationMeta?.menuItems || [])
    .map(item => {
      return {
        id: item?.id || undefined,
        link: {
          url: item?.menuItemLink?.url || undefined,
          text: item?.menuItemLink?.text || undefined,
          target: item?.menuItemLink?.target || undefined,
        },
        appearance: item?.menuItemAppearance || undefined,
        children: (item?.children || [])
          .map((childItem: any) => {
            return {
              id: childItem?.id || undefined,
              link: {
                url: childItem?.menuItemLink?.url || undefined,
                text: childItem?.menuItemLink?.text || undefined,
                target: childItem?.menuItemLink?.target || undefined,
              },
              appearance: childItem?.menuItemAppearance || undefined,
              description:
                (childItem as Craft_MenuItemsMenuItemSub)
                  ?.menuItemDescription || undefined,
            };
          })
          .filter(_ => _ && _.id),
      };
    })
    .filter(_ => _ && _.id);

  return (
    <MetaReduced
      translations={{ Phone: t('Phone'), Mail: t('Mail') }}
      mail={globalMeta.metaMail ?? undefined}
      phone={globalMeta.metaPhone ?? undefined}
      businessHours={globalMeta.metaBusinessHours ?? undefined}
      metaNavItems={navItems ?? undefined}
    />
  );
}

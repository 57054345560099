import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M18.7,11.1c-0.3,0-0.5,0-0.8,0.2c-0.2,0.2-0.4,0.4-0.4,0.6v5.8c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3
	c0.3,0,0.6-0.1,0.9-0.3S20,18,20,17.7v-4.4h11v2.1L19.5,37.2c-0.1,0.3-0.2,0.4-0.2,0.5c0,0.4,0.2,0.6,0.5,0.9s0.7,0.3,1.1,0.3
	s0.7-0.2,0.8-0.5l11.4-22.2c0.3-0.4,0.4-0.8,0.4-1.1V12c0-0.2-0.1-0.4-0.4-0.6c-0.3-0.2-0.5-0.3-0.8-0.3H18.7L18.7,11.1z"
    />
  </Svg>
);

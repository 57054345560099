/**
 * slugify
 * @param {String} string any string to slugify
 * @returns {String} the slugified string
 */
export default function slugify(string) {
    return string
      .toLowerCase()
      .replace(/â|à|á|å|ã|ā/g, 'a') // replace all umlauts
      .replace(/ä|æ/g, 'ae')
      .replace(/ç|ć|č/g, 'c')
      .replace(/é|ê|è|ë|ė/g, 'e')
      .replace(/ó|ô|ò|õ|ø|ō/g, 'o')
      .replace(/ö|œ/g, 'oe')
      .replace(/š|ś/g, 's')
      .replace(/ß/g, 'ss')
      .replace(/ú|û|ù|ū/g, 'u')
      .replace(/ü/g, 'ue')
      .replace(/ý|ÿ/g, 'y')
      .replace(/ž/g, 'z')
      .replace(/:/g, ' ') // treat colon as whitespace
      .replace(/\./g, ' ') // treat dot as whitespace
      .replace(/[^\w\s-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
      .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single -
      .replace(/^-+|-+$/g, ''); // remove leading, trailing -
  }
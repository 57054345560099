import React, { useContext, createContext } from 'react';

import {
  LinkStyled,
  GatsbyLinkStyled,
  OutboundLinkStyled,
} from './Link.styles';

import { navigate } from '@reach/router';

export const LinkSideEffectContext = createContext<(arg: any) => any | void>(
  () => {},
);

/**
 * Replace source url.
 *
 * @param {string} url
 */
export function replaceSourceUrl(url: string) {
  if (typeof url !== 'string') return url;

  const replacedUrl = url.replace(
    process.env.GATSBY_SOURCE_URL ? process.env.GATSBY_SOURCE_URL : '',
    process.env.GATSBY_REPLACE_URL ? process.env.GATSBY_REPLACE_URL : '',
  );

  return replacedUrl.length > 0 ? replacedUrl : '/';
}

/**
 * Replace file source url.
 *
 * @param {string} url
 */
export function replaceFileSourceUrl(url: string) {
  return url.replace(
    process.env.GATSBY_FILE_SOURCE_URL
      ? process.env.GATSBY_FILE_SOURCE_URL
      : '',
    process.env.GATSBY_FILE_REPLACE_URL
      ? process.env.GATSBY_FILE_REPLACE_URL
      : '',
  );
}

/**
 * Function:
 * Parse link url.
 *
 * Usage:
 * - Include needed variables in the .env and .env.example file.
 * -- GATSBY_FILE_SOURCE_URL
 * -- GATSBY_FILE_REPLACE_URL
 * -- GATSBY_SOURCE_URL
 * -- GATSBY_REPLACE_URL
 * - Parse whatever and let this script do it's work.
 *
 */
function searchReplaceUrl(url: string) {
  // Skip adjustment without url.
  // Also skip urls that do not start with http or https.
  if (!url || typeof url !== 'string' || !/^(http|https)/.test(url)) {
    return url;
  }

  const urlParts = url.split('/');
  const filename = urlParts.pop();

  // Fairly certain, if there are not enough parts [scheme][host][path]
  // something is wrong. Like https://example.com would be such a case.
  // We handle it as normal url. ~RS 2019/03/15
  if (urlParts.length <= 3) {
    return replaceSourceUrl(url);
  }

  // Check if the link points to a file.
  // The following regex isn't really reliable enough because it spits out
  // false positives for mailto: and links like https://example.com,
  // that just have no trailing slash.
  // I am currently trying to skip on those, but it's a bit... ugly ~RS 2019/03/15
  const isFile = /.+\..+/.test(filename || '');

  if (isFile) {
    return replaceFileSourceUrl(url);
  }

  return replaceSourceUrl(url);
}

type LinkProps = {
  children?: React.ReactNode;
  target?: string;
  className?: string;
  rel?: string;
  to?: string;
  onClick?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  appearance?: 'default' | 'highlight';
  isVertical?: boolean;
};

export default function Link({
  children,
  target,
  className,
  rel,
  to,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isVertical,
  ...rest
}: LinkProps) {
  const hasClickEvent = typeof onClick === 'function';

  const linkSideEffectFunc = useContext(LinkSideEffectContext);

  // Ignore the link if nothing would happen.
  if (!to && !hasClickEvent) {
    return (
      <span className={className} {...rest}>
        {children}
      </span>
    );
  }

  const anchor = /^#/.test(to || '');

  // Adjust to.
  const toStripped = searchReplaceUrl(to || '/');

  // This assumes that any internal link
  // will start with exactly one slash and that anything else is external.
  const internal = /^\/(?!\/)/.test(toStripped || '');

  const elementProps = {
    className,
    onClick: onClick || linkSideEffectFunc,
    onMouseEnter,
    onMouseLeave,
    target,
    rel,
    isVertical,
    ...rest,
  };

  if (to && anchor) {
    return (
      <LinkStyled
        className={className}
        href={to}
        onClick={e => {
          e.preventDefault();
          elementProps?.onClick?.(e);
          navigate(to);
        }}
        {...elementProps}
      >
        {children}
      </LinkStyled>
    );
  }

  if (internal) {
    return target === '_blank' ? (
      // Use <a> for internal links that open in a new tab.
      <LinkStyled className={className} href={toStripped} {...elementProps}>
        {children}
      </LinkStyled>
    ) : (
      // Use Gatsby <Link> for pure internal links.
      <GatsbyLinkStyled className={className} to={toStripped} {...elementProps}>
        {children}
      </GatsbyLinkStyled>
    );
  }

  // Use <OutboundLink> with fixed rel for external links.
  return (
    <OutboundLinkStyled
      href={toStripped}
      {...elementProps}
      rel="noopener noreferrer"
    >
      {children}
    </OutboundLinkStyled>
  );
}

import styled from '~/utils/styled';
import { Box } from 'rebass';
import Link from '../Link';

export const InlineBox = styled(Box)({
  display: 'inline-block',
});

export const MetaLink = styled(Link)({
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
});

export const Uppercase = styled('span')({
  textTransform: 'uppercase',
});

type PoweredByImgProps = {
  invertPoweredByImage: boolean;
};

export const PoweredByImg = styled('img')<PoweredByImgProps>(
  ({ invertPoweredByImage }) => ({
    verticalAlign: 'middle',
    width: 125,
    filter: invertPoweredByImage ? 'invert(.8)' : undefined,
  }),
);

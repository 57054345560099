import { css } from '@emotion/core';
import styled from '~/utils/styled';
import { Box, Flex } from 'rebass';
import Link from '../Link';
import Button from '../Button';

export const InlineBox = styled(Box)({
  display: 'inline-block',
});

export const NavItemWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

type NavItemProps = {
  appearance?: 'default' | 'highlight';
  padded?: number;
};

export const NavItem = styled(Link)<NavItemProps>(
  ({ theme }) => ({
    display: 'block',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: theme.fontSizes[1],
    paddingBottom: theme.space[1],

    '&:not(span):hover, &:not(span):focus': {
      color: theme.colors.secondaryBright,
      WebkitTapHighlightColor: 'transparent'
    },
  }),
  ({ theme, appearance, padded }) =>
    // Appearing as highlighted link
    appearance === 'highlight' && {
      textTransform: 'uppercase',
      fontWeight: 700,
      paddingBottom: padded === 1 ? theme.space[2] : 0,
    },
);

import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';


export const CookieContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-color: white;
  z-index: 9998;
  width: 100%;
  max-width: 720px;

  ${media('md')} {
    width: auto;
  }
`

export const CookieInnerContainer = styled.div`
  display: flex;
  justify-conntent: flex-start;
  flex-direction: column;
  padding: 2rem;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  position: relative;
`

export const CookieHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.5rem;
`

export const CookieBody = styled.div``

export const CookieContentContainer = styled.div``

export const CookieContentBlock = styled.div`
  margin-bottom: var(--space-sm);
  margin-top: var(--space);
`

export const CookieBannerText = styled.div`
  padding: 0 var(--space);
`

export const CookieHeadline = styled.h1`
  text-align: left;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0.5rem;
`

export const Text = styled.div`
  margin-bottom: var(--space-sm);
  margin-top: var(--space);
`

export const CheckboxesWrapper = styled.div`
  margin-top: 1rem;
`;

export const CookieLink = styled.a`
  border-bottom: 1px solid var(--text-color);
  :hover {
    border-bottom: none;
  }
  cursor: pointer;
  margin-right: var(--space-sm);
`

export const TextLink = styled.a`
  border-bottom: 1px solid var(--text-color);
  :hover {
    border-bottom: none;
  }
`

export const List = styled.ol`
  list-style: none;
  padding-inline-start: 0;
  display: flex;
`

export const Socialtem = styled.li`
  margin: var(--space-sm) var(--space-sm) var(--space-sm) 0;
  transition: 0.2s;
  :hover {
    color: var(--secondary-color);
    cursor: pointer;
  }
`

export const ButtonContainer = styled.div`
  margin: var(--space);
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  flex-wrap: wrap;
`
  // ${media.lessThan("medium")`
  //   flex-direction: column;
  //   gap: var(--space-sm);
  // `}
/* eslint-disable no-restricted-globals */
import queryString from 'query-string';
import { useLocalStorage } from './useLocalStorage';

const CODE_KEY = 'CalculatorVoucherCode';
const CODE_QUERY_PARAM = 'code';

type VoucherCode = string | undefined;

export function useVoucherCode() {
  return useLocalStorage<VoucherCode>(
    CODE_KEY,
    () => {
      if (typeof window !== 'undefined') {
        const params = queryString.parse(location.search);
        const codeParam = params[CODE_QUERY_PARAM];
        const code = Array.isArray(codeParam) ? codeParam[0] : codeParam;

        delete params[CODE_QUERY_PARAM];
        let newQs = queryString.stringify(params);
        newQs = newQs ? `?${newQs}` : '';
        history.replaceState({}, '', `${location.pathname}${newQs}`);

        return code ?? undefined;
      }
    },
    undefined,
    true,
  );
}

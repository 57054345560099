import styled from '~/utils/styled';
import { Flex, Box } from 'rebass';

export const Wrapper = styled(Box)(
  {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: 9999999999,
    background: 'white',
    overflow: 'hidden',
  }
);

export const Content = styled(Box)(
  {
    width: 700,
    maxWidth: '100%',
    padding: 25,
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxHeight: '100%',
    overflow: 'auto'
  }
);

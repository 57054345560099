import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#e0e0e0"
      d="M95.4,43c0,0,4.8-9.9-11-22.9C72.7,10.5,59.3,9.6,50.9,16.9c-8.3,7.2-4.5,20.2,12.9,20.1 C81.3,36.8,95.4,39.5,95.4,43"
    />
    <path
      fill="#b6b6b7"
      d="M48.043,20.367c-1.533,2.749-1.774,5.818-0.672,8.563c1.81,4.423,7.124,7.971,16.194,7.971 c0.079,0,0.156,0,0.235-0.001c0.312-0.002,0.626-0.003,0.936-0.003c16.968,0,30.566,2.664,30.665,6.003c0,0,0.578-1.182,0.676-3.267 c0.082-2.096-0.32-5.075-2.23-8.664C89.695,28.585,77.838,26.9,63.8,26.9C55.648,26.9,50.461,24.133,48.043,20.367"
    />
    <path
      fill="#73aeb6"
      d="M93.9,46.1l0.4,63.8c0,3.3-13.4,6-29.9,6.1s-30-2.5-30-5.7L34,46.5L93.9,46.1z"
    />
    <path
      fill="#e0e0e0"
      d="M93.9,46.1l0.399,63.8c0,3.3-13.399,6-29.899,6.1c-16.5,0.1-30-2.5-30-5.7L34,46.5L93.9,46.1"
    />
    <path
      fill="#a0a1a2"
      d="M93.8,46.101l-0.003,0L84.4,48l0.5,66.2c0,0,0.579-0.069,1.447-0.221 c3.805-0.834,6.47-1.876,7.491-3.027c0.231-0.322,0.361-0.672,0.361-1.051L93.8,46.101"
    />
    <path
      fill="#d3d3d1"
      d="M63.901 37.001A31.501 6.2 0 1 0 63.901 49.401A31.501 6.2 0 1 0 63.901 37.001Z"
    />
    <path
      fill="#f8e390"
      d="M63.901 37.001A31.501 6.2 0 1 0 63.901 49.401A31.501 6.2 0 1 0 63.901 37.001Z"
    />
    <path
      fill="#454b54"
      d="M95.4,46c-0.2,0-0.4,0-0.7-0.1c-1.1-0.3-2-1.1-2.2-2.2c-1.9-1.4-11.3-3.8-27.7-3.8h-1.2 c-9.1,0-14-3.2-16.5-5.9c-2.4-2.6-3.6-5.9-3.4-9.3c0.2-3.7,2.1-7.3,5.2-10.1C53.1,11,58.4,9,64.2,9c7.3,0,15.2,3.1,22,8.7 c5.6,4.6,9.4,9.4,11.4,14.3c2.8,7,0.7,11.7,0.4,12.2C97.6,45.3,96.5,46,95.4,46z M64.8,33.9c7,0,20.6,0.4,28.1,3.4 c-0.1-0.9-0.4-1.9-0.9-3.1c-1.5-3.9-4.8-7.9-9.6-11.9c-5.8-4.8-12.2-7.4-18.2-7.4c-4.4,0-8.3,1.4-11.4,4.1c-1.9,1.7-3.1,3.8-3.2,5.9 c-0.1,1.8,0.5,3.5,1.9,4.9c2.4,2.6,6.7,4,12.1,4L64.8,33.9z"
    />
    <path
      fill="#fff"
      d="M64.2,102.8c-10.9,0.1-15-1.5-15.8-2.2l-3.7-24.1h0.4l-0.6-2.1c0.5-0.2,1.4-0.6,3-1l2.1-0.5l-1.1-8.1 h0.4l-0.5-1.9c1.9-0.8,7.2-1.9,15.5-2c8.3,0,13.6,1,15.5,1.8L79,64.5h0.5l-1,8.1l2.1,0.5c1.6,0.4,2.5,0.7,3,1L83,76.2h0.4L80,100.4 C79.2,101.1,75.1,102.7,64.2,102.8z"
    />
    <path
      fill="#454b54"
      d="M63.6 105.8c-8.9 0-14.9-1.1-17.1-3-.5-.5-.9-1.1-1-1.8l-3.9-26.2c-.2-1.3.5-2.5 1.6-3.1.8-.4 1.9-.8 3.2-1.1l-.9-7.4c-.2-1.3.6-2.6 1.8-3.1 2.3-1 8-2.1 16.7-2.2h.6c8.3 0 13.9 1.1 16.1 2 1.2.5 2 1.8 1.8 3.1l-.8 7.4c1.3.3 2.4.7 3.2 1.1 1.2.6 1.8 1.8 1.6 3.1L83 100.8c-.1.7-.4 1.4-1 1.8-2.2 2-8.7 3.1-17.8 3.2H63.6zM51.2 98.6c1.7.5 5.5 1.2 12.4 1.2h.6c7.3 0 11.3-.8 13-1.4l3-22.2c-.1 0-.3-.1-.4-.1l-2.1-.5c-1.5-.3-2.5-1.7-2.3-3.2l.8-7.6c-2.4-.5-6.4-1-11.7-1H64c-5.9 0-9.9.6-12.3 1.1l.9 7.5c.2 1.5-.8 2.9-2.3 3.3l-2.1.5c-.1 0-.3.1-.4.1L51.2 98.6zM63.3 119.1c-8.2 0-16-.6-21.9-1.7-5.4-1-11.7-2.8-11.7-7.3 0-1.7 1.3-3 3-3l0 0c1.4 0 2.6.9 2.9 2.2 1.9 1.4 11.9 3.9 28.6 3.8s26.7-2.7 28.6-4.1c.3-1.3 1.5-2.3 2.9-2.3l0 0c1.6 0 3 1.3 3 3 0 4.5-6.2 6.3-11.6 7.4-6.1 1.2-14.3 2-22.9 2C64 119.1 63.7 119.1 63.3 119.1zM62.9 52.4c-10.1 0-33.5-.9-33.6-9 0-4.5 6.2-6.3 11.6-7.4 6.1-1.3 14.3-2 22.9-2h1c10.1 0 33.5.9 33.6 9 0 4.5-6.2 6.3-11.6 7.4-6.1 1.2-14.3 2-22.9 2H62.9zM36.7 43.3c3.6 1.4 12.5 3.1 26.2 3.1h1C78 46.3 87.3 44.5 91 43c-3.6-1.4-12.5-3.1-26.2-3.1h-1C49.7 40 40.4 41.9 36.7 43.3z"
    />
    <path
      fill="#454b54"
      d="M63.4,119c-7.8,0-15.2-0.6-20.8-1.7c-5.2-1-11.2-2.7-11.2-7.1L31,54.1c0-1.7,1.3-3,3-3l0,0 c1.6,0,3,1.3,3,3l0.3,55.5c2.1,1.4,11.3,3.6,27,3.5c15.6-0.1,24.8-2.4,26.9-3.8l-0.3-55.5c0-1.7,1.3-3,3-3l0,0c1.6,0,3,1.3,3,3 l0.3,56.2c0,8-23,9.1-32.9,9.1C64,119,63.7,119,63.4,119z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M26.3,11c-0.4,0-0.7,0.2-1,0.6L22.1,16c-0.4,0.3-0.6,0.7-0.6,1.1c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
	c0.3,0,0.5-0.2,0.8-0.5l1.6-2.5V38c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.4-0.4,0.4-0.7
	V12c0-0.3-0.1-0.5-0.4-0.7S26.6,11,26.3,11z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M19.6,11c-0.4,0-0.7,0.2-1,0.6c0,0-3.5,4.8-3.6,5c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
	s0.5-0.2,0.7-0.5l1.5-2.5V38c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.4-0.4,0.4-0.7V12
	c0-0.3-0.1-0.5-0.3-0.7C20.1,11.1,19.9,11,19.6,11z"
    />
    <path
      d="M32.6,11c-0.4,0-0.7,0.2-1,0.6c0,0-3.5,4.8-3.6,5c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
	s0.5-0.2,0.7-0.5l1.5-2.5V38c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.4-0.4,0.4-0.7V12
	c0-0.3-0.1-0.5-0.3-0.7C33.1,11.1,32.9,11,32.6,11z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <clipPath id="a">
        <rect width="48" height="48" transform="translate(410 104)" />
      </clipPath>
    </defs>
    <g transform="translate(-410 -104)" clipPath="url(#a)">
      <path d="M38,2.38a4.342,4.342,0,0,1-.955,1.465Q26,16.463,14.954,29.089a2.044,2.044,0,0,1-2.756.618,2.723,2.723,0,0,1-.585-.509C7.957,25.3,4.313,21.39.64,17.508a2.016,2.016,0,0,1,.87-3.437,1.987,1.987,0,0,1,2.012.645q1.342,1.438,2.691,2.87Q9.6,21.2,12.982,24.823c.256.276.33.065.456-.079Q19,18.391,24.555,12.037L34.4.783a1.961,1.961,0,0,1,2.123-.7,1.879,1.879,0,0,1,1.428,1.579c0,.019.031.034.047.051Z" transform="translate(415 113.001)" />
    </g>
  </Svg>
);

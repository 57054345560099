import React from 'react';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';
import NavigationMobile from '~/components/common/NavigationMobile';
import Navigation from '~/components/common/Navigation';
import { Craft_MenuItemsMenuItemSub } from '~/query';

type CraftGlobalNavigationProps = {
  mobile?: boolean;
};

export default function CraftGlobalNavigation({
  mobile,
}: CraftGlobalNavigationProps) {
  const { globalNavigation } = useCraftGlobals();

  const navItems = (globalNavigation?.menuItems ?? [])
    .map(item => {
      if (!item) return {};
      return {
        id: item.id ?? undefined,
        link: {
          url: item.menuItemLink?.url ?? undefined,
          text: item.menuItemLink?.text ?? undefined,
          target: item.menuItemLink?.target ?? undefined,
        },
        appearance: item.menuItemAppearance ?? undefined,
        children: (item.children ?? [])
          .map((childItem: any) => {
            if (!childItem) return {};
            return {
              id: childItem.id ?? undefined,
              link: {
                url: childItem.menuItemLink?.url ?? undefined,
                text: childItem.menuItemLink?.text ?? undefined,
                target: childItem.menuItemLink?.target ?? undefined,
              },
              appearance: childItem.menuItemAppearance ?? undefined,
              description:
                (childItem as Craft_MenuItemsMenuItemSub).menuItemDescription ??
                undefined,
            };
          })
          .filter(_ => _ && _.id),
      };
    })
    .filter(_ => _ && _.id);

  if (mobile) {
    return <NavigationMobile navItems={navItems} />;
  }

  return <Navigation navItems={navItems} />;
}

import { Text } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';

export const TextStyled = styled(Text)(({ theme }) => ({
  color: 'inherit',
  marginTop: theme.space[1],
  marginBottom: theme.space[1],

  [media('lg')]: {
    marginBottom: theme.space[2],
  },
}));

import { useState, useCallback } from 'react';

type SetTrue = () => void;
type SetFalse = () => void;

export function useSwitch(inital = false) {
  const [value, setValue] = useState(inital);
  const setTrue = useCallback(() => {
    setValue(true);
  }, []);
  const setFalse = useCallback(() => {
    setValue(false);
  }, []);
  return [value, setTrue, setFalse] as [boolean, SetTrue, SetFalse];
}

import styled from '~/utils/styled';

import media from '~/utils/mediaqueries';

export const TrustBadgeStyled = styled('div')({
  '&:empty': {
    display: 'none',
  },

  [media('xl')]: {
    position: 'relative',
    width: 140,
    height: 70,
    overflow: 'hidden',
    marginLeft: 'auto',
    backgroundColor: '#ffffff',
    borderRadius: 3,
    // boxShadow: '0 3px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out',
  },

  '& > div > div': {
    border: '0 !important',
  },
});

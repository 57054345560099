import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { globalHistory } from '@reach/router';
import { ApolloProvider } from 'react-apollo';
import scrollToElement from 'scroll-to-element';
import { disableStatisticsTracking, sendPageView } from '~/utils/tracking';
import client from './apollo-client';

export const onClientEntry = () => {
  // Polyfill scrollTo
  smoothscroll.polyfill();
};

function updateScrollPos(hash) {
  if (hash) {
    scrollToElement(hash, { offset: -165 });
  }
}

export const onInitialClientRender = () => {
  // get initial scroll pos right
  updateScrollPos(window.location && window.location.hash);

  if (window) {
    // Make tracking deactivation globally available.
    window.disableStatisticsTracking = disableStatisticsTracking;

    // Make trust badge config globally available.
    window._tsConfig = {
      yOffset: '0',
      variant: 'custom_reviews',
      customElementId: 'trusted-badge',
      customBadgeHeight: 70,
      trustcardDirection: 'topLeft',
      responsive: {
        'variant': 'floating_reviews',
        'customElementId': 'trustbadge-mobile',
      },
    };
  }
};

globalHistory.listen(({ location, location: { hash } }) => {
  sendPageView(location);
  // update scroll pos accoring to hash
  updateScrollPos(hash);
});

export const shouldUpdateScroll = () => true;

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);

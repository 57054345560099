import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M16.9,11c-0.4,0-0.7,0.2-1,0.6c0,0-3.5,4.8-3.6,5c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
	s0.5-0.2,0.7-0.5l1.5-2.5V38c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.4-0.4,0.4-0.7V12
	c0-0.3-0.1-0.5-0.3-0.7C17.4,11.1,17.1,11,16.9,11z"
    />
    <path
      d="M30,11c-2,0-3.8,0.6-5.1,1.8c-1.4,1.2-2.1,2.7-2.1,4.6c0,1,0.4,1.6,1.1,1.6c0.4,0,0.7-0.1,1-0.3s0.4-0.4,0.4-0.7
	c0-1.5,0.4-2.6,1.3-3.4c0.9-0.8,2-1.2,3.4-1.2c1.6,0,2.9,0.5,3.8,1.5c0.9,1,1.4,2.2,1.4,3.5c0,1.2-0.4,2.4-1.3,3.6
	c-0.9,1.2-2,2.2-3.3,3.1l-3.8,2.7c-1.3,0.9-2.3,1.9-3.2,3s-1.3,2.2-1.3,3.4V38c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.5,0.3,0.8,0.3h13.6
	c0.3,0,0.5-0.1,0.7-0.4c0.2-0.2,0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.7-0.3H24.7v-2.4c0-0.8,0.3-1.6,1-2.4
	c0.6-0.8,1.4-1.5,2.4-2.2l3.1-2.2c1.1-0.8,2.2-1.7,3.1-2.5c0.9-0.8,1.7-1.8,2.4-3c0.6-1.2,1-2.4,1-3.7c0-2.1-0.7-3.8-2.2-5.2
	C34,11.7,32.2,11,30,11z"
    />
  </Svg>
);

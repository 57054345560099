import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#f79c34"
      fill-rule="evenodd"
      d="M51.1,70.6C46.6,74,40,75.7,34.4,75.7A30.08,30.08,0,0,1,14,67.9c-.4-.4,0-.9.5-.6a40.83,40.83,0,0,0,20.4,5.4,40,40,0,0,0,15.6-3.2C51.2,69.2,51.9,70.1,51.1,70.6Z"
    />
    <path
      fill="#f79c34"
      fill-rule="evenodd"
      d="M53,68.5c-.6-.7-3.8-.4-5.3-.2-.4.1-.5-.3-.1-.6,2.6-1.8,6.8-1.3,7.3-.7s-.1,4.9-2.6,6.9c-.4.3-.7.1-.6-.3C52.3,72.3,53.6,69.2,53,68.5Z"
    />
    <path
      fill="#333e47"
      fill-rule="evenodd"
      d="M47.8,54.8V53a.37.37,0,0,1,.4-.4h7.9a.46.46,0,0,1,.5.4v1.5c0,.3-.2.6-.6,1.1l-4.1,5.9a9,9,0,0,1,4.5,1,.75.75,0,0,1,.4.7v1.9c0,.3-.3.6-.6.4a9.21,9.21,0,0,0-8.4,0,.44.44,0,0,1-.6-.4V63.3a1.94,1.94,0,0,1,.3-1.2l4.8-6.8H48.2A.46.46,0,0,1,47.8,54.8Z"
    />
    <path
      fill="#333e47"
      fill-rule="evenodd"
      d="M18.9,65.9H16.5a.43.43,0,0,1-.4-.4V53.1a.46.46,0,0,1,.5-.4h2.2a.43.43,0,0,1,.4.4v1.6h0a3.18,3.18,0,0,1,3.2-2.3,3.06,3.06,0,0,1,3.1,2.3,3.51,3.51,0,0,1,6.1-.9c.8,1,.6,2.5.6,3.9v7.8a.46.46,0,0,1-.5.4H29.3a.43.43,0,0,1-.4-.4V58.9a17.7,17.7,0,0,0-.1-2.3,1.3,1.3,0,0,0-1.4-1.1,1.5,1.5,0,0,0-1.4,1,9.34,9.34,0,0,0-.2,2.4v6.6a.46.46,0,0,1-.5.4H23a.43.43,0,0,1-.4-.4V58.9c0-1.4.2-3.4-1.5-3.4s-1.7,2-1.7,3.4v6.6A.46.46,0,0,1,18.9,65.9Z"
    />
    <path
      fill="#333e47"
      fill-rule="evenodd"
      d="M63.5,54.9c-1.8,0-1.9,2.4-1.9,3.9s0,4.7,1.9,4.7,2-2.6,2-4.2a9.35,9.35,0,0,0-.4-3.3A1.71,1.71,0,0,0,63.5,54.9Zm-.1-2.5c3.6,0,5.5,3.1,5.5,7s-2.1,6.8-5.5,6.8S58,63.1,58,59.3,60,52.4,63.4,52.4Z"
    />
    <path
      fill="#333e47"
      fill-rule="evenodd"
      d="M73.6,65.9H71.2a.43.43,0,0,1-.4-.4V53.1a.46.46,0,0,1,.5-.4h2.2a.45.45,0,0,1,.4.3v1.9h0c.7-1.7,1.6-2.5,3.3-2.5A3.12,3.12,0,0,1,80,53.9a8.22,8.22,0,0,1,.6,3.8v7.8a.46.46,0,0,1-.5.4H77.7a.43.43,0,0,1-.4-.4V58.8c0-1.4.2-3.3-1.5-3.3a1.61,1.61,0,0,0-1.4,1,6.2,6.2,0,0,0-.4,2.3v6.7C74.1,65.7,73.8,65.9,73.6,65.9Z"
    />
    <path
      fill="#333e47"
      fill-rule="evenodd"
      d="M43.9,65.8a.66.66,0,0,1-.6.1,9.36,9.36,0,0,1-1.4-1.6,5,5,0,0,1-4,1.8,3.47,3.47,0,0,1-3.6-3.8,4,4,0,0,1,2.6-3.9,13.75,13.75,0,0,1,4.5-.8v-.3a3.06,3.06,0,0,0-.3-1.7,1.82,1.82,0,0,0-1.4-.6,1.84,1.84,0,0,0-1.9,1.4.43.43,0,0,1-.4.4l-2.3-.3a.46.46,0,0,1-.4-.5c.5-2.8,3-3.7,5.3-3.7h.2a5,5,0,0,1,3.5,1.2c1.2,1.1,1.1,2.6,1.1,4.1v3.8a3.44,3.44,0,0,0,.9,2.2c.2.2.2.5,0,.6a13.72,13.72,0,0,1-1.8,1.6ZM41.5,60a4.85,4.85,0,0,1-.5,2.6,2,2,0,0,1-1.7,1.1A1.53,1.53,0,0,1,37.8,62c0-2.1,1.8-2.4,3.6-2.4V60Z"
    />
    <path
      fill="#333e47"
      fill-rule="evenodd"
      d="M12.4,65.8a.47.47,0,0,1-.6.1,9.36,9.36,0,0,1-1.4-1.6,5,5,0,0,1-4,1.8,3.47,3.47,0,0,1-3.6-3.8,4,4,0,0,1,2.6-3.9,13.75,13.75,0,0,1,4.5-.8v-.3a3.06,3.06,0,0,0-.3-1.7A1.82,1.82,0,0,0,8.2,55a1.84,1.84,0,0,0-1.9,1.4.43.43,0,0,1-.4.4l-2.3-.3a.46.46,0,0,1-.4-.5c.5-2.8,3-3.7,5.3-3.7h.2a5,5,0,0,1,3.5,1.2c1.2,1.1,1.1,2.6,1.1,4.1v3.8a3.44,3.44,0,0,0,.9,2.2c.2.2.2.5,0,.6-.5.5-1.4,1.2-1.8,1.6ZM9.9,60a4.85,4.85,0,0,1-.5,2.6,2,2,0,0,1-1.7,1.1A1.53,1.53,0,0,1,6.2,62c0-2.1,1.8-2.4,3.6-2.4V60Z"
    />
    <path
      fill="#333e47"
      fill-rule="evenodd"
      d="M113.6,70.3v-.9c0-.3.1-.4.4-.4a4.85,4.85,0,0,0,1.7,0,1.89,1.89,0,0,0,1.4-1.2,11.92,11.92,0,0,0,.8-2.1l-5-12.4c-.1-.2-.1-.6.3-.6H115a.46.46,0,0,1,.5.4l3.6,10.1,3.5-10.1c.1-.2.2-.4.5-.4h1.7c.4,0,.4.4.3.6l-5,12.8c-.6,1.7-1.5,4.4-3.4,4.9a4.69,4.69,0,0,1-2.9-.1A.73.73,0,0,1,113.6,70.3Z"
    />
    <path
      fill="#333e47"
      fill-rule="evenodd"
      d="M111.2,65.1a.43.43,0,0,1-.4.4h-1.2c-.3,0-.4-.2-.5-.4l-.1-.8a8.17,8.17,0,0,1-2,1.2,4.78,4.78,0,0,1-4.5-.2,3.7,3.7,0,0,1-1.6-3.1,3.78,3.78,0,0,1,1-2.7,4.84,4.84,0,0,1,3.8-1.4,12.93,12.93,0,0,1,3.3.4V56.8c0-1.7-.7-2.5-2.6-2.5a11.79,11.79,0,0,0-4.1.7.43.43,0,0,1-.4-.4v-1c0-.2.2-.5.4-.5a11.16,11.16,0,0,1,4.4-.8c2.2,0,4.8.5,4.8,3.8v9Zm-2.3-2.4V60.1a14.77,14.77,0,0,0-2.7-.3,3.07,3.07,0,0,0-2.2.7,1.87,1.87,0,0,0-.5,1.4,1.78,1.78,0,0,0,.8,1.7,3.26,3.26,0,0,0,2.6.1A6.26,6.26,0,0,0,108.9,62.7Z"
    />
    <path
      fill="#333e47"
      fill-rule="evenodd"
      d="M93,54.3c2.7,0,3.5,2.2,3.5,4.6a5.49,5.49,0,0,1-1.2,4,3.19,3.19,0,0,1-2.4.8,6,6,0,0,1-3.2-1.2V55.4A7.18,7.18,0,0,1,93,54.3ZM89.3,70.8H87.6a.43.43,0,0,1-.4-.4V53.2a.43.43,0,0,1,.4-.4h1.3c.3,0,.4.2.5.4l.1.9a6.25,6.25,0,0,1,4.2-1.7c4.1,0,5.4,3.4,5.4,6.8,0,3.7-2,6.7-5.5,6.7a5.61,5.61,0,0,1-3.9-1.5v6A.43.43,0,0,1,89.3,70.8Z"
    />
  </Svg>
);

import React, { useContext, Fragment } from 'react';
import { Box, Flex } from 'rebass';
import NavigationMeta from '~/components/common/NavigationMeta';

import { InlineBox, MetaLink } from './MetaReduced.styles';

import { PageContext } from '../Layout';

type NavItemBase = {
  id?: number;
  link?: {
    url?: string;
    text?: string;
    target?: string;
  };
  appearance?: 'default' | 'highlight';
};

type NavItemSub = NavItemBase & {
  description?: string;
};

type NavItem = NavItemBase & {
  children?: NavItemSub[];
};

type MetaProps = {
  translations: {
    Mail: string;
    Phone: string;
  };
  invertPoweredByImage?: boolean;
  poweredByLink?: {
    url?: string;
    text?: string;
    target?: string;
  };
  poweredByImage?: {
    id?: number;
    url?: string;
  };
  mail?: string;
  phone?: string;
  businessHours?: string;
  metaNavItems?: NavItem[] | undefined;
};

export default function MetaReduced({
  translations,
  mail,
  phone,
  businessHours,
  metaNavItems,
}: MetaProps) {
  const gridProps = {
    mx: { _: 0, lg: '-11px' },
  };

  const gridItemProps = {
    px: { _: 0, lg: '11px' },
  };

  const pageContext = useContext(PageContext);

  return (
    <Flex
      {...gridProps}
      py={1}
      width="100%"
      justifyContent="space-between"
      flexDirection={{ _: 'column', lg: 'row' }}
      alignItems={{ _: 'flex-start', lg: 'center' }}
      flexWrap="wrap"
      fontSize={0}
    >
      {mail ? (
        <Box {...gridItemProps}>
          <InlineBox mr={1}>{translations.Mail}</InlineBox>
          <MetaLink to={`mailto:${mail}`}>
            <strong>{mail}</strong>
          </MetaLink>
        </Box>
      ) : null}
      {phone ? (
        <Box mr={2}>
          <InlineBox mr={1}>{translations.Phone}</InlineBox>
          <MetaLink to={`tel:${phone}`}>
            <strong>{phone}</strong>
          </MetaLink>
        </Box>
      ) : null}
      {businessHours ? <Box>{businessHours}</Box> : null}
      {metaNavItems ? (
        <NavigationMeta navItems={metaNavItems} hideFirstLink isVertical />
      ) : null}
    </Flex>
  );
}

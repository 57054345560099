import React, { useEffect, useState } from 'react';
import { Box } from 'rebass';
import Parser from '~/components/common/Parser';
import { ALLOWED_TAGS_BLOCK as allowedTags } from '~/components/common/Parser/parse';
import { Craft_RedactorFieldData } from '~/query';
import Headline from '~/components/common/Headline';
import supportedBrowsers from '~/config/supportedBrowsers'
import { useLocalStorage } from '~/utils/hooks/useLocalStorage'

import {
  Wrapper,
  Content
} from './BrowserSupport.styles'
import Button from '../Button';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';

export default function BrowserSupport({ headline, text }: any) {
  const [hideBrowserSupportOverlay, setHideBrowserSupportOverlay] = useLocalStorage('hideBrowserSupportOverlay','true');
  const t = useCraftTranslations();

  useEffect(() => {
    const supported:any = !(navigator.userAgent.indexOf("MSIE") >= 0); // We only block IE lower than version 11
    setHideBrowserSupportOverlay(supported)
  });

  if(!hideBrowserSupportOverlay || hideBrowserSupportOverlay === 'false') {
    // console.log('overlay should appear');
    return (
        <Wrapper>
        <Content>
          <Headline level="h1">{headline}</Headline>
          <Parser
            html={text?.content}
            allowedTags={allowedTags}
          />
          <Button onClick={()=>{setHideBrowserSupportOverlay('true')}}>{t("Go to website")}</Button>
        </Content>
      </Wrapper>
    )
  } else {
    return null
  }
}

BrowserSupport.defaultProps = {
  headline: "",
  text: null
};

type BrowserSupport = {
  headline: String;
  text: Craft_RedactorFieldData;
};


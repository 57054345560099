import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M25.2,11c-1.4,0-2.7,0.2-3.7,0.6c-1.1,0.4-1.8,1-2.3,1.7s-0.9,1.3-1.1,2c-0.2,0.6-0.3,1.3-0.3,1.9c0,0.6,0.1,0.9,0.3,1.1
	s0.5,0.3,1,0.3c0.8,0,1.2-0.4,1.2-1.2c0-1.1,0.4-2,1.1-2.8c0.7-0.8,2-1.2,3.8-1.2c1.6,0,2.8,0.4,3.5,1.2c0.7,0.8,1.1,2,1.1,3.7
	c0,1.8-0.4,3-1.2,3.8c-0.8,0.7-2,1.1-3.6,1.1c-0.6,0-1,0.4-1,1.1c0,0.7,0.3,1.1,1,1.1c1.7,0,3.1,0.4,4,1.3c1,0.8,1.4,2.1,1.4,3.8V31
	c0,3.8-1.8,5.7-5.5,5.7c-1.9,0-3.3-0.4-4.1-1.3c-0.9-0.9-1.3-1.8-1.3-2.9c0-0.7-0.4-1.1-1.3-1.1c-0.4,0-0.7,0.1-0.9,0.3
	c-0.2,0.2-0.3,0.5-0.3,1c0,1.6,0.7,3,2.1,4.3c1.4,1.3,3.3,1.9,5.9,1.9c2.4,0,4.4-0.6,5.8-1.9c1.5-1.3,2.2-3.3,2.2-6.1v-0.7
	c0-3.1-1.3-5.1-3.9-6.2c0.9-0.5,1.7-1.2,2.3-2.3c0.6-1.1,0.9-2.4,0.9-4.1C32.2,13.2,29.9,11,25.2,11z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M25,11c-4.8,0-7.2,2.2-7.2,6.6c0,3,1.1,5,3.4,6c-2.8,1.1-4.2,3.5-4.2,7.1v0.5c0,2.7,0.7,4.7,2.2,5.9
	c1.5,1.3,3.4,1.9,5.8,1.9c2.4,0,4.3-0.6,5.8-1.9c1.5-1.3,2.2-3.3,2.2-5.9v-0.5c0-3.7-1.4-6-4.2-7.1c2.3-0.9,3.4-2.9,3.4-6
	C32.2,13.2,29.8,11,25,11z M25,13.2c3.2,0,4.8,1.5,4.8,4.6c0,3.1-1.6,4.7-4.8,4.7c-3.2,0-4.7-1.6-4.7-4.7
	C20.3,14.7,21.8,13.2,25,13.2z M25,24.8c3.6,0,5.5,1.9,5.5,5.7V31c0,1.9-0.5,3.4-1.4,4.4s-2.3,1.5-4,1.5c-1.7,0-3.1-0.5-4.1-1.5
	c-1-1-1.4-2.4-1.4-4.4v-0.5C19.5,26.7,21.4,24.8,25,24.8z"
    />
  </Svg>
);

import { useEffect, EffectCallback } from 'react';

type ScriptMap = {
  [scriptSrc: string]: Promise<boolean | undefined>;
};

const loaded: ScriptMap = {};

export function loadExternalScript(src: string) {
  // src is URL of external file

  if (!loaded[src]) {
    loaded[src] = new Promise((resolve, reject) => {
      const scriptTag = document.createElement('script');
      scriptTag.onload = () => {
        resolve(true);
      };
      scriptTag.onerror = () => {
        reject();
      };
      scriptTag.src = src;
      document.head.appendChild(scriptTag);
    });
  }

  return loaded[src];
}

export function useExternalScriptEffect(
  src: string,
  effect: EffectCallback,
  deps?: readonly any[],
) {
  useEffect(() => {
    let didCancel = false;

    (async () => {
      await loadExternalScript(src);
      if (!didCancel) {
        effect();
      }
    })();

    return () => {
      didCancel = true;
    };
  }, deps);
}

import React from 'react';
import { Svg } from '../Logo.styles';

export default () => (
  <Svg
    viewBox="0 0 362.38 48.2"
    dimMobile={[362.38 * 0.6, 48.2 * 0.6]}
    dim={[362.38 * 0.75, 48.2 * 0.75]}
  >
    <path
      fill="#1d2e53"
      d="M5.33,22.24.09,9.6A1.06,1.06,0,0,1,.63,8.19,1.06,1.06,0,0,1,2,8.8L6.87,20.73,11.73,8.8a1,1,0,1,1,1.92.8L8.41,22.24a1.6,1.6,0,0,1-1.48,1.15H6.81A1.6,1.6,0,0,1,5.33,22.24Z"
    />
    <path
      fill="#1d2e53"
      d="M22,23.55c-4.25,0-7.55-3.1-7.55-7.84A7.33,7.33,0,0,1,22,8c4.26,0,7.33,3,7.33,7.74a1,1,0,0,1-1,1H16.6A5.24,5.24,0,0,0,22,21.44a5.19,5.19,0,0,0,4.35-2.05A1.09,1.09,0,0,1,27.8,19a1,1,0,0,1,.32,1.47A7.36,7.36,0,0,1,22,23.55Zm5.15-8.83A5,5,0,0,0,22,10.08a5.22,5.22,0,0,0-5.37,4.64Z"
    />
    <path
      fill="#1d2e53"
      d="M37.94,10.11c-3,0-4.29,3.59-4.29,7.55V22.3a1.06,1.06,0,1,1-2.11,0V9.22a1.06,1.06,0,1,1,2.11,0v2.46C34.39,9.12,35.77,8,37.94,8a5.35,5.35,0,0,1,1.82.29,1,1,0,0,1,.68,1.34,1.15,1.15,0,0,1-1.38.64A9.43,9.43,0,0,0,37.94,10.11Z"
    />
    <path
      fill="#1d2e53"
      d="M44,29.53a1.06,1.06,0,1,1-2.11,0V9.22a1.06,1.06,0,1,1,2.11,0v1.66A6.6,6.6,0,0,1,49.62,8a7.5,7.5,0,0,1,7.65,7.87,7.38,7.38,0,0,1-7.65,7.71A6.46,6.46,0,0,1,44,20.8Zm5.63-19.42A5.41,5.41,0,0,0,44,15.84a5.47,5.47,0,0,0,5.63,5.57,5.29,5.29,0,0,0,5.5-5.57A5.48,5.48,0,0,0,49.62,10.11Z"
    />
    <path
      fill="#1d2e53"
      d="M74.2,22.3a1.06,1.06,0,1,1-2.12,0V20.67a6.56,6.56,0,0,1-5.63,2.88,7.48,7.48,0,0,1-7.64-7.84A7.4,7.4,0,0,1,66.45,8a6.48,6.48,0,0,1,5.63,2.78V9.22a1.06,1.06,0,1,1,2.12,0Zm-7.75-.89a5.39,5.39,0,0,0,5.63-5.7,5.5,5.5,0,0,0-5.63-5.6,5.32,5.32,0,0,0-5.5,5.6A5.45,5.45,0,0,0,66.45,21.41Z"
    />
    <path
      fill="#1d2e53"
      d="M84.5,21.44a6.46,6.46,0,0,0,3.36-1,1.1,1.1,0,0,1,1.5.28A1,1,0,0,1,89,22.17a8,8,0,0,1-4.54,1.38,7.58,7.58,0,0,1-7.81-7.81A7.55,7.55,0,0,1,84.5,8,8.11,8.11,0,0,1,89,9.34a1,1,0,0,1,.32,1.48,1.1,1.1,0,0,1-1.5.28,6.46,6.46,0,0,0-3.36-1,5.51,5.51,0,0,0-5.7,5.66A5.54,5.54,0,0,0,84.5,21.44Z"
    />
    <path
      fill="#1d2e53"
      d="M103.06,21.57a1.06,1.06,0,0,1,0,1.5,1,1,0,0,1-1.51-.06L94,15.07V22.3a1.06,1.06,0,1,1-2.11,0V1.12a1.06,1.06,0,1,1,2.11,0V14.21l5.8-5.76a1,1,0,0,1,1.5-.16,1,1,0,0,1,.13,1.5l-4.9,4.9Z"
    />
    <path
      fill="#1d2e53"
      d="M115.51,9.22a1.06,1.06,0,1,1,2.11,0V22.3a1.06,1.06,0,1,1-2.11,0V20.77a5.54,5.54,0,0,1-5,2.78c-3.65,0-5.79-2.5-5.76-6.56V9.22a1.06,1.06,0,1,1,2.11,0V17c0,2.75,1.34,4.45,3.65,4.45a4.66,4.66,0,0,0,5-5Z"
    />
    <path
      fill="#1d2e53"
      d="M123.22,22.3a1.06,1.06,0,1,1-2.11,0V9.22a1.06,1.06,0,1,1,2.11,0v1.53a5.54,5.54,0,0,1,5-2.78c3.65,0,5.79,2.49,5.76,6.56V22.3a1.06,1.06,0,1,1-2.11,0V14.53c0-2.75-1.35-4.45-3.65-4.45a4.65,4.65,0,0,0-5,5Z"
    />
    <path
      fill="#1d2e53"
      d="M138.16,26.24a1.07,1.07,0,0,1,1.48.35,5.15,5.15,0,0,0,4.09,1.92c3.46,0,5.89-2,5.86-8.06a6.47,6.47,0,0,1-5.63,3.1,7.4,7.4,0,0,1-7.65-7.74A7.48,7.48,0,0,1,144,8a6.57,6.57,0,0,1,5.63,2.88V9.22a1.06,1.06,0,1,1,2.11,0V20.8c0,7.33-3.26,9.82-8,9.82a6.94,6.94,0,0,1-5.89-2.91A1,1,0,0,1,138.16,26.24Zm5.8-4.83a5.5,5.5,0,0,0,5.63-5.6,5.39,5.39,0,0,0-5.63-5.7,5.46,5.46,0,0,0-5.51,5.7A5.32,5.32,0,0,0,144,21.41Z"
    />
    <path
      fill="#1d2e53"
      d="M154.9,18.81a1.08,1.08,0,0,1,1.22.8c.38,1,1.24,1.92,3.29,1.92s3.17-1,3.17-2.36c0-1-.58-1.92-2.14-2.31l-2.08-.48c-1.86-.45-4.16-1.37-3.78-4.86C154.9,9.38,157,8,159.44,8a4.42,4.42,0,0,1,4.42,2.69,1,1,0,0,1-.7,1.31c-.71.19-1-.23-1.32-.74a2.41,2.41,0,0,0-2.4-1.21,2.59,2.59,0,0,0-2.78,1.82c-.32,1.73,1.15,2.24,2.21,2.49l2,.49c2.81.67,3.77,2.3,3.77,4.35,0,2.59-2.37,4.38-5.47,4.38a5.16,5.16,0,0,1-5-3.49A1,1,0,0,1,154.9,18.81Z"
    />
    <path
      fill="#ee741b"
      d="M170.07,21.73a1.68,1.68,0,0,1-1.7,1.66,1.64,1.64,0,0,1-1.6-1.66V1.66A1.64,1.64,0,0,1,168.37,0a1.68,1.68,0,0,1,1.7,1.66Z"
    />
    <path
      fill="#ee741b"
      d="M174.58.16a2.1,2.1,0,0,1,2.18,2.08,2.1,2.1,0,0,1-2.18,2.08,2.15,2.15,0,0,1-2.21-2.08A2.15,2.15,0,0,1,174.58.16Zm1.66,9.63a1.68,1.68,0,0,0-1.69-1.66A1.64,1.64,0,0,0,173,9.79V21.73a1.64,1.64,0,0,0,1.6,1.66,1.68,1.68,0,0,0,1.69-1.66Z"
    />
    <path
      fill="#ee741b"
      d="M186.64,11.39h-6.52a1.55,1.55,0,0,1-1.57-1.5,1.59,1.59,0,0,1,1.57-1.6h9.69a1.64,1.64,0,0,1,1.79,1.5v.07a2.39,2.39,0,0,1-.77,1.72l-8.06,8.55h7.46a1.6,1.6,0,0,1,1.57,1.6,1.56,1.56,0,0,1-1.57,1.5h-10.5a1.64,1.64,0,0,1-1.79-1.5v-.07a2.47,2.47,0,0,1,.77-1.73Z"
    />
    <path
      fill="#ee741b"
      d="M206.26,16.93h-10.4a4.34,4.34,0,0,0,4.58,3.74A5,5,0,0,0,204,19.23a1.68,1.68,0,0,1,2.11-.13,1.43,1.43,0,0,1,.06,2.08,8.12,8.12,0,0,1-5.88,2.37,7.5,7.5,0,0,1-7.68-7.84A7.42,7.42,0,0,1,200.28,8a7.25,7.25,0,0,1,7.48,7.45A1.5,1.5,0,0,1,206.26,16.93Zm-1.73-2.4a4.06,4.06,0,0,0-4.25-3.68,4.19,4.19,0,0,0-4.42,3.68Z"
    />
    <path
      fill="#ee741b"
      d="M209.43,9.79a1.68,1.68,0,0,1,1.69-1.66,1.64,1.64,0,0,1,1.6,1.66v.74A5.18,5.18,0,0,1,217.3,8c3.61,0,5.69,2.33,5.69,6.56v7.2a1.68,1.68,0,0,1-1.69,1.66,1.64,1.64,0,0,1-1.6-1.66V14.91c0-2.37-1.22-3.71-3.14-3.71s-3.84,1.05-3.84,4v6.53A1.68,1.68,0,0,1,211,23.39a1.64,1.64,0,0,1-1.6-1.66Z"
    />
    <path
      fill="#ee741b"
      d="M233.27,11.39h-6.53a1.56,1.56,0,0,1-1.57-1.5,1.6,1.6,0,0,1,1.57-1.6h9.69a1.65,1.65,0,0,1,1.8,1.5v.07a2.43,2.43,0,0,1-.77,1.72l-8.07,8.55h7.46a1.6,1.6,0,0,1,1.57,1.6,1.56,1.56,0,0,1-1.57,1.5h-10.5a1.64,1.64,0,0,1-1.79-1.5v-.07a2.47,2.47,0,0,1,.77-1.73Z"
    />
    <path
      fill="#ee741b"
      d="M241.94.16a2.1,2.1,0,0,1,2.17,2.08,2.1,2.1,0,0,1-2.17,2.08,2.15,2.15,0,0,1-2.21-2.08A2.15,2.15,0,0,1,241.94.16Zm1.66,9.63a1.68,1.68,0,0,0-1.69-1.66,1.64,1.64,0,0,0-1.6,1.66V21.73a1.64,1.64,0,0,0,1.6,1.66,1.68,1.68,0,0,0,1.69-1.66Z"
    />
    <path
      fill="#ee741b"
      d="M259.25,16.93h-10.4a4.34,4.34,0,0,0,4.58,3.74A5,5,0,0,0,257,19.23a1.68,1.68,0,0,1,2.11-.13,1.43,1.43,0,0,1,.06,2.08,8.12,8.12,0,0,1-5.88,2.37,7.5,7.5,0,0,1-7.68-7.84A7.42,7.42,0,0,1,253.27,8a7.24,7.24,0,0,1,7.48,7.45A1.5,1.5,0,0,1,259.25,16.93Zm-1.73-2.4a4,4,0,0,0-4.25-3.68,4.19,4.19,0,0,0-4.42,3.68Z"
    />
    <path
      fill="#ee741b"
      d="M265.71,17.85v3.88A1.68,1.68,0,0,1,264,23.39a1.64,1.64,0,0,1-1.6-1.66V9.79A1.64,1.64,0,0,1,264,8.13a1.68,1.68,0,0,1,1.69,1.66v1.92C266.48,9.06,268,8,269.81,8a6.55,6.55,0,0,1,1.41.13A1.57,1.57,0,0,1,272.4,10c-.29,1.44-1.73,1.09-2.69,1.09C266.9,11.07,265.71,13.89,265.71,17.85Z"
    />
    <path
      fill="#ee741b"
      d="M286.77,21.73a1.68,1.68,0,0,1-1.7,1.66,1.64,1.64,0,0,1-1.6-1.66V21a5.17,5.17,0,0,1-4.57,2.56c-3.62,0-5.7-2.34-5.7-6.56V9.79a1.68,1.68,0,0,1,1.7-1.66,1.64,1.64,0,0,1,1.6,1.66v6.82c0,2.36,1.21,3.71,3.13,3.71s3.84-1.06,3.84-4V9.79a1.68,1.68,0,0,1,1.7-1.66,1.64,1.64,0,0,1,1.6,1.66Z"
    />
    <path
      fill="#ee741b"
      d="M289.59,9.79a1.68,1.68,0,0,1,1.69-1.66,1.64,1.64,0,0,1,1.6,1.66v.74A5.17,5.17,0,0,1,297.46,8c3.61,0,5.69,2.33,5.69,6.56v7.2a1.68,1.68,0,0,1-1.69,1.66,1.64,1.64,0,0,1-1.6-1.66V14.91c0-2.37-1.22-3.71-3.14-3.71s-3.84,1.05-3.84,4v6.53a1.68,1.68,0,0,1-1.7,1.66,1.64,1.64,0,0,1-1.59-1.66Z"
    />
    <path
      fill="#ee741b"
      d="M312.66,27.74c3,0,5-1.69,5-5.89V20.77c-.8,1.82-3,2.78-5.06,2.78A7.45,7.45,0,0,1,305,15.81,7.51,7.51,0,0,1,312.59,8a5.82,5.82,0,0,1,5.06,2.53V9.79a1.64,1.64,0,0,1,1.6-1.66,1.68,1.68,0,0,1,1.69,1.66l0,11.36c0,6.88-3.23,9.66-8.25,9.66a7.63,7.63,0,0,1-6.05-2.88,1.52,1.52,0,0,1,.25-2.14,1.65,1.65,0,0,1,2.18.32A5.09,5.09,0,0,0,312.66,27.74ZM313,11.17a4.48,4.48,0,0,0-4.55,4.61,4.42,4.42,0,0,0,4.55,4.6,4.55,4.55,0,0,0,4.6-4.6A4.39,4.39,0,0,0,313,11.17Z"
    />
    <path
      fill="#ee741b"
      d="M325.71,19.23A2.2,2.2,0,0,1,328,21.41a2.26,2.26,0,0,1-4.51,0A2.2,2.2,0,0,1,325.71,19.23Z"
    />
    <path
      fill="#ee741b"
      d="M341.87,1.66A1.68,1.68,0,0,1,343.57,0a1.64,1.64,0,0,1,1.6,1.66V21.73a1.64,1.64,0,0,1-1.6,1.66,1.68,1.68,0,0,1-1.7-1.66v-.68a5.77,5.77,0,0,1-5,2.5,7.45,7.45,0,0,1-7.49-7.77c0-4.8,3.4-7.81,7.46-7.81a5.62,5.62,0,0,1,5,2.46Zm-4.61,9.51a4.38,4.38,0,0,0-4.51,4.54,4.55,4.55,0,1,0,9.09,0A4.46,4.46,0,0,0,337.26,11.17Z"
    />
    <path
      fill="#ee741b"
      d="M360.88,16.93h-10.4a4.34,4.34,0,0,0,4.57,3.74,5,5,0,0,0,3.56-1.44,1.68,1.68,0,0,1,2.11-.13,1.43,1.43,0,0,1,.06,2.08,8.12,8.12,0,0,1-5.88,2.37,7.5,7.5,0,0,1-7.68-7.84A7.42,7.42,0,0,1,354.9,8a7.25,7.25,0,0,1,7.48,7.45A1.5,1.5,0,0,1,360.88,16.93Zm-1.73-2.4a4.06,4.06,0,0,0-4.25-3.68,4.19,4.19,0,0,0-4.42,3.68Z"
    />
    <path
      fill="#9c9b9b"
      d="M123.64,47.91V45.65l5.06-6h-4.79V37.26h8.22v2.26l-5.06,6h5.26v2.42Z"
    />
    <path
      fill="#9c9b9b"
      d="M134.86,37.26h2.83v1.18a3.67,3.67,0,0,1,3-1.41,3.93,3.93,0,0,1,1.9.49A3.74,3.74,0,0,1,144,38.87a4.31,4.31,0,0,1,1.52-1.35,4,4,0,0,1,2-.5,4,4,0,0,1,3,1.2,4.3,4.3,0,0,1,1.16,3.1v6.59h-2.83v-6.3a2.19,2.19,0,0,0-.53-1.49,1.68,1.68,0,0,0-1.3-.6,2.75,2.75,0,0,0-2.36,1.53c0,.3,0,.54,0,.7v6.16h-2.83v-6.3a2.12,2.12,0,0,0-.54-1.5,1.68,1.68,0,0,0-1.29-.59,2.74,2.74,0,0,0-2.35,1.5v6.89h-2.83Z"
    />
    <path
      fill="#9c9b9b"
      d="M159,37a4.62,4.62,0,0,1,3.38,1.31V37.26h2.75V47.91h-2.75V46.85A4.62,4.62,0,0,1,159,48.16a5,5,0,0,1-2.73-.75,5.27,5.27,0,0,1-1.89-2,6,6,0,0,1,0-5.58A5.13,5.13,0,0,1,159,37Zm.6,8.65a3.08,3.08,0,0,0,1.66-.46A2.91,2.91,0,0,0,162.42,44V41.21A2.91,2.91,0,0,0,161.3,40a3.05,3.05,0,0,0-1.66-.46,3,3,0,0,0-2.19.88,3,3,0,0,0-.89,2.2,3,3,0,0,0,3.08,3.08Z"
    />
    <path
      fill="#9c9b9b"
      d="M173.35,39.51a2.24,2.24,0,0,0-1.3.46A3.59,3.59,0,0,0,171,41.23v6.68h-2.83V37.26H171v1.3A3.4,3.4,0,0,1,173.82,37a2,2,0,0,1,1,.22l-.2,2.48A3.71,3.71,0,0,0,173.35,39.51Z"
    />
    <path
      fill="#9c9b9b"
      d="M180.39,44.2a1.3,1.3,0,0,0,1.46,1.47,9.68,9.68,0,0,0,1.17-.05V48a7.46,7.46,0,0,1-1.47.15q-4,0-4-3.75V39.75h-1.65V37.26h1.65V34.42l2.83-.25v3.09h2.5v2.49h-2.5Z"
    />
    <path
      fill="#9c9b9b"
      d="M0,43.75a4.28,4.28,0,0,1,1.27-3.13,4.52,4.52,0,0,1,3.35-1.28,4.1,4.1,0,0,1,2.08.57,4.11,4.11,0,0,1,1.54,1.53,4.23,4.23,0,0,1,.55,2.11,3.08,3.08,0,0,1-.08.68H1.53a2.8,2.8,0,0,0,1,1.92,3,3,0,0,0,2.06.76,3.47,3.47,0,0,0,2.67-1.23l1,1A4.69,4.69,0,0,1,4.6,48.2a4.84,4.84,0,0,1-1.88-.36A4.23,4.23,0,0,1,.34,45.48,4.55,4.55,0,0,1,0,43.75ZM6.3,41.28a2.66,2.66,0,0,0-1.81-.66,2.86,2.86,0,0,0-1.86.66,2.93,2.93,0,0,0-1,1.67H7.23A2.67,2.67,0,0,0,6.3,41.28Z"
    />
    <path
      fill="#9c9b9b"
      d="M10.83,37.87a1,1,0,0,1-.29-.7.91.91,0,0,1,.29-.69,1,1,0,0,1,1.68.69,1,1,0,0,1-.28.7.94.94,0,0,1-.7.28A1,1,0,0,1,10.83,37.87ZM10.78,48V39.54h1.49V48Z"
    />
    <path
      fill="#9c9b9b"
      d="M16.33,48H14.85V39.54h1.48v1.3a4,4,0,0,1,1.21-1.08,3,3,0,0,1,1.54-.42,3.31,3.31,0,0,1,2.45,1,3.39,3.39,0,0,1,.95,2.5V48H21V42.88a2.11,2.11,0,0,0-.62-1.58,2,2,0,0,0-1.46-.62,2.39,2.39,0,0,0-1.5.49,4.25,4.25,0,0,0-1.09,1.22Z"
    />
    <path
      fill="#9c9b9b"
      d="M35.67,45A1.76,1.76,0,0,0,35,43.6a5,5,0,0,0-2.23-.89,5.13,5.13,0,0,1-2.49-1.17,2.87,2.87,0,0,1-.88-2.15,2.75,2.75,0,0,1,1-2.17A3.87,3.87,0,0,1,33,36.37a4.85,4.85,0,0,1,3.57,1.43l-.89,1.11a3.79,3.79,0,0,0-2.61-1.21,2.42,2.42,0,0,0-1.55.46A1.43,1.43,0,0,0,31,39.37c0,1,.87,1.69,2.62,2.09a5.6,5.6,0,0,1,2.76,1.3A3.08,3.08,0,0,1,37.2,45a2.79,2.79,0,0,1-1.09,2.28,4.51,4.51,0,0,1-2.91.9A5.42,5.42,0,0,1,31,47.7a4.85,4.85,0,0,1-1.78-1.32l1-1.05a4.05,4.05,0,0,0,3.13,1.53A2.84,2.84,0,0,0,35,46.35,1.61,1.61,0,0,0,35.67,45Z"
    />
    <path
      fill="#9c9b9b"
      d="M38.56,43.75a4.28,4.28,0,0,1,1.27-3.13,4.51,4.51,0,0,1,3.34-1.28,4.25,4.25,0,0,1,3.63,2.1,4.23,4.23,0,0,1,.55,2.11,3.08,3.08,0,0,1-.08.68h-7.2a2.85,2.85,0,0,0,1,1.92,3,3,0,0,0,2.06.76,3.47,3.47,0,0,0,2.67-1.23l1,1a4.67,4.67,0,0,1-3.65,1.57,4.81,4.81,0,0,1-1.88-.36,4.25,4.25,0,0,1-1.45-1,4.18,4.18,0,0,1-.93-1.41A4.55,4.55,0,0,1,38.56,43.75Zm6.29-2.47A2.68,2.68,0,0,0,43,40.62a2.91,2.91,0,0,0-1.87.66,3,3,0,0,0-1,1.67h5.65A2.72,2.72,0,0,0,44.85,41.28Z"
    />
    <path
      fill="#9c9b9b"
      d="M53.05,40.68a2.06,2.06,0,0,0-1.33.52,4.61,4.61,0,0,0-1.1,1.37V48H49.14V39.54h1.48v1.39a3.3,3.3,0,0,1,2.63-1.59,1.8,1.8,0,0,1,.79.17l-.12,1.33A2.43,2.43,0,0,0,53.05,40.68Z"
    />
    <path
      fill="#9c9b9b"
      d="M62.73,39.54,59.45,48H57.91l-3.28-8.46h1.55l2.5,6.51,2.5-6.51Z"
    />
    <path
      fill="#9c9b9b"
      d="M64.39,37.87a.93.93,0,0,1-.29-.7.88.88,0,0,1,.29-.69,1,1,0,0,1,1.38,0,.91.91,0,0,1,.29.69,1,1,0,0,1-.28.7.92.92,0,0,1-.69.28A.94.94,0,0,1,64.39,37.87Zm0,10.13V39.54h1.48V48Z"
    />
    <path
      fill="#9c9b9b"
      d="M72.36,48.2A4.63,4.63,0,0,1,70,47.6,4.25,4.25,0,0,1,68.4,46a4.33,4.33,0,0,1-.58-2.22,4.38,4.38,0,0,1,.58-2.22A4.14,4.14,0,0,1,70,39.94a4.79,4.79,0,0,1,5.41.53l-.77,1.16a3.45,3.45,0,0,0-2.34-1,2.83,2.83,0,0,0-2.15.9,3.17,3.17,0,0,0,0,4.38,3,3,0,0,0,2.2.9,3.28,3.28,0,0,0,2.34-1l.88,1.08A4.49,4.49,0,0,1,72.36,48.2Z"
    />
    <path
      fill="#9c9b9b"
      d="M76.62,43.75a4.28,4.28,0,0,1,1.27-3.13,4.52,4.52,0,0,1,3.35-1.28,4.25,4.25,0,0,1,3.62,2.1,4.23,4.23,0,0,1,.55,2.11,3.08,3.08,0,0,1-.08.68h-7.2a2.85,2.85,0,0,0,1,1.92,3,3,0,0,0,2.06.76,3.47,3.47,0,0,0,2.67-1.23l1,1a4.67,4.67,0,0,1-3.65,1.57,4.81,4.81,0,0,1-1.88-.36,4.35,4.35,0,0,1-1.45-1A4.18,4.18,0,0,1,77,45.48,4.55,4.55,0,0,1,76.62,43.75Zm6.29-2.47a2.66,2.66,0,0,0-1.81-.66,2.91,2.91,0,0,0-1.87.66,3,3,0,0,0-1,1.67h5.65A2.67,2.67,0,0,0,82.91,41.28Z"
    />
    <path
      fill="#9c9b9b"
      d="M99.17,39.54,95.89,48H94.35l-3.27-8.46h1.54l2.51,6.51,2.49-6.51Z"
    />
    <path
      fill="#9c9b9b"
      d="M104.41,39.34a4.84,4.84,0,0,1,2.34.6A4.33,4.33,0,0,1,109,43.77a4.25,4.25,0,0,1-.6,2.22,4.4,4.4,0,0,1-1.65,1.61,4.88,4.88,0,0,1-4.69,0A4.39,4.39,0,0,1,100.43,46a4.39,4.39,0,0,1,1.64-6.05A4.84,4.84,0,0,1,104.41,39.34ZM102.21,46a3.09,3.09,0,0,0,4.36,0,3.14,3.14,0,0,0,0-4.38,3.1,3.1,0,0,0-4.36,0,3.05,3.05,0,0,0-.89,2.2A3,3,0,0,0,102.21,46Z"
    />
    <path
      fill="#9c9b9b"
      d="M112.26,48h-1.48V39.54h1.48v1.3a4,4,0,0,1,1.21-1.08,3,3,0,0,1,1.54-.42,3.31,3.31,0,0,1,2.45,1,3.39,3.39,0,0,1,1,2.5V48h-1.48V42.88a2.11,2.11,0,0,0-.62-1.58,2,2,0,0,0-1.46-.62,2.39,2.39,0,0,0-1.5.49,4.25,4.25,0,0,0-1.09,1.22Z"
    />
  </Svg>
);

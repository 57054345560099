import React, { useContext, Fragment } from 'react';
import { Box, Flex } from 'rebass';
import Link from '~/components/common/Link';
import ExternalFlagLink from '~/components/common/ExternalFlagLink';
import { ShowDesktop } from '~/components/calculator/CalculatorMediaUtils';
import { InlineBox, MetaLink, Uppercase, PoweredByImg } from './Meta.styles';
import { PageContext } from '../Layout';
import Icon from '../Icon';
// @ts-ignore
import flagUrl from '~/assets/img/placeholder/flag_china.png';

const flagPlaceholder = flagUrl;


type MetaProps = {
  translations: {
    Mail: string;
    Phone: string;
  };
  invertPoweredByImage?: boolean;
  poweredByLink?: {
    url?: string;
    text?: string;
    target?: string;
  };
  poweredByImage?: {
    id?: number;
    url?: string;
  };
  mail?: string;
  phone?: string;
  businessHours?: string;
  hideCustomerPortalLink?: boolean;
  customerPortalLink?: {
    url?: string;
    text?: string;
    target?: string;
  };
};

export default function Meta({
  translations,
  poweredByLink,
  poweredByImage,
  invertPoweredByImage,
  mail,
  phone,
  businessHours,
  hideCustomerPortalLink,
  customerPortalLink,
}: MetaProps) {
  const gridProps = {
    mx: { _: 0, lg: '-11px' },
  };

  const gridItemProps = {
    px: { _: 0, lg: '11px' },
  };

  const pageContext = useContext(PageContext);

  const { alternateEntries } = pageContext;
  const alternateLangs =
    alternateEntries && Object.keys(pageContext.alternateEntries);

  return (
    <Flex
      {...gridProps}
      py={1}
      justifyContent={{ _: 'flex-start', lg: 'space-between' }}
      flexDirection={{ _: 'column', lg: 'row' }}
      alignItems={{ _: 'flex-start', lg: 'center' }}
      flexWrap="wrap"
      fontSize={0}
    >
      {poweredByImage ? (
        <Box
          width={{ _: '100%', xl: 'auto' }}
          pb={{ _: 1, lg: 0 }}
          {...gridItemProps}
        >
          <Link
            to={poweredByLink ? poweredByLink.url : undefined}
            target={poweredByLink ? poweredByLink.target : undefined}
          >
            <PoweredByImg
              invertPoweredByImage={invertPoweredByImage ? true : false}
              src={poweredByImage.url}
              alt={poweredByLink ? poweredByLink.text : undefined}
            />
          </Link>
        </Box>
      ) : null}
      {mail ? (
        <Box {...gridItemProps}>
          <InlineBox mr={1}>{translations.Mail}</InlineBox>
          <MetaLink to={`mailto:${mail}`}>
            <strong>{mail}</strong>
          </MetaLink>
        </Box>
      ) : null}
      <Flex {...gridItemProps} alignItems="center" flexWrap="wrap">
        {phone ? (
          <Box mr={2}>
            <InlineBox mr={1}>{translations.Phone}</InlineBox>
            <MetaLink to={`tel:${phone}`}>
              <strong>{phone}</strong>
            </MetaLink>
          </Box>
        ) : null}
        {businessHours ? <Box>{businessHours}</Box> : null}
      </Flex>
      <ShowDesktop>
        <Flex
          {...gridProps}
          py={1}
          justifyContent={{ _: 'flex-start', lg: 'space-between' }}
          flexDirection={{ _: 'column', lg: 'row' }}
          alignItems={{ _: 'flex-start', lg: 'center' }}
          flexWrap="wrap"
          fontSize={0}
        >
          {!hideCustomerPortalLink && customerPortalLink ? (
            <Box {...gridItemProps} pt={{ _: 1, lg: 0 }}>
              <MetaLink
                to={customerPortalLink.url}
                target={customerPortalLink.target}
              >
                <Uppercase>{customerPortalLink.text}</Uppercase>
              </MetaLink>
            </Box>
          ) : null}
          {alternateLangs.length ? (
            <Box
              {...gridItemProps}
              pt={{ _: 1, lg: 0 }}
              css={{ display: 'inline-flex', alignItems: 'center' }}
            >
              <Icon name="Globe" css={{ marginRight: '0.5em' }} />
              {alternateLangs.map((language, index: number) => {
                const entry = alternateEntries[language];
                if (entry.fullUri) {
                  return (
                    <Fragment key={language}>
                      <MetaLink to={entry.fullUri}>
                        <Uppercase>{entry.language}</Uppercase>
                      </MetaLink>
                      {index !== alternateLangs.length - 1 ? ' | ' : null}
                    </Fragment>
                  );
                }
              })}
            </Box>
          ) : null}
          <Flex
            justifyContent={{ _: 'flex-start' }}
            flexDirection={{ _: 'column' }}
            alignItems={{ _: 'flex-start' }}
            flexWrap="wrap"
          >
            <ExternalFlagLink
              url="https://zmart.cn/"
              logoUrl={flagPlaceholder}
              logoAlt="Flag of China"
              linkText="ZMART.CN"
            />
          </Flex>
        </Flex>
      </ShowDesktop>
    </Flex>
  );
}

import styled from '~/utils/styled';
import { Text } from 'rebass';
import media from '~/utils/mediaqueries';
import { breakpointNavigation } from '~/themes/baseTheme';

type LogoStyledProps = {
  greyscale?: boolean;
};

export const LogoStyled = styled(Text)<LogoStyledProps>(
  ({ theme }) => ({
    display: 'inline-flex',
    flex: 'none',
    color: theme.colors.secondary,
    verticalAlign: 'middle',
  }),
  ({ theme, greyscale }) =>
    greyscale && {
      color: theme.colors.dim,
      filter: 'grayscale(1)',
      opacity: 0.5,
    },
);

type Dim = number | string;

type SvgProps = {
  dimMobile?: [Dim, Dim];
  dim?: [Dim, Dim];
};

export const Svg = styled('svg')<SvgProps>(({ dimMobile, dim }) => ({
  width: dimMobile?.[0] ?? 110,
  height: dimMobile?.[1] ?? 34,
  fill: 'currentColor',

  [media(breakpointNavigation)]: {
    width: dim?.[0] ?? 136,
    height: dim?.[1] ?? 42,
  },
}));

Svg.defaultProps = {
  xmlns: 'http://www.w3.org/2000/svg',
};

import styled from '~/utils/styled';
import { Box } from 'rebass';
import Link from '../Link';

export const InlineBox = styled(Box)({
  display: 'inline-block',
});

export const MetaLink = styled(Link)({
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
});

import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import { breakpointNavigation } from '~/themes/baseTheme';
import { Box } from 'rebass';
import Link from '../Link';
import Button from '../Button';
import Icon from '~/components/common/Icon';

export const InlineBox = styled(Box)({
  display: 'inline-block',
});

export const NavBar = styled(Box)({});

NavBar.defaultProps = {
  mx: 0,
};

export const NavItemWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

type NavItemProps = {
  active?: number;
};

export const NavItem = styled(Link)<NavItemProps>(({ active, theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textTransform: 'uppercase',
  textDecoration: 'none',
  fontWeight: 600,
  padding: `${theme.space[2]}px ${theme.space[0]}px`,
  borderBottom: active === 1 ? 0 : `1px solid ${theme.colors.border}`,
  color: theme.colors.secondary,
  WebkitTapHighlightColor: 'transparent',

  '&:hover': {
    color: theme.colors.secondaryBright,
  },
}));

export const NavItemToggle = styled(Icon)({
  cursor: 'pointer',
  padding: '.5em',
  WebkitTapHighlightColor: 'transparent',
});

export const NavButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: `${theme.space[2]}px ${theme.space[2]}px ${theme.space[2]}px`,

  [media('lg')]: {
    padding: `${theme.space[2]}px ${theme.space[2]}px ${theme.space[1]}px`,
  },
}));

export const NavButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: 'inherit',
  fontWeight: 600,
}));

export const DropdownSlot = styled(Box)({
  position: 'relative',
});

type DropdownMobileTransitionProps = {
  show: boolean;
  height: number;
};

export const DropdownMobileTransition = styled(Box)<
  DropdownMobileTransitionProps
>(({ show, height }) => ({
  WebkitOverflowScrolling: 'touch',
  height: show ? height : 0,

  [media('lg')]: {
    height: show ? height : 0,
  },

  transition: 'height 400ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

type DropdownMobileInnerProps = {
  show: boolean;
};

export const DropdownMobileInner = styled(Box)<DropdownMobileInnerProps>(
  ({ show }) => ({
    position: 'relative',
    opacity: show ? 1 : 0,
    transition: show
      ? 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 250ms'
      : 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)',
  }),
);

export const NavigationDropdownMobileContainer = styled(Box)({
  position: 'relative',
  overflow: 'hidden',

  '&::before, &::after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    height: 1,
    width: '100%',

    [media(breakpointNavigation)]: {
      display: 'none',
    },
  },

  '&::before': {
    top: 0,
    boxShadow: '0 -5px 16px 5px rgba(0,0,0,0.2)',
  },

  '&::after': {
    bottom: 0,
    boxShadow: '0 5px 16px 5px rgba(0,0,0,0.2)',
  },
});

export const Caret = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -1,
  left: theme.space[1] + theme.space[1],
  width: 0,
  height: 0,
  borderWidth: 12,
  borderStyle: 'solid',
  borderColor: 'white transparent transparent',
  zIndex: 1,

  [media('sm')]: {
    left: theme.space[4] + theme.space[1],
  },

  [media('lg')]: {
    left: theme.space[5] + theme.space[1],
  },
}));

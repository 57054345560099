import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M25.1,11c-2.5,0-4.4,0.7-5.9,2c-1.5,1.3-2.2,3.3-2.2,6.1v11.8c0,2.8,0.7,4.9,2.2,6.2c1.4,1.3,3.4,2,5.8,2s4.4-0.7,5.8-2
	c1.4-1.3,2.2-3.4,2.2-6.2V30c0-5-2.6-7.6-7.7-7.6c-2.6,0-4.6,0.8-5.8,2.5v-5.8c0-3.8,1.8-5.8,5.5-5.8c1.5,0,2.8,0.4,3.9,1.2
	c1.1,0.8,1.6,1.8,1.6,3.1c0,0.7,0.4,1,1.3,1c0.8,0,1.2-0.4,1.2-1.1c0-1.8-0.7-3.3-2.1-4.6C29.5,11.6,27.5,11,25.1,11z M25,24.6
	c3.6,0,5.5,1.8,5.5,5.5v0.9c0,3.8-1.8,5.7-5.5,5.7c-3.6,0-5.5-1.9-5.5-5.7v-1.2C19.5,26.3,21.4,24.6,25,24.6z"
    />
  </Svg>
);

import React from 'react';
import Link from '~/components/common/Link';
import { ButtonStyled, IconWrapper, Icon, Text } from './Button.styles';
import { IconNames } from '../Icon/Icon';
import { TextTransformProperty, BorderRadiusProperty } from 'csstype';

type ButtonProps = {
  iconName?: 'none' | IconNames;
  to?: string;
  onClick?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  variant?:
    | 'primary'
    | 'filled'
    | 'filledLarge'
    | 'blank'
    | 'blankGrey'
    | 'filledWhite'
    | 'filledGrey'
    | 'toggle'
    | 'toggleInherit';
  target?: string;
  className?: string;
  disabled?: boolean;
  hoverStyle?: 'moveRight' | 'moveLeft' | 'zoom' | 'none';
  children?: React.ReactNode;
  mobileWidth?: string | number;
  width?: string | number;
  type?: string;
  textTransform?: TextTransformProperty;
  paddingSize?: 'normal' | 'big' | 'small';
  iconPosition?: 'before' | 'after' | '';
  iconSize?: 'default' | 'small';
  bg: string;
} & typeof defaultProps;

const defaultProps = {
  iconName: 'ChevronRight',
  iconPosition: 'after',
  iconSize: 'default',
  variant: 'primary',
  mobileWidth: '100%',
  disabled: false,
  hoverStyle: 'moveRight',
  bg: '',
};

export default function Button({
  children,
  className,
  iconName,
  iconPosition,
  iconSize,
  to,
  onClick,
  onMouseEnter,
  onMouseLeave,
  target,
  disabled,
  variant,
  hoverStyle,
  mobileWidth,
  width,
  type,
  textTransform,
  paddingSize,
  bg,
}: ButtonProps) {
  return (
    <ButtonStyled
      as={to ? Link : undefined} // Render button as Link if needed
      to={to}
      target={target}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      variant={variant}
      hasChildren={children ? true : false}
      disabled={disabled}
      hoverStyle={hoverStyle}
      mobileWidth={mobileWidth}
      width={width}
      type={type}
      textTransform={textTransform}
      paddingSize={paddingSize}
      bg={bg}
    >
      {iconName !== 'none' &&
      (iconPosition === 'before' || iconPosition === '') ? (
        <IconWrapper iconPosition={iconPosition}>
          <Icon name={iconName} iconSize={iconSize} />
        </IconWrapper>
      ) : null}
      {children && <Text>{children}</Text>}
      {iconName !== 'none' && iconPosition === 'after' ? (
        <IconWrapper iconPosition={iconPosition}>
          <Icon name={iconName} iconSize={iconSize} />
        </IconWrapper>
      ) : null}
    </ButtonStyled>
  );
}

Button.defaultProps = defaultProps;

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M16.8,11c-0.4,0-0.7,0.2-0.9,0.6c0,0-3.4,4.7-3.5,4.9c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0.1,0.6,0.3,0.8
	c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.2,0.7-0.5l1.5-2.5v22.5c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3
	c0.3-0.2,0.4-0.4,0.4-0.7V11.9c0-0.3-0.1-0.5-0.3-0.7S17.1,11,16.8,11z M30.2,11c-2.3,0-4.2,0.7-5.6,2c-1.4,1.3-2.1,3.3-2.1,6.1
	v11.8c0,2.8,0.7,4.9,2.1,6.2c1.4,1.3,3.3,2,5.6,2s4.2-0.7,5.6-2c1.4-1.3,2.1-3.4,2.1-6.2V19.1c0-2.8-0.7-4.8-2.1-6.1
	C34.4,11.7,32.5,11,30.2,11z M30.2,13.4c3.5,0,5.3,1.9,5.3,5.8v11.8c0,3.9-1.8,5.8-5.3,5.8s-5.3-1.9-5.3-5.8V19.1
	C24.9,15.3,26.7,13.4,30.2,13.4z"
    />
  </Svg>
);

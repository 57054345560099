import styled from '~/utils/styled';
import { Box } from 'rebass';
import { TextAlignProperty } from 'csstype';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';
import media from '~/utils/mediaqueries';
import { ThemeColor } from '../ThemeProvider/ThemeProviderImplementation';

type SegmentStyledProps = {
  textAlign?: TextAlignProperty;
  minHeight?: string | number;
  viewportLayout?: boolean;
  backgroundColor: ThemeColor;
  borderBottom?: boolean;
  overflow?: string;
  demo?: boolean;
};

export const SegmentStyled = styled('div')<SegmentStyledProps>(
  ({ theme, textAlign, minHeight, backgroundColor, demo }) => ({
    position: 'relative',
    // Theme
    color: theme.colors.text,
    background: theme.colors[backgroundColor] as string,
    textAlign,
    minHeight,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    // required for header-background transition:
    transition:
      'background-color 450ms ease-in-out, box-shadow 260ms cubic-bezier(0.7, 0, 0.3, 1) 55ms',

    border: demo ? '2px dashed lightgray' : undefined,
    borderRadius: demo ? 2 : undefined,
  }),
  ({ theme, borderBottom }) => {
    if (borderBottom)
      return {
        borderBottom: `1px solid ${theme.colors.border}`,
      };
  },
  ({ overflow }) => {
    if (overflow)
      return {
        overflow: overflow,
      };
  },
  ({ viewportLayout }) => {
    if (viewportLayout)
      return {
        display: ['-ms-grid', 'grid'],

        [media('lg')]: {
          msGridRows: '2fr auto 1fr',
          gridTemplateRows: '2fr auto 1fr',
        },

        [media('xl')]: {
          msGridRows: 'minmax(180px, 2fr) auto 1fr',
          gridTemplateRows: 'minmax(180px, 2fr) auto 1fr',
        },
      };
  },
);

type ImageProps = {
  hasOverlay?: boolean;
};

const ImageBase = styled(CraftGatsbyImage)<ImageProps>(
  ({ theme }) => ({
    position: 'absolute !important' as any,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }),
  ({ hasOverlay }) =>
    hasOverlay && {
      '&::after': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,.5)',
      },
    },
);

export const Image = styled(ImageBase)({
  '@media (orientation: portrait)': {
    display: 'none',
  },
});

export const ImagePortrait = styled(ImageBase)({
  '@media (orientation: landscape)': {
    display: 'none',
  },
});

type WrapperProps = {
  innerWidth?: string;
};

export const Wrapper = styled(Box)<WrapperProps>(
  {
    position: 'relative',
    maxWidth: 1440,
    width: '100%',
    msGridRow: 2,
    gridRow: 2,
  },
  ({ innerWidth }) => {
    if (innerWidth === 'narrow')
      return {
        maxWidth: 890,
      };
  },
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M16.9,11c-0.4,0-0.7,0.2-0.9,0.6c0,0-3.4,4.7-3.5,4.9c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0.1,0.6,0.3,0.8
	c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.2,0.7-0.5l1.5-2.5v22.5c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3
	c0.3-0.2,0.4-0.4,0.4-0.7V11.9c0-0.3-0.1-0.5-0.3-0.7C17.4,11.1,17.2,11,16.9,11z"
    />
    <path
      d="M30,11c-1.4,0-2.6,0.2-3.7,0.6c-1,0.4-1.8,1-2.3,1.7c-0.5,0.7-0.9,1.3-1.1,2s-0.3,1.3-0.3,1.9c0,0.6,0.1,0.9,0.3,1.1
	c0.2,0.2,0.5,0.3,1,0.3c0.8,0,1.2-0.4,1.2-1.2c0-1.1,0.4-2,1.1-2.8c0.7-0.8,2-1.2,3.7-1.2c1.6,0,2.7,0.4,3.5,1.2
	c0.7,0.8,1.1,2,1.1,3.7c0,1.8-0.4,3-1.2,3.8c-0.8,0.7-2,1.1-3.5,1.1c-0.6,0-0.9,0.4-0.9,1.1c0,0.7,0.3,1.1,1,1.1
	c1.7,0,3,0.4,3.9,1.3s1.4,2.1,1.4,3.8V31c0,3.8-1.8,5.7-5.4,5.7c-1.9,0-3.2-0.4-4.1-1.3c-0.8-0.9-1.3-1.8-1.3-2.9
	c0-0.7-0.4-1.1-1.3-1.1c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.3,0.5-0.3,1c0,1.6,0.7,3,2,4.3c1.4,1.3,3.3,1.9,5.8,1.9
	c2.4,0,4.3-0.6,5.7-1.9c1.4-1.3,2.2-3.3,2.2-6.1v-0.7c0-3.1-1.3-5.1-3.8-6.1c0.9-0.5,1.7-1.2,2.2-2.3c0.6-1.1,0.8-2.4,0.8-4.1
	C36.9,13.3,34.6,11,30,11z"
    />
  </Svg>
);

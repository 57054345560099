import styled from '~/utils/styled';
import { Text } from 'rebass';
import { Colors } from '~/themes/baseTheme';

interface IconProps {
  disabled?: boolean;
  fillColor?: keyof Colors & string;
}

export const IconStyled = styled(Text)<IconProps>(
  {
    display: 'inline-flex',
    flex: 'none',
  },
  ({ fillColor, theme }) => {
    if (fillColor) {
      return {
        svg: {
          fill: `${theme.colors[fillColor] ?? fillColor}`,
        },
      };
    }
  },
  ({ disabled }) =>
    disabled && {
      opacity: 0.5,
      pointerEvents: 'none',
    },
);

export const Svg = styled('svg')({
  width: 'auto',
  height: '1em',
  fill: 'currentColor',

  '@media all and (-ms-high-contrast:none)': {
    width: '1em',
  },
});

Svg.defaultProps = {
  xmlns: 'http://www.w3.org/2000/svg',
};

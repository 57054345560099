import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import { Heading } from 'rebass';
import { isBrowserIE11 } from '~/utils/styles';
import { ThemeColor } from '../ThemeProvider/ThemeProviderImplementation';

interface HeadlineProps {
  color: ThemeColor;
}

//        0  1   2   3   4   5   6    7    8
//space: [0, 17, 30, 40, 65, 80, 100, 125, 150],

export const HeadlineStyled = styled(Heading)<HeadlineProps>(props => ({
  fontFamily: props.theme.fonts.headline,
  color: props.theme.colors[props.color] as string,
  letterSpacing: 0,

  strong: {
    fontWeight: 'inherit',
    color: props.theme.colors.primary,
  },

  u: {
    fontWeight: 'inherit',
    textDecoration: 'none',
    // Headline with badge style needs different padding because the browsers renders the font height differently.
    padding: isBrowserIE11() ? '13px 17px 9px 17px' : '0 0.45em 0.1em',
    borderRadius: '3em',
    backgroundColor: props.theme.colors.primary,
    color: props.theme.colors.primaryText,
  },

  '[data-level=h1]&': {
    fontWeight: 700,
    lineHeight: 1.2,
    marginTop: props.theme.space[1],
    marginBottom: props.theme.space[2],
    fontSize: props.theme.fontSizes[6],
    [media('lg')]: {
      fontSize: props.theme.fontSizes[8],
    },
  },
  '[data-level=h2]&': {
    fontWeight: 700,
    lineHeight: 1.2,
    marginTop: props.theme.space[1],
    marginBottom: props.theme.space[2],
    fontSize: props.theme.fontSizes[5],
    [media('lg')]: {
      fontSize: props.theme.fontSizes[7],
    },
  },
  '[data-level=h3]&': {
    fontWeight: 500,
    lineHeight: 1.25,
    marginTop: props.theme.space[1],
    marginBottom: props.theme.space[2],
    textTransform: 'uppercase',
    fontSize: props.theme.fontSizes[4],
    [media('lg')]: {
      fontSize: props.theme.fontSizes[5],
    },
  },
  '[data-level=h4]&': {
    fontWeight: 500,
    lineHeight: 1.3,
    marginTop: props.theme.space[1],
    marginBottom: props.theme.space[1],
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    fontSize: props.theme.fontSizes[3],
    [media('lg')]: {
      fontSize: props.theme.fontSizes[4],
    },
  },
  '[data-level=h5]&': {
    fontFamily: props.theme.fonts.base,
    fontWeight: 700,
    lineHeight: 1.3,
    marginTop: props.theme.space[1],
    marginBottom: props.theme.space[1],
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    fontSize: props.theme.fontSizes[2],
    [media('lg')]: {
      fontSize: props.theme.fontSizes[3],
    },
  },
}));

import React from 'react';
import { Flex, Box } from 'rebass';

import Parser from '~/components/common/Parser';

import { NavItem, NavItemWrapper } from './NavigationFooter.styles';

type NavItemBase = {
  id?: number;
  link?: {
    url?: string;
    text?: string;
    target?: string;
  };
  appearance?: 'default' | 'highlight';
};

type NavItemSub = NavItemBase & {
  description?: string;
};

type NavItem = NavItemBase & {
  children?: NavItemSub[];
};

type NavigationFooterProps = {
  navItems: NavItem[];
};

export default function NavigationFooter({ navItems }: NavigationFooterProps) {
  return (
    <Flex
      as="nav"
      flexWrap="wrap"
      flexDirection={{ _: 'column', sm: 'row' }}
      mx={{ _: 0, lg: -2 }}
    >
      {navItems.map(({ id, link, appearance, children }) => {
        if (!link) return null;

        const { url, target, text } = link;
        const hasChildren = children && children.length > 0;

        return (
          <Box
            key={id}
            px={{ _: 0, lg: 2 }}
            py={{ _: 1, lg: 2 }}
            width={{ _: 1, sm: 1 / 3, lg: 1 / 5 }}
            flex={1}
          >
            <NavItem
              to={url}
              target={target}
              appearance={appearance}
              padded={hasChildren ? 1 : 0}
            >
              {text}
            </NavItem>

            {hasChildren &&
              children &&
              children.map(({ id, link, appearance }) => {
                if (!link) return null;

                const { url, target, text } = link;

                return (
                  <NavItem
                    key={id}
                    to={url}
                    target={target}
                    appearance={appearance}
                  >
                    <Parser html={text} />
                  </NavItem>
                );
              })}
          </Box>
        );
      })}
    </Flex>
  );
}

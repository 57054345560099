import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#fff"
      d="M86,9H32c-5.5,0-10,4.5-10,10v90c0,5.5,4.5,10,10,10h64c5.5,0,10-4.5,10-10V29C106,18,97,9,86,9z"
    />
    <path
      fill="#5da793"
      d="M57 104.7c-.2 0-.4 0-.6-.1-.2 0-.4-.1-.6-.2-.2-.1-.3-.2-.5-.3-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.4.3-.5.1-.2.2-.3.4-.5.1-.1.3-.3.5-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2.4-.1.8-.1 1.2 0 .2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.5.4.1.1.3.3.4.5.1.2.2.3.3.5.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5C58.6 104.3 57.8 104.7 57 104.7zM74.5 107c-2 0-3.8-1.1-4.6-2.9l0-.1c-.9.7-2.1.8-3.2.3-1.5-.7-2.1-2.5-1.3-4 .9-1.8 2.7-2.9 4.7-2.9 1.8 0 3.4.9 4.3 2.3l.8-2.6c.7-2.2 2.7-3.8 5-4 2.3-.2 4.5 1 5.6 3l2 3.8c.3.6.8.7 1.1.8.3 0 .8 0 1.3-.4 1.2-1.2 3.1-1.2 4.2 0 1.2 1.2 1.2 3.1 0 4.2-1.7 1.7-4 2.5-6.4 2.1-2.4-.4-4.4-1.8-5.5-3.9l-1.8-3.3-1.2 4c-.6 2-2.4 3.5-4.5 3.6C74.8 107 74.7 107 74.5 107z"
    />
    <path
      fill="#e9eef4"
      d="M86 62H42c-1.7 0-3-1.3-3-3s1.3-3 3-3h44c1.7 0 3 1.3 3 3S87.7 62 86 62zM76 32H52c-1.7 0-3-1.3-3-3s1.3-3 3-3h24c1.7 0 3 1.3 3 3S77.7 32 76 32zM71 77H42c-1.7 0-3-1.3-3-3s1.3-3 3-3h29c1.7 0 3 1.3 3 3S72.7 77 71 77zM86 77c-.8 0-1.6-.3-2.1-.9-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.8.3-1.6.9-2.1.1-.1.3-.3.5-.4.2-.1.3-.2.5-.3.2-.1.4-.1.6-.2 1-.2 2 .1 2.7.8.6.6.9 1.3.9 2.1 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5C87.6 76.7 86.8 77 86 77z"
    />
    <path
      fill="#fff"
      d="M106,29H96c-5.5,0-10-4.5-10-10V9h0C97,9,106,18,106,29L106,29z"
    />
    <path
      fill="#444b54"
      d="M102.3,12.8C97.9,8.4,92.2,6,86,6c0,0,0,0,0,0H32c-7.2,0-13,5.8-13,13v90c0,7.3,5.9,13,11,13h63.8c8.7,0,15-5.5,15-13l0.1-67c0-1.7-1.3-3-3-3c0,0,0,0,0,0c-1.7,0-3,1.3-3,3l-0.1,67c0,4.2-3.6,7-9,7H30c-1.7,0-5-3-5-7V19c0-3.9,3.1-7,7-7h51v7c0,7.2,5.8,13,13,13h9.6c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0c1.2,0,2.3-0.8,2.8-1.8c0.2-0.4,0.3-0.8,0.3-1.2c0-0.1,0-0.3,0-0.4C108.9,22.6,106.5,17,102.3,12.8z M89,19v-6.7c3.4,0.6,6.5,2.2,9,4.7c2.5,2.5,4.1,5.6,4.7,9H96C92.1,26,89,22.9,89,19z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 120 120">
    <path
      fill="#444b54"
      d="M11.508 92.054l-2.649-.6A12.3 12.3 0 01.011 80.407V13.925a12.4 12.4 0 014.4-10 11.547 11.547 0 017.1-2.9h95.271a12.347 12.347 0 0112.6 8.6 13.2 13.2 0 01.6 4.4v64.933a12.3 12.3 0 01-12.9 13h-10a39.443 39.443 0 00-5.948 0c-2.4 0-3.149-.6-3.849-2.949a3.149 3.149 0 013.549-2.9h16a6.548 6.548 0 007.2-7.2V13.525a6.548 6.548 0 00-7.252-7.249H13.007a6.6 6.6 0 00-7.248 7.248v65.384a6.6 6.6 0 007.348 7.3h18.845c2.249 0 3.049.7 3.549 2.9-.5 2.2-1.35 2.9-3.649 2.9z"
    />
    <path
      fill="#1fd4af"
      d="M82.189 95.103a60.531 60.531 0 003 6.6 3.049 3.049 0 010 3.649 3 3 0 01-3.549.8l-12.648-3.651c-1-.3-1.4 0-1.85.8-2.2 4.549-4.449 9-6.648 13.546-.65 1.25-1.4 2.3-3 2.3s-2.3-1.1-2.949-2.349a139.96 139.96 0 01-6.5-15.8c-1.2-3.9-2.2-7.9-3.049-11.9l-.3-1.85a86.926 86.926 0 01-1.5-15v.45a17.3 17.3 0 0018.9 13.346 17.545 17.545 0 0011.647-6.848 23.544 23.544 0 003.249-7c.25 2.3.45 4.549.75 6.8a46.687 46.687 0 002.4 10 28.292 28.292 0 002.047 6.107z"
    />
    <path
      fill="#d0d4d8"
      d="M44.999 89.105c.85 4 1.85 8 3.049 11.9l-13.596 2.25c-3.9.65-7.748 1.4-11.647 2a8.6 8.6 0 01-10-7 37.141 37.141 0 01-.95-6.2h20.348c2.3 0 3.149-.7 3.649-2.9z"
    />
    <path
      fill="#d0d4d8"
      d="M82.188 95.103a28.293 28.293 0 01-2-5.9h7.3c.7 2.349 1.45 2.9 3.849 2.949a39.444 39.444 0 015.948 0 8.2 8.2 0 01-3.9.95z"
    />
    <path
      fill="#444b54"
      d="M42.999 72.41a17.203 17.203 0 1134.041-5 40.84 40.84 0 010 5 23.544 23.544 0 01-3.249 7 17.545 17.545 0 01-11.647 6.848A17.3 17.3 0 0143.251 72.91a4.4 4.4 0 00-.252-.5zm28.392-3.149a11.347 11.347 0 10-3.34 8.041 11.4 11.4 0 003.341-8.041z"
    />
    <path
      fill="#444b54"
      d="M59.995 25.273H27.253c-2.049 0-3.149-.8-3.4-2.349a2.849 2.849 0 012.449-3.3h66.334c2.349 0 3.6 1.15 3.5 3.1a2.7 2.7 0 01-2.449 2.6 13.992 13.992 0 01-1.45 0z"
    />
    <path
      fill="#444b54"
      d="M59.99 39.568H40.5a2.649 2.649 0 01-2.349-2.349 2.8 2.8 0 011.7-3.049 4.149 4.149 0 011.75-.25H78.49a4.149 4.149 0 011.55 0 2.8 2.8 0 011.8 3.149 2.549 2.549 0 01-2.4 2.3 19.994 19.994 0 01-2.449 0z"
    />
  </Svg>
);

import React, { useContext } from 'react';
import { TextAlignProperty } from 'csstype';
import ThemeProvider from '~/components/common/ThemeProvider';
import { SegmentStyled, Wrapper, Image, ImagePortrait } from './Segment.styles';
import PageBuilderContext from '../PageBuilderContext';
import { ThemeColor } from '../ThemeProvider/ThemeProviderImplementation';

enum OverflowEnum {
  Visible = 'visible',
  Scroll = 'scroll',
  Auto = 'auto',
  Hidden = 'hidden',
}

type SegmentProps = {
  className?: string;
  children?: React.ReactNode;
  themeName?: string;
  alignItems?: TextAlignProperty;
  backgroundImage?: any;
  backgroundImagePortrait?: any;
  backgroundColor?: ThemeColor;
  backgroundHasOverlay?: boolean;
  borderBottom?: boolean;
  noPadding?: boolean;
  height?: 'viewport' | 'intrinsic' | null;
  width?: 'narrow' | null | any; // @todo: please help, dont know how to fix the type 🥺
  overflow?: OverflowEnum | any | undefined; // @todo: same ^^ ~DH
  demo?: boolean;
} & typeof defaultProps;

const defaultProps = {
  backgroundColor: 'background',
  themeName: 'default',
  noPadding: false,
};

export default function Segment({
  className,
  children,
  themeName,
  alignItems,
  backgroundImage,
  backgroundImagePortrait,
  backgroundColor,
  backgroundHasOverlay,
  borderBottom,
  height,
  width,
  noPadding,
  overflow,
  demo,
}: SegmentProps) {
  const pageBuilder = useContext(PageBuilderContext);

  const padding = pageBuilder.lessSpacing
    ? { _: 2, md: 4 }
    : { _: 2, md: 4, lg: 5, xl: 6 };

  let minHeight, viewportLayout;
  switch (height) {
    case 'viewport':
      minHeight = '100vh';
      viewportLayout = true;
      break;
    case 'intrinsic':
    default:
      minHeight = undefined;
  }

  return (
    <ThemeProvider
      name={
        // the default cms value should trigger the defaultSegmentTheme if available
        // for some reason the cms default value is "white", it should be "default"
        // it's not possible to change it though without manually going trough all pages
        (themeName === null || themeName === undefined || themeName === 'white'
          ? pageBuilder.defaultSegmentTheme
          : themeName) ?? 'default'
      }
    >
      <SegmentStyled
        className={className}
        textAlign={alignItems}
        minHeight={minHeight}
        viewportLayout={viewportLayout}
        backgroundColor={backgroundColor}
        borderBottom={borderBottom}
        overflow={overflow}
        demo={demo}
      >
        {backgroundImage && (
          <Image
            hasOverlay={backgroundHasOverlay}
            fluid={backgroundImage}
            args={{
              width: backgroundImage.width,
              maxWidth: 1920,
            }}
          />
        )}
        {(backgroundImagePortrait || backgroundImage) && (
          <ImagePortrait
            hasOverlay={backgroundHasOverlay}
            fluid={backgroundImagePortrait || backgroundImage}
            args={{
              width: (backgroundImagePortrait || backgroundImage).width,
              maxWidth: 960,
            }}
          />
        )}
        <Wrapper
          mx="auto"
          px={noPadding ? 0 : (padding as any)}
          mt={viewportLayout ? { _: 5, md: 7, lg: 0 } : undefined}
          mb={viewportLayout ? { _: 3, md: 5, lg: 0 } : undefined}
          innerWidth={width}
        >
          {children}
        </Wrapper>
      </SegmentStyled>
    </ThemeProvider>
  );
}

Segment.defaultProps = defaultProps;

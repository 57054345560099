import React, {
  Component,
  createRef,
  forwardRef,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Image, { GatsbyImageProps } from 'gatsby-image';

interface GatsbyImageWithIEPolyfillProps extends GatsbyImageProps {
  objectFit?: `fill` | `contain` | `cover` | `none` | `scale-down`;
  objectPosition?: string;
}

function useCombinedRefs(...refs: React.Ref<any>[]) {
  const targetRef = React.useRef<any>();

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        // @ts-ignore
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

function ImageWithIEPolyfill(
  props: GatsbyImageWithIEPolyfillProps & { ref: React.Ref<any> },
) {
  const { objectFit, objectPosition, ref, ...rest } = props;

  const innerRef = useRef<any>(null);
  const imageRef = useCombinedRefs(ref, innerRef);

  function ofi(imageRefCurrent: any) {
    const testImg = document.createElement('img');
    if (
      typeof testImg.style.objectFit === 'undefined' ||
      typeof testImg.style.objectPosition === 'undefined'
    ) {
      // @ts-ignore
      import('object-fit-images').then(({ default: ObjectFitImages }) => {
        if (imageRefCurrent?.imageRef?.current) {
          ObjectFitImages(imageRefCurrent.imageRef.current);
        }
      });
    }
  }

  useEffect(() => {
    ofi(imageRef.current);
  }, [imageRef.current]);

  return (
    <Image
      ref={imageRef}
      {...rest}
      imgStyle={{
        // @ts-ignore
        ...props.imgStyle,
        objectFit,
        objectPosition,
        fontFamily: `"object-fit: ${objectFit}; object-position: ${objectPosition}"`,
      }}
      onLoad={() => {
        ofi(imageRef.current);
      }}
    />
  );
}

// If you modify these propTypes, please don't forget to update following files as well:
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-image/withIEPolyfill/index.d.ts
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-image/README.md#gatsby-image-props
// https://github.com/gatsbyjs/gatsby/blob/master/docs/docs/gatsby-image.md#gatsby-image-props
ImageWithIEPolyfill.propTypes = {
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
};

ImageWithIEPolyfill.defaultProps = {
  objectFit: 'cover',
  objectPosition: '50% 50%',
};

export default forwardRef((props: GatsbyImageWithIEPolyfillProps, ref) => (
  <ImageWithIEPolyfill {...props} ref={ref} />
));

import { basicColors, pallet } from '~/themes/baseTheme';

export const colors = {
  ...basicColors,

  primary: pallet.green650,
  primaryBright: pallet.green200,
  primaryText: basicColors.background,
  primaryBrightText: basicColors.background,

  secondary: pallet.red950,
  secondaryBright: pallet.red200,
  secondaryText: basicColors.background,
  secondaryBrightText: basicColors.background,

  tertiary: pallet.orange125,
  tertiaryBright: pallet.orange025,
  tertiaryText: basicColors.text,
  tertiaryBrightText: basicColors.text,

  quaternary: pallet.orange050,
  quaternaryText: basicColors.text,

  errorBg: pallet.red025,
  errorText: pallet.red950,

  successBg: pallet.green025,
  successText: pallet.green650,

  pallet,
};

import React from 'react';
import {
  useCraftGlobals,
  useCraftTranslations,
} from '~/utils/hooks/useCraftGlobals';
import Meta from '~/components/common/Meta';

type CraftGlobalMetaProps = {
  invertPoweredByImage?: boolean;
  hideCustomerPortalLink?: boolean;
};

export default function CraftGlobalMeta({
  invertPoweredByImage,
  hideCustomerPortalLink,
}: CraftGlobalMetaProps) {
  const { globalMeta } = useCraftGlobals();
  const t = useCraftTranslations();

  if (!globalMeta) return null;

  return (
    <Meta
      translations={{ Phone: t('Phone'), Mail: t('Mail') }}
      invertPoweredByImage={invertPoweredByImage ?? undefined}
      poweredByLink={{
        url: globalMeta.metaPoweredByLink?.url ?? undefined,
        text: globalMeta.metaPoweredByLink?.text ?? undefined,
        target: globalMeta.metaPoweredByLink?.target ?? undefined,
      }}
      poweredByImage={{
        id: globalMeta.metaPoweredByImage?.[0]?.id ?? undefined,
        url: globalMeta.metaPoweredByImage?.[0]?.url ?? undefined,
      }}
      mail={globalMeta.metaMail ?? undefined}
      phone={globalMeta.metaPhone ?? undefined}
      businessHours={globalMeta.metaBusinessHours ?? undefined}
      hideCustomerPortalLink={hideCustomerPortalLink}
      customerPortalLink={{
        url: globalMeta.metaCustomerPortalLink?.url ?? undefined,
        text: globalMeta.metaCustomerPortalLink?.text ?? undefined,
        target: globalMeta.metaCustomerPortalLink?.target ?? undefined,
      }}
    />
  );
}

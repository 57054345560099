import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#addff8"
      d="M111.5,54.2v25c0,5.2-21.3,9.5-47.5,9.5s-47.5-4.3-47.5-9.5v-25L111.5,54.2"
    />
    <path
      fill="#7ca0b3"
      d="M111.5,54.2L111.5,54.2l-15,3l0.2,28.871c9.106-1.736,14.793-4.181,14.8-6.867 c0-0.002,0-0.003,0-0.005V54.2"
    />
    <path
      fill="#c6c6c5"
      d="M64 39.2A50 10 0 1 0 64 59.2A50 10 0 1 0 64 39.2Z"
    />
    <path
      fill="#fff"
      d="M44,49.2c0-4.5,14.7-8.3,35-9.5c-4.7-0.3-9.8-0.5-15-0.5c-27.6,0-50,4.5-50,10s22.4,10,50,10 c5.2,0,10.3-0.2,15-0.5C58.7,57.5,44,53.7,44,49.2z"
    />
    <path
      fill="#454b54"
      d="M34.6,52.3c-1.5,0-2.7-1.1-3-2.5c-0.3-1.6,0.9-3.2,2.5-3.4c6-0.9,16-2,29.8-2c1.7,0,3,1.3,3,3 s-1.301,3-3,3c-13.4,0-23.1,1.1-28.9,2C35,52.2,34.8,52.3,34.6,52.3z"
    />
    <path
      fill="#454b54"
      d="M64 62.2c-5.4 0-53-.4-53-13s47.6-13 53-13 53 .4 53 13S69.4 62.2 64 62.2zM17.2 49.2c2.7 2.7 19.4 7 46.8 7s44.1-4.3 46.8-7c-2.7-2.7-19.399-7-46.8-7C36.6 42.2 19.9 46.6 17.2 49.2zM64 92.2c-5.4 0-53-.4-53-13 0-1.7 1.3-3 3-3 1.6 0 2.9 1.2 3 2.8 1.8 2.7 18.8 7.2 47 7.2s45.2-4.601 47-7.2c.1-1.5 1.4-2.8 3-2.8 1.7 0 3 1.3 3 3C117 91.9 69.4 92.2 64 92.2z"
    />
    <path
      fill="#454b54"
      d="M16.5 84.2c-1.7 0-3-1.3-3-3v-20c0-1.7 1.3-3 3-3s3 1.3 3 3v20C19.5 82.9 18.2 84.2 16.5 84.2zM111.5 84.2c-1.7 0-3-1.3-3-3v-20c0-1.7 1.3-3 3-3s3 1.3 3 3v20C114.5 82.9 113.2 84.2 111.5 84.2z"
    />
    <path
      fill="#fff"
      d="M47,73.9L47,73.9c8.3-8.301,21.7-8.301,30,0C68.7,82.2,55.3,82.2,47,73.9z"
    />
    <path
      fill="#454b54"
      d="M62,83.1c-6.5,0-12.5-2.5-17.1-7.1c-1.2-1.2-1.2-3.1,0-4.2c4.6-4.6,10.7-7.1,17.1-7.1 s12.5,2.5,17.1,7.1c1.2,1.2,1.2,3.101,0,4.2C74.5,80.6,68.5,83.1,62,83.1z M51.7,73.9c3,2.1,6.6,3.2,10.3,3.2 c3.7,0,7.3-1.1,10.3-3.2c-3-2.101-6.6-3.2-10.3-3.2S54.7,71.8,51.7,73.9z"
    />
    <path
      fill="#454b54"
      d="M82,82c-0.8,0-1.6-0.3-2.2-0.9l-5-5.1c-1.1-1.2-1.1-3,0-4.2l5-5.1c1.2-1.2,3.1-1.2,4.2-0.1 c1.2,1.2,1.2,3.101,0.1,4.2l-3,3.101l3,3.1c1.2,1.2,1.101,3.1-0.1,4.2C83.5,81.7,82.8,82,82,82z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '~/components/common/Icon/Icon.styles';

export default () => (
  <Svg viewBox="0 0 48 48">
    <path
      d="M19.1,39.4c-0.2,0-0.5-0.2-0.5-0.3L5.8,25.6C5.1,24.9,4.7,24,4.7,23s0.3-1.9,1.1-2.6c1.4-1.4,3.9-1.4,5.3,0l8,8.4L37,9.8
	c1.4-1.4,3.9-1.4,5.3,0s1.4,3.7,0.2,5.1L19.6,39.1C19.5,39.2,19.3,39.4,19.1,39.4L19.1,39.4z M8.4,20.7c-0.6,0-1.1,0.2-1.4,0.6
	c-0.8,0.8-0.8,2.3,0,3.1l12.2,13l22-23.8c0.8-0.8,0.6-2.1,0-2.9c-0.8-0.8-2.3-0.8-3.1,0L19.6,30.4c-0.2,0.2-0.3,0.3-0.6,0.3
	c-0.2,0-0.5-0.2-0.5-0.2l-8.7-8.9C9.5,21.1,9,20.7,8.4,20.7z"
    />
  </Svg>
);

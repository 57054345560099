import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path fill="#938370" d="M76 66L68.5 51 83.5 51z" />
    <path fill="#e4caa6" d="M22 66H76V116H22z" />
    <path fill="#fff" d="M30 100H50V108H30z" />
    <path fill="#e4caa6" d="M76 66L22 66 12 46 66 46 76 66" />
    <path fill="#ceb797" d="M66 46L12 46 22 66 76 66 66 46" />
    <path
      fill="#454b54"
      d="M46 30c-1.1 0-2.2-.6-2.7-1.7l-5-10c-.7-1.5-.1-3.3 1.3-4 1.5-.7 3.3-.1 4 1.3l5 10c.7 1.5.1 3.3-1.3 4C46.9 29.9 46.5 30 46 30zM66 30c-1.7 0-3-1.3-3-3V12c0-1.7 1.3-3 3-3s3 1.3 3 3v15C69 28.7 67.7 30 66 30zM86 30c-.5 0-.9-.1-1.3-.3-1.5-.7-2.1-2.5-1.3-4l5-10c.7-1.5 2.5-2.1 4-1.3 1.5.7 2.1 2.5 1.3 4l-5 10C88.2 29.4 87.1 30 86 30z"
    />
    <path fill="#e4caa6" d="M76 66L106 66 106 116 76 116 76 66" />
    <path fill="#ceb797" d="M106 66L76 66 76 116 106 116 106 66" />
    <path fill="#e4caa6" d="M106 66L76 66 86 46 116 46 106 66" />
    <path fill="#ceb797" d="M116 46L86 46 76 66 106 66 116 46" />
    <path
      fill="#454b54"
      d="M118.6,44.4C118,43.5,117,43,116,43H86c-1.1,0-2.2,0.6-2.7,1.7L81.6,48H70.4l-1.7-3.3 c-0.5-1-1.5-1.7-2.7-1.7H12c-1,0-2,0.5-2.6,1.4c-0.5,0.9-0.6,2-0.1,2.9L19,66.7V116c0,1.7,1.3,3,3,3h54h30c1.7,0,3-1.3,3-3V66.7 l9.7-19.4C119.1,46.4,119.1,45.3,118.6,44.4z M16.9,49h47.3l7,14H23.9L16.9,49z M73,113H25V69h48V113z M73.4,54h5.3L76,59.3L73.4,54 z M103,113H79V69h24V113z M104.1,63H80.9l7-14h23.3L104.1,63z"
    />
  </Svg>
);

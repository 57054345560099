import styled from '~/utils/styled';
import { Box } from 'rebass';
import media from '~/utils/mediaqueries';

export const ShowDesktop = styled(Box)(({ theme }) => ({
  display: 'none',
  [media('lg')]: {
    display: 'block',
  },
}));

export const ShowMobile = styled(Box)(({ theme }) => ({
  display: 'block',
  [media('lg')]: {
    display: 'none',
  },
}));

import React, { Children } from 'react';
import { Box, Flex } from 'rebass';
import { FlexWrapProps, FlexProps } from 'styled-system';

type PaddingSize = 'none' | 'small' | 'medium' | 'large' | null;

function paddingSizeToTop(paddingSize?: PaddingSize) {
  let pt;

  switch (paddingSize) {
    case 'none':
      pt = 0;
      break;
    case 'small':
      pt = { _: 1, sm: 1 };
      break;
    case 'medium':
      pt = { _: 1, sm: 2 };
      break;
    case 'large':
    default:
      pt = { _: 2, sm: 3, md: 4 };
      break;
  }

  return pt;
}

type ButtonGroupProps = {
  children: React.ReactNode;
  justifyContent?: string;
  flexWrap?: FlexWrapProps['flexWrap'];
  py?: PaddingSize;
};

export default function ButtonGroup({
  children,
  justifyContent,
  flexWrap,
  py,
}: ButtonGroupProps) {
  const pt = paddingSizeToTop(py);
  return (
    <Flex
      mx={{ _: 0, lg: -1 }}
      mt={pt as any}
      width={{ _: 1, lg: 'auto' }}
      flexWrap={flexWrap ?? 'wrap'}
      justifyContent={justifyContent}
      alignItems="center"
    >
      {children}
    </Flex>
  );
}

type ButtonGroupItemProps = {
  children: React.ReactNode;
  className?: string;
  width?: string | number;
  flex?: FlexProps['flex'];
};

export function ButtonGroupItem({
  children,
  className,
  width,
  flex,
}: ButtonGroupItemProps) {
  return (
    <Box
      px={{ _: 0, lg: 1 }}
      pb={1}
      width={{ _: '100%', lg: width ?? 'auto' }}
      flex={flex}
      className={className}
    >
      {children}
    </Box>
  );
}

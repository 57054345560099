import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M25,11c-2.4,0-4.3,0.7-5.8,2c-1.5,1.3-2.2,3.4-2.2,6.1v11.8c0,2.8,0.7,4.9,2.2,6.2c1.5,1.3,3.4,2,5.8,2c2.4,0,4.3-0.7,5.8-2
	c1.5-1.3,2.2-3.4,2.2-6.2V19.1c0-2.8-0.7-4.8-2.2-6.1C29.3,11.7,27.4,11,25,11z M25,13.3c3.6,0,5.4,1.9,5.4,5.8v11.8
	c0,3.9-1.8,5.8-5.4,5.8s-5.4-1.9-5.4-5.8V19.1C19.6,15.2,21.4,13.3,25,13.3z"
    />
  </Svg>
);

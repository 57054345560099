
import React from 'react';
import {
  LinkWrapper,
  FlagIcon,
  LinkText,
} from './ExternalFlagLink.styles';

type LinkProps = {
  url: string;
  logoUrl: string;
  logoAlt?: string;
  linkText: string;
  variant?: 'mobile' | 'default';
  margin?: string;
};

export default function Link({
  url,
  logoUrl,
  logoAlt,
  linkText,
  variant,
  margin,
}: LinkProps) {
  return (
    <LinkWrapper href={url} target="_blank" rel="noopener noreferrer" margin={margin} variant={variant ?? 'default'}>
      <FlagIcon src={logoUrl} alt={logoAlt} />
      <LinkText variant={variant ?? 'default'}>
        { linkText }
      </LinkText>
    </LinkWrapper>
  );
}

Link.defaultProps = {
  logoAlt: 'Country Flag',
  variant: 'default',
  margin: '0',
}

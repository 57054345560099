import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';

export const MobileCustomTrustbadgeStyled = styled.div(() => ({
  zIndex: 100,
  position: 'fixed',
  display: 'flex',
  justifyContent: 'flex-start',
  bottom: '5px',
  paddingRight: '5px',
  width: '100%',
  height: '65px',
  opacity: 1,
  [media('md')]: {
    opacity: 0,
  },
}));

import React from 'react';

import { MobileCustomTrustbadgeStyled } from './MobileCustomTrustbadge.styles';

type MobileCustomTrustbadgeProps = {
  identifier: string;
};

export default function MobileCustomTrustbadge({identifier}: MobileCustomTrustbadgeProps) {
  return (
    <MobileCustomTrustbadgeStyled id={identifier} />
  );
}

import React from 'react';
import { parseAsContent, ALLOWED_TAGS_INLINE } from './parse';
import { searchAndExecuteScriptsFromHtml } from '~/utils/hooks/useScriptBodyEffect';

type ParserProps = {
  html?: string;
  allowedTags?: string[];
} & typeof defaultProps;

const defaultProps = {
  allowedTags: ALLOWED_TAGS_INLINE,
};

export default function Parser({
  html,
  allowedTags,
}: ParserProps) {
  if (!html) return null;
  // Read all Script Tags from CMS, execute code (unsafe, but implemented as of ZMAR-561)
  searchAndExecuteScriptsFromHtml(html);
  const parsedChildren = parseAsContent(html, { allowedTags });
  return <>{parsedChildren}</>;
}

Parser.defaultProps = defaultProps;

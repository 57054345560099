import React, { useContext, useState, Fragment } from 'react';
import Measure from 'react-measure';
import { Flex, Box  } from 'rebass';
import { MetaLink, Uppercase } from '~/components/common/Meta/Meta.styles';
import { Location } from '@reach/router';
import { PageContext } from '../Layout';
import Icon from '../Icon';
import {
  useCraftGlobals
} from '~/utils/hooks/useCraftGlobals';

import { HeaderIsPinnedContext } from '../Header';
import NavigationDropdown from '../NavigationDropdown';
import ExternalFlagLink from '~/components/common/ExternalFlagLink';
// @ts-ignore
import flagUrl from '~/assets/img/placeholder/flag_china.png';

const flagPlaceholder = flagUrl;

import {
  NavBar,
  NavItemWrapper,
  NavItem,
  NavItemToggle,
  NavButtonWrapper,
  NavButton,
  DropdownSlot,
  DropdownMobileTransition,
  DropdownMobileInner,
  NavigationDropdownMobileContainer,
  Caret,
} from './NavigationMobile.styles';

type NavItemBase = {
  id?: number;
  link?: {
    url?: string;
    text?: string;
    target?: string;
  };
  appearance?: 'default' | 'highlight';
};

type NavItemSub = NavItemBase & {
  description?: string;
};

type NavItem = NavItemBase & {
  children?: NavItemSub[];
};

type NavigationProps = {
  navItems: NavItem[];
} & typeof defaultProps;

const defaultProps = {};

export default function NavigationMobile({ navItems }: NavigationProps) {
  const headerIsPinned = useContext(HeaderIsPinnedContext);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState<
    number | null
  >();

  const pageContext = useContext(PageContext);

  const { alternateEntries } = pageContext;
  const alternateLangs =
    alternateEntries && Object.keys(pageContext.alternateEntries);
  const gridItemProps = {
    px: { _: 0, lg: '11px' },
  };
  const { globalMeta } = useCraftGlobals();
  const customerPortalLink = {
    url: globalMeta?.metaCustomerPortalLink?.url ?? undefined,
    text: globalMeta?.metaCustomerPortalLink?.text ?? undefined,
    target: globalMeta?.metaCustomerPortalLink?.target ?? undefined,
  }

  return (
    <Location>
      {({ location }) => (
        <NavBar as="nav">
          {navItems.map(({ id, link, appearance, children }, index) => {
            if (!link) return;

            const hasChildren = children && children.length > 0;

            // Render as button
            if (appearance === 'highlight') {
              // Hide highlighted menu item when the page is the same. See ZMAR-194
              if (
                location.pathname !== '/' &&
                link.url &&
                link.url.indexOf(location.pathname) >= 0
              ) {
                return null;
              }

              return (
                <Fragment key={`nav-main-mobile-${id}`}>
                  <NavItemWrapper>
                    {customerPortalLink ? (
                      <NavItem
                        to={customerPortalLink.url}
                        target={customerPortalLink.target}
                      >
                        <Uppercase>{customerPortalLink.text}</Uppercase>
                      </NavItem>
                    ) : null}
                    {alternateLangs.length ? (
                      <Box
                        {...gridItemProps}
                        css={{ display: 'inline-flex', alignItems: 'center', width: '100%', borderBottom: '1px solid #f0f0f0' }}
                      >
                        <Icon name="Globe" css={{ marginRight: '0.5em' }} />
                        {alternateLangs.map((language, index: number) => {
                          const entry = alternateEntries[language];
                          if (entry.fullUri) {
                            return (
                              <Fragment key={language}>
                                <NavItem to={entry.fullUri}>
                                  <Uppercase>{entry.language}</Uppercase>
                                </NavItem>
                                {index !== alternateLangs.length - 1 ? ' | ' : null}
                              </Fragment>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Box>
                    ) : null}

                    <Flex
                      justifyContent={{ _: 'flex-start' }}
                      flexDirection={{ _: 'column' }}
                      alignItems={{ _: 'flex-start' }}
                      flexWrap="wrap"
                    >
                      <ExternalFlagLink
                        url="https://zmart.cn/"
                        logoUrl={flagPlaceholder}
                        logoAlt="Flag of China"
                        linkText="ZMART.CN"
                        variant="mobile"
                        margin="1.5rem 0"
                      />
                    </Flex>
                  </NavItemWrapper>

                  <NavButtonWrapper>
                    <NavButton
                      to={link.url}
                      target={link.target}
                      variant={headerIsPinned ? 'primary' : 'filled'}
                    >
                      {link.text}
                    </NavButton>
                  </NavButtonWrapper>
                </Fragment>
              );
            }

            // Render as link
            return (
              <NavItemWrapper key={`nav-main-mobile-${id}`}>
                <NavItem
                  to={link.url}
                  target={link.target}
                  active={activeDropdownIndex === index ? 1 : 0}
                  data-navitem
                  onClick={
                    hasChildren
                      ? () =>
                          setActiveDropdownIndex(
                            activeDropdownIndex === index ? undefined : index,
                          )
                      : undefined
                  }
                >
                  {link.text}
                  {hasChildren && (
                    <NavItemToggle
                      name={activeDropdownIndex === index ? 'Minus' : 'Plus'}
                    />
                  )}
                </NavItem>
                {children && hasChildren && (
                  <Measure bounds>
                    {({ measureRef, contentRect }) => {
                      const height =
                        (contentRect.bounds && contentRect.bounds.height) || 0;

                      return (
                        <DropdownMobileTransition
                          show={activeDropdownIndex === index}
                          height={height}
                        >
                          <DropdownMobileInner
                            show={activeDropdownIndex === index}
                            ref={measureRef}
                          >
                            {activeDropdownIndex === index && (
                              <NavigationDropdownMobileContainer
                                mx={{ _: -1, sm: -4, lg: -5, xl: 0 }}
                              >
                                <NavigationDropdown
                                  navItems={children}
                                  mobile
                                />
                                <Caret />
                              </NavigationDropdownMobileContainer>
                            )}
                          </DropdownMobileInner>
                        </DropdownMobileTransition>
                      );
                    }}
                  </Measure>
                )}
              </NavItemWrapper>
            );
          })}
        </NavBar>
      )}
    </Location>
  );
}

NavigationMobile.defaultProps = defaultProps;

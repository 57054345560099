import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <polygon
      fill="#fff"
      points="109 113.38 99 103 89 113 79 103 69 113 59 103 49 113 39 103 29 113 19 103 19 15 109 15 109 113.38"
    />
    <rect width="89" height="17" x="19" y="15" fill="#9eb9d3" />
    <line
      x1="49"
      x2="39"
      y1="113"
      y2="103"
      fill="none"
      stroke="#444b54"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="6"
    />
    <line
      x1="69"
      x2="59"
      y1="113"
      y2="103"
      fill="none"
      stroke="#444b54"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="6"
    />
    <line
      x1="89"
      x2="79"
      y1="113"
      y2="103"
      fill="none"
      stroke="#444b54"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="6"
    />
    <polyline
      fill="none"
      stroke="#444b54"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="6"
      points="31 15 109 15 109 113 99 103"
    />
    <line
      x1="86"
      x2="86"
      y1="47"
      y2="51"
      fill="none"
      stroke="#9eb9d3"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    />
    <line
      x1="86"
      x2="86"
      y1="81"
      y2="85"
      fill="none"
      stroke="#9eb9d3"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    />
    <path
      fill="none"
      stroke="#9eb9d3"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
      d="M94,58s0-7-8-7c-7,0-9,4-9,7,0,5,1.09,6.42,9,8,5,1,8,3,8,8,0,1,0,7-8,7-7,0-9-4-9-7"
    />
    <line
      x1="35"
      x2="61"
      y1="47"
      y2="47"
      fill="none"
      stroke="#9eb9d3"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    />
    <line
      x1="35"
      x2="61"
      y1="59"
      y2="59"
      fill="none"
      stroke="#9eb9d3"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    />
    <line
      x1="35"
      x2="61"
      y1="73"
      y2="73"
      fill="none"
      stroke="#9eb9d3"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    />
    <line
      x1="35"
      x2="42"
      y1="85"
      y2="85"
      fill="none"
      stroke="#9eb9d3"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    />
    <line
      x1="54"
      x2="61"
      y1="85"
      y2="85"
      fill="none"
      stroke="#9eb9d3"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    />
    <line
      x1="19"
      x2="19"
      y1="103"
      y2="15"
      fill="none"
      stroke="#444b54"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="6"
    />
    <line
      x1="29"
      x2="19"
      y1="113"
      y2="103"
      fill="none"
      stroke="#444b54"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="6"
    />
  </Svg>
);

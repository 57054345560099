import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 128 128">
    <path
      fill="#e2bea6"
      d="M29 34L90 34 90 51.5 110 51 90 91 90 124 84 124 71 111 58 124 29 124z"
    />
    <path
      fill="#bf8f83"
      d="M87 122.8V77c0-1.7-1.3-3-3-3s-3 1.3-3 3v45.8M57 34v24.8c0 1.7-1.3 3-3 3s-3-1.3-3-3V34H57z"
    />
    <path
      fill="#444b54"
      d="M112.6,49.4C112,48.5,111,48,110,48h-8c-1.7,0-3,1.3-3,3s1.3,3,3,3h3.1L87.3,89.7C87.1,90.1,87,90.5,87,91v30 h-1.8L74,109.8V84c0-1.7-1.3-3-3-3s-3,1.3-3,3v25.8L56.8,121H32V74c0-1.7-1.3-3-3-3s-3,1.3-3,3v50c0,1.7,1.3,3,3,3h29 c0.8,0,1.6-0.3,2.1-0.9L71,115.2l10.9,10.9c0.6,0.6,1.3,0.9,2.1,0.9h6c1.7,0,3-1.3,3-3V91.7l19.7-39.4 C113.1,51.4,113.1,50.3,112.6,49.4z"
    />
    <path
      fill="#444b54"
      d="M29 62c1.7 0 3-1.3 3-3V37h13v32c0 1.7 1.3 3 3 3s3-1.3 3-3V37h17v17c0 1.7 1.3 3 3 3s3-1.3 3-3V37h13v7 7.5 10c0 1.7 1.3 3 3 3s3-1.3 3-3v-10V44 34c0-1.7-1.3-3-3-3H29c-1.7 0-3 1.3-3 3v25C26 60.7 27.3 62 29 62zM48 91c-1.7 0-3 1.3-3 3v10c0 1.7 1.3 3 3 3s3-1.3 3-3V94C51 92.3 49.7 91 48 91z"
    />
  </Svg>
);

import React from 'react';
import { Svg } from '../Logo.styles';

export default () => (
  <Svg
    viewBox="0 0 550.94 77.82"
    dimMobile={[550.94 * 0.4, 77.82 * 0.4]}
    dim={[550.94 * 0.75, 77.82 * 0.75]}
  >
    <path
      fill="#144899"
      d="M81.45,38.1H77L68.74,15h5.12l4.36,13.37c.43,1.29.89,3,1.09,3.8.19-.86.62-2.45,1-3.8L84.62,15h5Z"
    />
    <path
      fill="#144899"
      d="M94.78,38.1V15h16v4.53H99.4v4H106V28H99.4v5.58h11.92V38.1Z"
    />
    <path
      fill="#144899"
      d="M130.46,38.1l-4.13-8.32h-4.65V38.1h-4.62V15h10.76c4.35,0,8,2.41,8,7.33,0,3.47-1.48,5.65-4.42,6.8l4.42,9Zm-2.74-18.62h-6v5.94h6c2.14,0,3.3-1,3.3-3S129.76,19.48,127.72,19.48Z"
    />
    <path
      fill="#144899"
      d="M151.15,29.91h-5.09V38.1h-4.62V15h10.1c4.36,0,8,2.41,8,7.33C159.5,27.67,155.93,29.91,151.15,29.91Zm.23-10.43h-5.32v5.94h5.38c2.15,0,3.3-1,3.3-3S153.42,19.48,151.38,19.48Z"
    />
    <path
      fill="#144899"
      d="M177.65,38.1,175.83,33h-8.45l-1.81,5.15h-4.95L169.46,15H174l8.78,23.11Zm-4.89-13.73c-.46-1.36-.92-2.74-1.15-3.6-.23.86-.7,2.24-1.16,3.6L169,28.56h5.28Z"
    />
    <path
      fill="#144899"
      d="M200.22,21.73A4.37,4.37,0,0,0,196,19.22c-3.47,0-5.45,3-5.45,7.33s2,7.32,5.48,7.32c2.08,0,3.3-.89,4.52-2.93l3.9,2.31a8.84,8.84,0,0,1-8.55,5.21c-6,0-10.1-4.62-10.1-11.91,0-7,4.22-11.92,10.26-11.92a8.7,8.7,0,0,1,8.45,5.12Z"
    />
    <path
      fill="#144899"
      d="M224.54,38.1l-6.27-11.16-3.69,4.36v6.8H210V15h4.62v6.57c0,.86,0,2.94-.07,3.67.33-.53,1.19-1.59,1.82-2.35L222.79,15h5.58l-6.76,8,8.48,15.12Z"
    />
    <path
      fill="#144899"
      d="M242.49,38.46c-5.58,0-9.34-3.76-9.34-10.49V15h4.62V27.9c0,3.7,1.78,6,4.72,6s4.75-2.28,4.75-6V15h4.62V27.9C251.86,34.6,248.37,38.46,242.49,38.46Z"
    />
    <path
      fill="#144899"
      d="M273.05,38.1l-8.48-12.61c-.56-.86-1.32-2-1.62-2.61,0,.86.07,3.77.07,5.05V38.1h-4.56V15h4.42l8.19,12.22c.56.85,1.32,2,1.62,2.6,0-.85-.07-3.76-.07-5V15h4.56V38.1Z"
    />
    <path
      fill="#144899"
      d="M292.81,38.46c-6,0-10.09-4.62-10.09-11.91,0-7,4.22-11.92,10.26-11.92,4.72,0,7.23,2.21,8.75,5.78l-4.39,1.81c-.86-1.71-1.92-3-4.43-3-3.46,0-5.44,3-5.44,7.33s2,7.39,5.48,7.39c3.17,0,4.25-1.49,4.52-3.5h-3.66V26.12h8.28v3C302.09,35.09,298.16,38.46,292.81,38.46Z"
    />
    <path
      fill="#144899"
      d="M315.32,38.46c-3.86,0-7.23-1.62-9-4.19l3.34-3a7.46,7.46,0,0,0,5.9,2.64c2.74,0,4-.76,4-2.51,0-1.48-.79-2.15-5.15-3.13s-7.23-2.35-7.23-6.9c0-4.19,3.7-6.77,8.25-6.77a11,11,0,0,1,8.42,3.63l-3.33,3.24a7.06,7.06,0,0,0-5.25-2.38c-2.47,0-3.3,1.06-3.3,2.08,0,1.45,1,1.91,4.65,2.77s7.73,2.11,7.73,7.13C324.4,35.49,321.62,38.46,315.32,38.46Z"
    />
    <path
      fill="#144899"
      d="M338.82,38.46c-6,0-10.1-4.62-10.1-11.91,0-7,4.23-11.92,10.27-11.92,4.72,0,7.22,2.21,8.74,5.78l-4.39,1.81c-.86-1.71-1.91-3-4.42-3-3.47,0-5.45,3-5.45,7.33s2,7.39,5.48,7.39c3.17,0,4.26-1.49,4.52-3.5h-3.66V26.12h8.29v3C348.1,35.09,344.17,38.46,338.82,38.46Z"
    />
    <path
      fill="#144899"
      d="M353.8,38.1V15h16v4.53H358.42v4h6.64V28h-6.64v5.58h11.92V38.1Z"
    />
    <path
      fill="#144899"
      d="M383.44,38.46c-3.86,0-7.23-1.62-9-4.19l3.34-3a7.46,7.46,0,0,0,5.9,2.64c2.74,0,4-.76,4-2.51,0-1.48-.79-2.15-5.15-3.13s-7.23-2.35-7.23-6.9c0-4.19,3.7-6.77,8.25-6.77A11,11,0,0,1,392,18.26l-3.33,3.24a7.06,7.06,0,0,0-5.25-2.38c-2.48,0-3.3,1.06-3.3,2.08,0,1.45,1,1.91,4.65,2.77s7.73,2.11,7.73,7.13C392.52,35.49,389.74,38.46,383.44,38.46Z"
    />
    <path
      fill="#144899"
      d="M398.22,38.1V15h16v4.53H402.84v4h6.64V28h-6.64v5.58h11.92V38.1Z"
    />
    <path
      fill="#144899"
      d="M430.27,19.55V38.1h-4.62V19.55H419V15H436.9v4.56Z"
    />
    <path
      fill="#144899"
      d="M440.6,38.1V34l9.93-11.84c.73-.89,2.05-2.41,2.64-3-.92,0-3.33.07-4.68.07h-7.23V15h17.39v4.1l-9.83,11.85c-.73.89-2.05,2.4-2.64,3,.92,0,3.33-.07,4.68-.07h8V38.1Z"
    />
    <path
      fill="#0799B4"
      d="M473.56,38.1c.3-5.84,3-8.29,7.43-11.29,4.09-2.77,5-3.89,5-6,0-2.37-1.68-3.86-4.72-3.86a7.78,7.78,0,0,0-5.61,2.15l-1.48-1.88a10.42,10.42,0,0,1,7.19-2.58c4.1,0,7.17,1.92,7.17,6,0,3.43-1.59,4.82-5.78,7.69-4.69,3.2-5.81,4.82-6.24,7.3h12.25V38.1Z"
    />
    <path
      fill="#0799B4"
      d="M501.88,38.46c-5.15,0-8.65-4.26-8.65-11.91s3.5-11.92,8.65-11.92,8.64,4.26,8.64,11.92S507,38.46,501.88,38.46Zm0-21.52c-4,0-6.11,3.63-6.11,9.61s2.11,9.6,6.11,9.6,6.1-3.63,6.1-9.6S505.87,16.94,501.88,16.94Z"
    />
    <path
      fill="#0799B4"
      d="M514,38.1c.3-5.84,3-8.29,7.43-11.29,4.09-2.77,5-3.89,5-6,0-2.37-1.68-3.86-4.72-3.86a7.78,7.78,0,0,0-5.61,2.15l-1.48-1.88a10.42,10.42,0,0,1,7.19-2.58c4.1,0,7.16,1.92,7.16,6,0,3.43-1.58,4.82-5.77,7.69-4.69,3.2-5.81,4.82-6.24,7.3h12.25V38.1Z"
    />
    {/* <path
      fill="#0799B4"
      d="M542.3,38.46c-5.15,0-8.65-4.26-8.65-11.91s3.5-11.92,8.65-11.92,8.64,4.26,8.64,11.92S547.44,38.46,542.3,38.46Zm0-21.52c-4,0-6.11,3.63-6.11,9.61s2.11,9.6,6.11,9.6,6.1-3.63,6.1-9.6S546.29,16.94,542.3,16.94Z"
    /> */}
    <path
      fill="#0799B4"
      transform="translate(4 0)"
      d="M537.4,38h-2.6V19.4h-4.2v-1.6c2.6-0.2,4.4-0.9,4.8-3h2V38z"
    />
    <path
      fill="#abc4dd"
      d="M12.58,38.75V31.68S12.2,28,14.1,26.85s6.76-3.57,6.76-3.57l17.87-9.43V0L11.33,14.3A23.49,23.49,0,0,0,5,18.48,17.07,17.07,0,0,0,1.2,24.6,21.89,21.89,0,0,0,.27,29v9.72Z"
    />
    <path
      fill="#144899"
      d="M38.46,39.06v7.08s.38,3.67-1.52,4.83-6.76,3.57-6.76,3.57L12.31,64V77.82l27.4-14.3a23.49,23.49,0,0,0,6.38-4.18,16.91,16.91,0,0,0,3.75-6.12,21.86,21.86,0,0,0,.94-4.43V39.06Z"
    />
    <path
      fill="#0799B4"
      d="M38.73,13.85v7.07s.37,3.68-1.53,4.84-6.76,3.56-6.76,3.56L17.72,36l-6.66,3.47A23.65,23.65,0,0,0,4.68,43.7,17,17,0,0,0,.94,49.81,21.86,21.86,0,0,0,0,54.24V64H12.31V56.89s-.37-3.67,1.53-4.83,6.76-3.57,6.76-3.57l12.72-6.71L40,38.3a23.49,23.49,0,0,0,6.38-4.18A17,17,0,0,0,50.1,28,21.69,21.69,0,0,0,51,23.57V13.85Z"
    />
    <path
      fill="#0799B4"
      d="M195.25,63.17V60.81l5.28-6.24h-5V52h8.59v2.37l-5.29,6.23h5.5v2.53Z"
    />
    <path
      fill="#0799B4"
      d="M207,52h3v1.24a3.82,3.82,0,0,1,3.13-1.48,4.12,4.12,0,0,1,2,.52,4,4,0,0,1,1.51,1.41,4.53,4.53,0,0,1,1.59-1.41,4.24,4.24,0,0,1,2.08-.53,4.15,4.15,0,0,1,3.13,1.26,4.51,4.51,0,0,1,1.2,3.24v6.88h-2.95V56.59A2.28,2.28,0,0,0,221,55a1.73,1.73,0,0,0-1.35-.63A2.85,2.85,0,0,0,217.22,56c0,.31,0,.55,0,.73v6.43h-3V56.59a2.23,2.23,0,0,0-.57-1.57,1.75,1.75,0,0,0-1.34-.62A2.88,2.88,0,0,0,209.92,56v7.2h-3Z"
    />
    <path
      fill="#0799B4"
      d="M232.21,51.79a4.81,4.81,0,0,1,3.52,1.38V52h2.88V63.17h-2.88V62.06a4.84,4.84,0,0,1-3.52,1.37,5.11,5.11,0,0,1-2.85-.79,5.46,5.46,0,0,1-2-2.12,6,6,0,0,1-.71-2.9,6.06,6.06,0,0,1,.71-2.92,5.4,5.4,0,0,1,4.83-2.91Zm.62,9a3.25,3.25,0,0,0,1.74-.48,3.06,3.06,0,0,0,1.16-1.29V56.17a3,3,0,0,0-1.16-1.29,3.18,3.18,0,0,0-1.74-.49,3.07,3.07,0,0,0-2.28.93,3.22,3.22,0,0,0,2.28,5.51Z"
    />
    <path
      fill="#0799B4"
      d="M247.15,54.39a2.32,2.32,0,0,0-1.36.49,3.75,3.75,0,0,0-1.12,1.32v7h-2.95V52h2.95v1.37a3.58,3.58,0,0,1,3-1.62,2.28,2.28,0,0,1,1,.23l-.21,2.59A4.13,4.13,0,0,0,247.15,54.39Z"
    />
    <path
      fill="#0799B4"
      d="M254.5,59.29A1.35,1.35,0,0,0,256,60.83c.49,0,.9,0,1.23,0v2.49a8.18,8.18,0,0,1-1.54.16q-4.17,0-4.17-3.92V54.64h-1.72V52h1.72V49.09l3-.26V52h2.61v2.6H254.5Z"
    />
    <path
      fill="#8092a0"
      d="M70.94,63V51.45h7v1.18h-5.7v3.63h3.3v1.17h-3.3v4.41h6V63Z"
    />
    <path
      fill="#8092a0"
      d="M81.62,52.79a.88.88,0,1,1,.86-.87A.87.87,0,0,1,81.62,52.79ZM81,63V54.34h1.24V63Z"
    />
    <path
      fill="#8092a0"
      d="M90.89,63V57.94c0-1.86-.67-2.7-2-2.7-1.21,0-2.15.8-2.15,2.7V63H85.45V54.34h1.24V55.4a2.81,2.81,0,0,1,2.4-1.24c1.83,0,3,1.08,3,3.57V63Z"
    />
    <path
      fill="#8092a0"
      d="M103.35,63.19a5.48,5.48,0,0,1-3.88-1.6l.86-1A4.35,4.35,0,0,0,103.4,62c1.53,0,2.49-.76,2.49-2s-.64-1.68-2.76-2.44c-2.49-.9-3.33-1.7-3.33-3.37,0-1.85,1.45-3,3.61-3a5.19,5.19,0,0,1,3.52,1.24l-.83,1a4,4,0,0,0-2.77-1.07c-1.58,0-2.24.79-2.24,1.7s.42,1.5,2.74,2.32c2.56.93,3.35,1.79,3.35,3.5S105.76,63.19,103.35,63.19Z"
    />
    <path
      fill="#8092a0"
      d="M116.54,59.1h-5.78c.11,1.85,1,3,2.41,3a3.83,3.83,0,0,0,2.39-.83l.58.9a5.13,5.13,0,0,1-3.1,1c-1.59,0-3.52-1.15-3.52-4.55,0-2.76,1.52-4.48,3.58-4.48,2.26,0,3.49,1.79,3.49,4.26A4.12,4.12,0,0,1,116.54,59.1Zm-3.45-3.86c-1.29,0-2.18.92-2.33,2.85h4.67C115.33,56.41,114.6,55.24,113.09,55.24Z"
    />
    <path
      fill="#8092a0"
      d="M123.73,55.63a2.39,2.39,0,0,0-1.3-.33c-1.21,0-1.88,1-1.88,2.63V63h-1.24V54.34h1.24v1.08a2.52,2.52,0,0,1,2.14-1.26,3.2,3.2,0,0,1,1.36.22Z"
    />
    <path
      fill="#8092a0"
      d="M129.92,63h-1.09l-3.1-8.67h1.32l2.21,6.82.13.42.14-.42,2.21-6.82H133Z"
    />
    <path
      fill="#8092a0"
      d="M136.19,52.79a.88.88,0,1,1,.86-.87A.87.87,0,0,1,136.19,52.79ZM135.55,63V54.34h1.24V63Z"
    />
    <path
      fill="#8092a0"
      d="M145.29,56.11a2.9,2.9,0,0,0-2.08-.87c-1.35,0-2.4,1.22-2.4,3.38s.91,3.5,2.45,3.5a2.73,2.73,0,0,0,2-.92L146,62a3.68,3.68,0,0,1-2.79,1.2c-1.9,0-3.65-1.45-3.65-4.47,0-3.2,1.92-4.56,3.73-4.56a4,4,0,0,1,2.74,1Z"
    />
    <path
      fill="#8092a0"
      d="M154.88,59.1H149.1c.12,1.85,1,3,2.41,3a3.83,3.83,0,0,0,2.39-.83l.58.9a5.13,5.13,0,0,1-3.1,1c-1.59,0-3.52-1.15-3.52-4.55,0-2.76,1.52-4.48,3.58-4.48,2.27,0,3.49,1.79,3.49,4.26A4.12,4.12,0,0,1,154.88,59.1Zm-3.45-3.86c-1.29,0-2.18.92-2.33,2.85h4.67C153.67,56.41,153,55.24,151.43,55.24Z"
    />
    <path
      fill="#8092a0"
      d="M165.84,63h-1.09l-3.11-8.67H163l2.21,6.82.13.42.13-.42,2.21-6.82h1.29Z"
    />
    <path
      fill="#8092a0"
      d="M174.35,63.19c-2.11,0-3.73-1.57-3.73-4.5s1.69-4.53,3.8-4.53,3.73,1.52,3.73,4.46S176.47,63.19,174.35,63.19Zm0-7.95c-1.5,0-2.51,1.13-2.51,3.41s1,3.47,2.51,3.47,2.52-1.19,2.52-3.47S175.89,55.24,174.39,55.24Z"
    />
    <path
      fill="#8092a0"
      d="M186.27,63V57.94c0-1.86-.66-2.7-2-2.7-1.21,0-2.15.8-2.15,2.7V63h-1.24V54.34h1.24V55.4a2.78,2.78,0,0,1,2.39-1.24c1.84,0,3,1.08,3,3.57V63Z"
    />
  </Svg>
);

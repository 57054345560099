import React from 'react';
import { Svg } from '../Icon.styles';

export default () => (
  <Svg viewBox="0 0 50 50">
    <path
      d="M16.6,11c-0.4,0-0.7,0.2-0.9,0.6c0,0-3.4,4.7-3.5,4.9C12,16.7,12,16.9,12,17.1c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3
	c0.3,0,0.5-0.2,0.7-0.5l1.5-2.5v22.5c0,0.3,0.1,0.5,0.4,0.7c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.4-0.4,0.4-0.7
	V11.9c0-0.3-0.1-0.5-0.3-0.7S16.9,11,16.6,11z M30.2,11c-2.4,0-4.4,0.7-5.8,2c-1.4,1.3-2.2,3.4-2.2,6.1v11.8c0,2.8,0.7,4.9,2.1,6.2
	c1.4,1.3,3.3,2,5.7,2s4.3-0.7,5.7-2c1.4-1.3,2.1-3.4,2.1-6.2V30c0-5-2.5-7.6-7.6-7.6c-2.6,0-4.5,0.8-5.7,2.5v-5.8
	c0-3.8,1.8-5.8,5.4-5.8c1.5,0,2.8,0.4,3.8,1.2c1,0.8,1.6,1.8,1.6,3.1c0,0.7,0.4,1,1.3,1c0.8,0,1.2-0.4,1.2-1.1
	c0-1.8-0.7-3.3-2.1-4.6S32.6,11,30.2,11z M30.1,24.6c3.6,0,5.4,1.8,5.4,5.5v0.9c0,3.8-1.8,5.7-5.4,5.7c-3.6,0-5.4-1.9-5.4-5.7v-1.2
	C24.7,26.3,26.5,24.6,30.1,24.6z"
    />
  </Svg>
);

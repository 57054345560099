import React from 'react';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';
import TrustBadge from '~/components/common/TrustBadge';

export default function CraftGlobalTrustedShops() {
  const { globalTrustedShops } = useCraftGlobals();

  if (!globalTrustedShops || globalTrustedShops.trustedShopsShowBadge !== true)
    return null;

  return <TrustBadge trustedShopsId={globalTrustedShops.trustedShopsId} />;
}

import React from 'react';

import { TableScrollWrapper, TableStyled } from './Table.styles';

/**
 * Component: Table
 */
export default function Table({
  className = undefined,
  children = undefined,
  element = undefined,
  appearance = undefined,
  ...rest
}) {
  return (
    <TableScrollWrapper>
      <TableStyled className={className} appearance={appearance} {...rest}>
        {children}
      </TableStyled>
    </TableScrollWrapper>
  );
}

import React, {
  createContext,
  Children,
  useRef,
  // useEffect,
} from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { Global } from '@emotion/core';
import { SnackbarProvider } from 'notistack';
import { useVoucherCode } from '~/utils/hooks/useVoucherCode';
import ThemeProvider from '~/components/common/ThemeProvider';
import Header from '~/components/common/Header';
import Footer from '~/components/common/Footer';
import MobileCustomTrustbadge from '~/components/common/MobileCustomTrustbadge';
import SalesViewer from '~/components/common/SalesViewer';
import AnchorNavigation from '~/components/common/AnchorNavigation';
import CraftGlobalNavigation from '~/components/craft/CraftGlobalNavigation';
import CraftGlobalNavigationFooter from '~/components/craft/CraftGlobalNavigationFooter';
import CraftGlobalNavigationMeta from '~/components/craft/CraftGlobalNavigationMeta';
import CraftGlobalMeta from '~/components/craft/CraftGlobalMeta';
import CraftGlobalMetaReduced from '~/components/craft/CraftGlobalMetaReduced';
import CraftGlobalTrustedShops from '~/components/craft/CraftGlobalTrustedShops';
import CraftGlobalCookieInfo from '~/components/craft/CraftGlobalCookieInfo';
import PreloadFonts from './PreloadFonts';
import { FooterVariant } from '~/components/common/Footer/Footer';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';
import { globalStyles } from './Layout.styles';
import Button from '../Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Icon from '../Icon';
import CraftGlobalBrowserSupport from '~/components/craft/CraftGlobalBrowserSupport';
// import { renderWeglot } from '~/utils/tracking';

// import { ThemeProviderDemo } from '../ThemeProvider/ThemeProviderDemo';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'inherit',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [],
      },
    },
  },
});

export type PageContextType = {
  homeUri?: string;
  pageFooter?: string;
  id?: number;
  language?: string;
  type?: string;
  site?: string;
  alternateEntries?: any;
  template?: string;
  pageNavigation?: string | null | undefined;
  navigationCta?: any;
  anchorNav?: any;
  calculatorWizard?: {
    calculatorBaseUri: string;
    stepIndex?: number;
    stepSlug: string;
  };
};

export const PageContext = createContext<PageContextType>({});

type LayoutProps = {
  children: React.ReactNode;
  pageContext: PageContextType;
};

export default function Layout({ children, pageContext }: LayoutProps) {
  // make sure to grab any code from the url and store it in localestorage
  useVoucherCode();
  const {
    globalSiteSettings,
    globalNavigationMeta,
    globalNavigationFooter,
  } = useCraftGlobals(pageContext);

  const onlyChild = Children.only(children);

  // all calculator pages use the same key to prevent reiniting the components

  if (pageContext?.type === 'calculator') {
    (onlyChild as any).key = '$calculator';
  }

  const notistackRef = useRef<any>();

  // useEffect(() => {
  //   // Render weglot on client render, not on SSR
  //   renderWeglot();
  // });

  return (
    <PageContext.Provider value={pageContext}>
      {/* <ThemeProviderDemo> */}
      <ThemeProvider>
        <MuiThemeProvider theme={muiTheme}>
          <CraftGlobalBrowserSupport />
          <SnackbarProvider
            ref={notistackRef}
            hideIconVariant={true}
            action={(key: string) => (
              <Button
                onClick={() => {
                  notistackRef.current?.closeSnackbar(key);
                }}
                variant="blank"
                css={{
                  color: 'inherit',
                  textTransform: 'uppercase',
                  '&:hover': { color: 'inherit', opacity: 0.7 },
                }}
                iconName="none"
              >
                <Icon name="Close" />
              </Button>
            )}
            classes={{
              variantSuccess: 'mui-snackbar mui-snackbar-success',
              variantError: 'mui-snackbar mui-snackbar-error',
              variantWarning: 'mui-snackbar mui-snackbar-warning',
              variantInfo: 'mui-snackbar mui-snackbar-info',
            }}
          >
            <PreloadFonts />
            <Global styles={globalStyles} />
            <Header
              navigationDesktop={
                pageContext.pageNavigation === 'onepagerNav' ? (
                  <AnchorNavigation
                    anchorNav={pageContext.anchorNav}
                    navigationCta={pageContext.navigationCta}
                  />
                ) : (
                  <CraftGlobalNavigation />
                )
              }
              navigationMobile={
                pageContext.pageNavigation === 'onepagerNav' ? (
                  <AnchorNavigation
                    anchorNav={pageContext.anchorNav}
                    navigationCta={pageContext.navigationCta}
                    mobile
                  />
                ) : (
                  <CraftGlobalNavigation mobile />
                )
              }
              meta={<CraftGlobalMeta />}
              opaqueAtTop={[
                'calculator',
                'calculatorConcludeContract',
                'pages',
                'blog',
                'blogArticles',
                'blogTag',
                'blogCategory',
              ].includes(String(pageContext.template))}
            />
            <Helmet>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
              />

              <script type="text/javascript"> // var et_pagename = ""; // var et_areas = ""; // var et_tval = 0; // var et_tsale = 0; // var et_tonr = ""; // var et_basket = ""; </script>
              <script id="_etLoader" type="text/javascript" charSet="UTF-8" data-block-cookies="true" data-respect-dnt="true" data-secure-code="4aKd09" src="//code.etracker.com/code/e.js" async></script>

              <meta
                name="google-site-verification"
                content="zB56BCsyQmTUfdJfufALoNwiX5D6G9kRxk9iLhOf7gU"
              />
            </Helmet>
            <MobileCustomTrustbadge identifier="trustbadge-mobile" />
            {onlyChild}
            <Footer
              columnLeft={<CraftGlobalNavigationFooter />}
              columnRight={
                globalNavigationMeta?.menuItemsSimplified ? null : (
                  <CraftGlobalNavigationMeta />
                )
              }
              hideColumns={
                !(
                  globalNavigationFooter?.menuItems?.length &&
                  (globalNavigationMeta?.menuItemsSimplified
                    ? 0
                    : globalNavigationMeta?.menuItems?.length)
                )
              }
              meta={
                <CraftGlobalMeta invertPoweredByImage hideCustomerPortalLink />
              }
              variant={pageContext.pageFooter as FooterVariant}
              metaReduced={<CraftGlobalMetaReduced />}
              badges={
                pageContext?.type !== 'calculator' ? (
                  <CraftGlobalTrustedShops />
                ) : null
              }
            >
              {globalNavigationMeta?.menuItemsSimplified ? (
                <CraftGlobalNavigationMeta />
              ) : null}
            </Footer>
            <CraftGlobalCookieInfo globalSiteSettings={globalSiteSettings}/>
          </SnackbarProvider>
        </MuiThemeProvider>
      </ThemeProvider>

      <SalesViewer />

      {/* </ThemeProviderDemo> */}
    </PageContext.Provider>
  );
}
